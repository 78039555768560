import "./Footer.scss";
import {useTheme} from "@mui/material";

export default function Footer(props: { direitos?: string }) {
    const {direitos} = props;
    const theme = useTheme();

    return (
        <div className="footer" style={{
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText
        }}>{direitos}</div>
    )
}
