import {Link} from "react-router-dom";
import "./Header.scss";
import * as React from "react";
import {useTheme} from "@mui/material";

export default function Header(props: { logoSrc?: string, logoComponent?: React.ReactNode, headerActions?: React.ReactNode }) {
    const {logoSrc, logoComponent, headerActions} = props;
    const theme = useTheme();

    return (
        <div className="header" style={{borderBottomColor: theme.palette.primary.main}}>
            <Link className="logo" to="/">
                {logoSrc && <img src={logoSrc} alt="Logo Fazenda Santa Fé"/>}
                {logoComponent || ""}
            </Link>

            <div className="spacer"></div>

            {headerActions || ""}
        </div>
    );
}
