import React, {createContext, useContext, useEffect, useState} from "react";
import {AcessoApi, UsuarioLogadoDTO} from "../../../dao";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import {BehaviorSubject, Observable} from "rxjs";
import {Mensageria, MensageriaContext} from "../mensageria/Mensageria";
import * as uuid from "uuid";
import fetchIntercept, {FetchInterceptor} from "fetch-intercept";
import DateTypeInterceptor from "../dateType/DateTypeInterceptor";

const DEV = 'mcldev';
const SSE = 'mclsse';

export interface SessaoDTO {
    user: UsuarioLogadoDTO,
    token: string;
}

export class SessaoManager {
    private acessoApi = new AcessoApi(ApiConfiguration);
    private sessao = new BehaviorSubject<SessaoDTO | null>(null);

    constructor(
        private mensageria: Mensageria,
    ) {
        const device = localStorage.getItem(DEV) || uuid.v4();
        const self = this;

        const interceptor: FetchInterceptor = {
            request: function (url: string, config: any) {
                const storedToken = localStorage.getItem(SSE);
                if (url.startsWith(process.env.REACT_APP_BASE_PATH) && !config.headers['Authorization']) {
                    if (!!storedToken) {
                        config.headers['Authorization'] = `Bearer ${storedToken}`;
                    } else {
                        config.headers['Authorization'] = `Device browser@${device}`;
                    }
                }

                return [url, config];
            },
            response: function (response) {
                if (!response.status) {
                    return response;
                }

                if (response.status === 403 || response.status === 401) {
                    self.loggedOut();
                } else if (response.status >= 400) {
                    mensageria.handleError(response);
                } else if (response.headers.has('Set-Authorization')) {
                    let headerToken = response.headers.get('Set-Authorization');
                    self.sessao.next({token: headerToken} as SessaoDTO);
                    localStorage.setItem(SSE, headerToken);
                }

                return response;
            },
            responseError: async function (error: any): Promise<any> {
                mensageria.handleError(error);

                return error;
            }
        };

        fetchIntercept.register(interceptor);
        fetchIntercept.register(DateTypeInterceptor);

        localStorage.setItem(DEV, device);

        const token = localStorage.getItem(SSE);
        if (!!token) {
            this.sessao.next({token} as SessaoDTO);
            this.refresh();
        }
    }

    get current() {
        return this.sessao.value;
    }

    get sessaoObservable(): Observable<SessaoDTO> {
        return this.sessao.asObservable();
    }

    loggedIn(user: UsuarioLogadoDTO, userToken?: string) {
        this.sessao.next({user, token: userToken || this.sessao.value?.token});
        if (!!userToken) {
            localStorage.setItem(SSE, userToken);
        }
    }

    loggedOut() {
        this.sessao.next(null);
        localStorage.removeItem(SSE);
    }

    isLoggedIn(): boolean {
        return !!this.sessao.value;
    }

    async refresh() {
        try {
            const token = this.sessao.value?.token;

            if (token) {
                const user = await this.acessoApi.refresh({headers: {Authorization: `Bearer ${token}`}});

                this.sessao.next({user, token: this.sessao.value?.token});
            }
        } catch (ex: any) {
            if (ex.status === 403) {
                this.loggedOut();
            }
        }
    }
}

export const SessaoContext = createContext<SessaoManager>(null);

export default function Sessao(props: { children: React.ReactNode }) {
    const mensageria = useContext(MensageriaContext);
    const [manager] = useState(new SessaoManager(mensageria));

    useEffect(() => {
        const interval = setInterval(() => {
            if (!!manager.current?.token) {
                manager.refresh();
            }
        }, 2 * 60 * 1000);

        return () => clearInterval(interval);
    });

    return (
        <SessaoContext.Provider value={manager}>
            {props.children}
        </SessaoContext.Provider>
    );
}
