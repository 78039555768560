import {createContext} from "react";


export interface IMenuItem {
    key: string;
    route?: string;
    label: string
    icon?: string;
    children?: Array<IMenuItem>;
}

const MenuContext = createContext<Array<IMenuItem>>([]);

export default MenuContext;
