import {Button, Icon, Menu, MenuItem, useTheme} from "@mui/material";
import {IMenuItem} from "./MenuContext";
import {MouseEvent, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

export default function MenuOption(props: { menu: IMenuItem }) {
    const {menu} = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    const handleRouteClick = (m: IMenuItem) => {
        setAnchorEl(null);
        navigate(m.route as string, {
            replace: true,
        });
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const active = (menu.route !== "/" || location.pathname === "/") && location.pathname.startsWith(menu.route);

    return (
        <>
            {
                !menu.children?.length && <Button
                    className={active ? 'active' : ''}
                    style={{
                        color: theme.palette.primary.main,
                        backgroundColor: active ? '#efefef' : 'inherit'
                    }}
                    onClick={() => handleRouteClick(menu)}>
                    {!!menu.icon && <Icon className="left">{menu.icon}</Icon>}
                    <span className="text">{menu.label}</span>
                </Button>
            }
            {
                !!menu.children?.length &&
                <>
                    <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleMenuClick}
                    >
                        {!!menu.icon && <Icon className="left">{menu.icon}</Icon>}
                        <span className="text">{menu.label}</span>
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => handleMenuClose()}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {
                            menu.children.map(child =>
                                <MenuItem key={child.key + "_menuItem"} onClick={() => handleRouteClick(child)}>
                                    {!!menu.icon && <Icon className="left">{menu.icon}</Icon>}
                                    <span className="text">{menu.label}</span>
                                </MenuItem>
                            )
                        }
                    </Menu>
                </>
            }
        </>
    )
}
