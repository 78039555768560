/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Fazenda Santa Fé - API - API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.7.2
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";

import { Configuration } from "./configuration";

const BASE_PATH = "https://localhost".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = window.fetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface AlteracaoAuditoriaDTO
 */
export interface AlteracaoAuditoriaDTO {
    /**
     * 
     * @type {number}
     * @memberof AlteracaoAuditoriaDTO
     */
    id?: number;
    /**
     * Data e hora no formato yyyy-MM-ddTHH:mm:ss.SSSXX
     * @type {Date}
     * @memberof AlteracaoAuditoriaDTO
     */
    dataHora?: Date;
    /**
     * 
     * @type {string}
     * @memberof AlteracaoAuditoriaDTO
     */
    entityType?: string;
    /**
     * 
     * @type {string}
     * @memberof AlteracaoAuditoriaDTO
     */
    entityId?: string;
    /**
     * cadastro - CADASTRO, exclusao - EXCLUSAO, alteracao - ALTERACAO
     * @type {string}
     * @memberof AlteracaoAuditoriaDTO
     */
    tipo?: AlteracaoAuditoriaDTO.TipoEnum;
    /**
     * 
     * @type {ArquivoDTO}
     * @memberof AlteracaoAuditoriaDTO
     */
    detalheAlteracoes?: ArquivoDTO;
}

/**
 * @export
 * @namespace AlteracaoAuditoriaDTO
 */
export namespace AlteracaoAuditoriaDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum TipoEnum {
        Cadastro = 'cadastro',
        Exclusao = 'exclusao',
        Alteracao = 'alteracao'
    }
}

/**
 * 
 * @export
 * @interface AlteracaoClienteDTO
 */
export interface AlteracaoClienteDTO {
    /**
     * sem_app - SEM_APP, nao_utiliza_app - NAO_UTILIZA_APP, impossibilitado - IMPOSSIBILITADO, outro - OUTRO
     * @type {string}
     * @memberof AlteracaoClienteDTO
     */
    motivo?: AlteracaoClienteDTO.MotivoEnum;
    /**
     * 
     * @type {string}
     * @memberof AlteracaoClienteDTO
     */
    outroMotivo?: string;
    /**
     * 
     * @type {Array<ArquivoDTO>}
     * @memberof AlteracaoClienteDTO
     */
    anexos?: Array<ArquivoDTO>;
}

/**
 * @export
 * @namespace AlteracaoClienteDTO
 */
export namespace AlteracaoClienteDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum MotivoEnum {
        SemApp = 'sem_app',
        NaoUtilizaApp = 'nao_utiliza_app',
        Impossibilitado = 'impossibilitado',
        Outro = 'outro'
    }
}

/**
 * 
 * @export
 * @interface AlteracaoSenhaDTO
 */
export interface AlteracaoSenhaDTO {
    /**
     * Senha atual criptografada usuário
     * @type {SenhaDTO}
     * @memberof AlteracaoSenhaDTO
     */
    senhaAtualCriptografada: SenhaDTO;
    /**
     * Nova senha criptografada usuário
     * @type {SenhaDTO}
     * @memberof AlteracaoSenhaDTO
     */
    novaSenhaCriptografada: SenhaDTO;
}

/**
 * 
 * @export
 * @interface AnimalDTO
 */
export interface AnimalDTO {
    /**
     * 
     * @type {number}
     * @memberof AnimalDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AnimalDTO
     */
    codigo?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalDTO
     */
    bosch?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalDTO
     */
    sisbov?: string;
    /**
     * macho - MACHO, femea - FEMEA
     * @type {string}
     * @memberof AnimalDTO
     */
    sexo?: AnimalDTO.SexoEnum;
    /**
     * 
     * @type {number}
     * @memberof AnimalDTO
     */
    diasDeCocho?: number;
    /**
     * 
     * @type {RacaDTO}
     * @memberof AnimalDTO
     */
    raca?: RacaDTO;
    /**
     * 
     * @type {LoteDTO}
     * @memberof AnimalDTO
     */
    lote?: LoteDTO;
    /**
     * 
     * @type {ClienteDTO}
     * @memberof AnimalDTO
     */
    cliente?: ClienteDTO;
    /**
     * inativo - INATIVO, vendido - VENDIDO, ativo - ATIVO
     * @type {string}
     * @memberof AnimalDTO
     */
    status?: AnimalDTO.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AnimalDTO
     */
    pesoBalancinha?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalDTO
     */
    protocoloEntrada?: string;
    /**
     * Data no formato yyyy-MM-dd
     * @type {string}
     * @memberof AnimalDTO
     */
    dataProcessamento?: string;
    /**
     * 
     * @type {number}
     * @memberof AnimalDTO
     */
    diasLimite?: number;
    /**
     * 
     * @type {string}
     * @memberof AnimalDTO
     */
    valorDiaria?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalDTO
     */
    pesoAtual?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalDTO
     */
    gmd?: string;
}

/**
 * @export
 * @namespace AnimalDTO
 */
export namespace AnimalDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum SexoEnum {
        Macho = 'macho',
        Femea = 'femea'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Inativo = 'inativo',
        Vendido = 'vendido',
        Ativo = 'ativo'
    }
}

/**
 * 
 * @export
 * @interface ArquivoDTO
 */
export interface ArquivoDTO {
    /**
     * 
     * @type {string}
     * @memberof ArquivoDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ArquivoDTO
     */
    nome?: string;
    /**
     * 
     * @type {number}
     * @memberof ArquivoDTO
     */
    tamanho?: number;
    /**
     * 
     * @type {string}
     * @memberof ArquivoDTO
     */
    url?: string;
}

/**
 * 
 * @export
 * @interface ArquivoProcessamentoEntradaDTO
 */
export interface ArquivoProcessamentoEntradaDTO {
    /**
     * 
     * @type {ArquivoDTO}
     * @memberof ArquivoProcessamentoEntradaDTO
     */
    arquivo?: ArquivoDTO;
}

/**
 * 
 * @export
 * @interface CancelamentoPendenciaDTO
 */
export interface CancelamentoPendenciaDTO {
    /**
     * comprador_cancelou - COMPRADOR_CANCELOU, negociacao_melhor - NEGOCIACAO_MELHOR, frigorifico_cancelou - FRIGORIFICO_CANCELOU, outro - OUTRO
     * @type {string}
     * @memberof CancelamentoPendenciaDTO
     */
    motivo?: CancelamentoPendenciaDTO.MotivoEnum;
    /**
     * 
     * @type {string}
     * @memberof CancelamentoPendenciaDTO
     */
    outroMotivo?: string;
    /**
     * 
     * @type {Array<ArquivoDTO>}
     * @memberof CancelamentoPendenciaDTO
     */
    anexos?: Array<ArquivoDTO>;
}

/**
 * @export
 * @namespace CancelamentoPendenciaDTO
 */
export namespace CancelamentoPendenciaDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum MotivoEnum {
        CompradorCancelou = 'comprador_cancelou',
        NegociacaoMelhor = 'negociacao_melhor',
        FrigorificoCancelou = 'frigorifico_cancelou',
        Outro = 'outro'
    }
}

/**
 * 
 * @export
 * @interface ClienteDTO
 */
export interface ClienteDTO {
    /**
     * 
     * @type {number}
     * @memberof ClienteDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ClienteDTO
     */
    codigo?: string;
    /**
     * 
     * @type {string}
     * @memberof ClienteDTO
     */
    nome?: string;
    /**
     * 
     * @type {string}
     * @memberof ClienteDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ClienteDTO
     */
    telefone?: string;
    /**
     * 
     * @type {string}
     * @memberof ClienteDTO
     */
    cpfCnpj?: string;
    /**
     * inativo - INATIVO, ativo - ATIVO, bloqueado - BLOQUEADO, excluido - EXCLUIDO
     * @type {string}
     * @memberof ClienteDTO
     */
    status?: ClienteDTO.StatusEnum;
}

/**
 * @export
 * @namespace ClienteDTO
 */
export namespace ClienteDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Inativo = 'inativo',
        Ativo = 'ativo',
        Bloqueado = 'bloqueado',
        Excluido = 'excluido'
    }
}

/**
 * 
 * @export
 * @interface ClienteQuantidadeAnimaisDTO
 */
export interface ClienteQuantidadeAnimaisDTO {
    /**
     * 
     * @type {ClienteDTO}
     * @memberof ClienteQuantidadeAnimaisDTO
     */
    cliente?: ClienteDTO;
    /**
     * 
     * @type {number}
     * @memberof ClienteQuantidadeAnimaisDTO
     */
    qtdAnimais60a74DiasMacho?: number;
    /**
     * 
     * @type {number}
     * @memberof ClienteQuantidadeAnimaisDTO
     */
    qtdAnimais60a74DiasFemea?: number;
    /**
     * 
     * @type {number}
     * @memberof ClienteQuantidadeAnimaisDTO
     */
    qtdAnimais75a94DiasMacho?: number;
    /**
     * 
     * @type {number}
     * @memberof ClienteQuantidadeAnimaisDTO
     */
    qtdAnimais75a94DiasFemea?: number;
    /**
     * 
     * @type {number}
     * @memberof ClienteQuantidadeAnimaisDTO
     */
    qtdAnimais95DiasMacho?: number;
    /**
     * 
     * @type {number}
     * @memberof ClienteQuantidadeAnimaisDTO
     */
    qtdAnimais95DiasFemea?: number;
}

/**
 * 
 * @export
 * @interface ComentarioDTO
 */
export interface ComentarioDTO {
    /**
     * 
     * @type {number}
     * @memberof ComentarioDTO
     */
    id?: number;
    /**
     * 
     * @type {NoticiaDTO}
     * @memberof ComentarioDTO
     */
    noticia?: NoticiaDTO;
    /**
     * 
     * @type {string}
     * @memberof ComentarioDTO
     */
    comentario: string;
    /**
     * Data e hora no formato yyyy-MM-ddTHH:mm:ss.SSSXX
     * @type {Date}
     * @memberof ComentarioDTO
     */
    data?: Date;
    /**
     * 
     * @type {UsuarioDTO}
     * @memberof ComentarioDTO
     */
    autor?: UsuarioDTO;
    /**
     * ativo - ATIVO, denunciado - DENUNCIADO, excluido - EXCLUIDO
     * @type {string}
     * @memberof ComentarioDTO
     */
    status?: ComentarioDTO.StatusEnum;
}

/**
 * @export
 * @namespace ComentarioDTO
 */
export namespace ComentarioDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Ativo = 'ativo',
        Denunciado = 'denunciado',
        Excluido = 'excluido'
    }
}

/**
 * 
 * @export
 * @interface ConfiguracaoUploadArquivoDTO
 */
export interface ConfiguracaoUploadArquivoDTO {
    /**
     * 
     * @type {string}
     * @memberof ConfiguracaoUploadArquivoDTO
     */
    url?: string;
    /**
     * 
     * @type {ArquivoDTO}
     * @memberof ConfiguracaoUploadArquivoDTO
     */
    arquivo?: ArquivoDTO;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ConfiguracaoUploadArquivoDTO
     */
    headers?: { [key: string]: string; };
}

/**
 * 
 * @export
 * @interface DadosArquivoDTO
 */
export interface DadosArquivoDTO {
    /**
     * 
     * @type {string}
     * @memberof DadosArquivoDTO
     */
    nome: string;
    /**
     * 
     * @type {number}
     * @memberof DadosArquivoDTO
     */
    tamanho: number;
    /**
     * 
     * @type {string}
     * @memberof DadosArquivoDTO
     */
    sha256: string;
}

/**
 * 
 * @export
 * @interface DadosUltimaCargaDTO
 */
export interface DadosUltimaCargaDTO {
    /**
     * Data e hora no formato yyyy-MM-ddTHH:mm:ss.SSSXX
     * @type {Date}
     * @memberof DadosUltimaCargaDTO
     */
    dataInicio?: Date;
    /**
     * Data e hora no formato yyyy-MM-ddTHH:mm:ss.SSSXX
     * @type {Date}
     * @memberof DadosUltimaCargaDTO
     */
    dataTermino?: Date;
    /**
     * processando - PROCESSANDO, concluido_sucesso - CONCLUIDO_SUCESSO, falha_processamento - FALHA_PROCESSAMENTO
     * @type {string}
     * @memberof DadosUltimaCargaDTO
     */
    status?: DadosUltimaCargaDTO.StatusEnum;
    /**
     * 
     * @type {number}
     * @memberof DadosUltimaCargaDTO
     */
    qtdClientesRegistrados?: number;
    /**
     * 
     * @type {number}
     * @memberof DadosUltimaCargaDTO
     */
    qtdDesembarquesRegistrados?: number;
    /**
     * 
     * @type {number}
     * @memberof DadosUltimaCargaDTO
     */
    qtdLotesRegistrados?: number;
    /**
     * 
     * @type {number}
     * @memberof DadosUltimaCargaDTO
     */
    qtdSaidasRegistradas?: number;
}

/**
 * @export
 * @namespace DadosUltimaCargaDTO
 */
export namespace DadosUltimaCargaDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Processando = 'processando',
        ConcluidoSucesso = 'concluido_sucesso',
        FalhaProcessamento = 'falha_processamento'
    }
}

/**
 * 
 * @export
 * @interface DenunciaDTO
 */
export interface DenunciaDTO {
    /**
     * 
     * @type {number}
     * @memberof DenunciaDTO
     */
    id?: number;
    /**
     * 
     * @type {ComentarioDTO}
     * @memberof DenunciaDTO
     */
    comentario?: ComentarioDTO;
    /**
     * spam - SPAM, golpe_fraude - GOLPE_FRAUDE, informacao_falsa - INFORMACAO_FALSA, violencia_linguajar_inadequado - VIOLENCIA_LINGUAJAR_INADEQUADO, outro - OUTRO
     * @type {string}
     * @memberof DenunciaDTO
     */
    motivo: DenunciaDTO.MotivoEnum;
    /**
     * pendente - PENDENTE, aprovado - APROVADO, rejeitado - REJEITADO
     * @type {string}
     * @memberof DenunciaDTO
     */
    status?: DenunciaDTO.StatusEnum;
    /**
     * Data e hora no formato yyyy-MM-ddTHH:mm:ss.SSSXX
     * @type {Date}
     * @memberof DenunciaDTO
     */
    data?: Date;
    /**
     * 
     * @type {UsuarioDTO}
     * @memberof DenunciaDTO
     */
    denunciante?: UsuarioDTO;
}

/**
 * @export
 * @namespace DenunciaDTO
 */
export namespace DenunciaDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum MotivoEnum {
        Spam = 'spam',
        GolpeFraude = 'golpe_fraude',
        InformacaoFalsa = 'informacao_falsa',
        ViolenciaLinguajarInadequado = 'violencia_linguajar_inadequado',
        Outro = 'outro'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Pendente = 'pendente',
        Aprovado = 'aprovado',
        Rejeitado = 'rejeitado'
    }
}

/**
 * 
 * @export
 * @interface DesembarqueDTO
 */
export interface DesembarqueDTO {
    /**
     * 
     * @type {number}
     * @memberof DesembarqueDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DesembarqueDTO
     */
    codigo?: string;
    /**
     * Data e hora no formato yyyy-MM-ddTHH:mm:ss.SSSXX
     * @type {Date}
     * @memberof DesembarqueDTO
     */
    dataHoraEntrada?: Date;
    /**
     * Data e hora no formato yyyy-MM-ddTHH:mm:ss.SSSXX
     * @type {Date}
     * @memberof DesembarqueDTO
     */
    dataHoraSaida?: Date;
    /**
     * 
     * @type {string}
     * @memberof DesembarqueDTO
     */
    placaCarreta?: string;
    /**
     * 
     * @type {number}
     * @memberof DesembarqueDTO
     */
    qtdAnimais?: number;
    /**
     * pendente - PENDENTE, iniciado - INICIADO, encerrado - ENCERRADO, cancelado - CANCELADO
     * @type {string}
     * @memberof DesembarqueDTO
     */
    status?: DesembarqueDTO.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DesembarqueDTO
     */
    pesoTotal?: string;
    /**
     * 
     * @type {string}
     * @memberof DesembarqueDTO
     */
    pesoMedio?: string;
    /**
     * 
     * @type {string}
     * @memberof DesembarqueDTO
     */
    pesoLiquido?: string;
    /**
     * 
     * @type {string}
     * @memberof DesembarqueDTO
     */
    pesoTara?: string;
    /**
     * macho - MACHO, femea - FEMEA
     * @type {string}
     * @memberof DesembarqueDTO
     */
    sexoAnimais?: DesembarqueDTO.SexoAnimaisEnum;
    /**
     * 
     * @type {ClienteDTO}
     * @memberof DesembarqueDTO
     */
    proprietario?: ClienteDTO;
    /**
     * 
     * @type {LoteDTO}
     * @memberof DesembarqueDTO
     */
    lote?: LoteDTO;
    /**
     * 
     * @type {ArquivoDTO}
     * @memberof DesembarqueDTO
     */
    notaFiscal?: ArquivoDTO;
    /**
     * 
     * @type {ArquivoDTO}
     * @memberof DesembarqueDTO
     */
    declaracaoFemea?: ArquivoDTO;
    /**
     * 
     * @type {Array<ArquivoDTO>}
     * @memberof DesembarqueDTO
     */
    gtas?: Array<ArquivoDTO>;
    /**
     * 
     * @type {ArquivoDTO}
     * @memberof DesembarqueDTO
     */
    gtaUnificado?: ArquivoDTO;
}

/**
 * @export
 * @namespace DesembarqueDTO
 */
export namespace DesembarqueDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Pendente = 'pendente',
        Iniciado = 'iniciado',
        Encerrado = 'encerrado',
        Cancelado = 'cancelado'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum SexoAnimaisEnum {
        Macho = 'macho',
        Femea = 'femea'
    }
}

/**
 * 
 * @export
 * @interface DiagnosticoDTO
 */
export interface DiagnosticoDTO {
    /**
     * 
     * @type {LocalMorteDTO}
     * @memberof DiagnosticoDTO
     */
    local?: LocalMorteDTO;
    /**
     * 
     * @type {TipoMorteDTO}
     * @memberof DiagnosticoDTO
     */
    tipo?: TipoMorteDTO;
    /**
     * 
     * @type {string}
     * @memberof DiagnosticoDTO
     */
    observacao?: string;
    /**
     * debilitado - DEBILITADO, magro - MAGRO, gordo - GORDO
     * @type {string}
     * @memberof DiagnosticoDTO
     */
    escoreCondicaoCorporal?: DiagnosticoDTO.EscoreCondicaoCorporalEnum;
    /**
     * 
     * @type {Array<ArquivoDTO>}
     * @memberof DiagnosticoDTO
     */
    midiasAnimal?: Array<ArquivoDTO>;
    /**
     * 
     * @type {Array<ArquivoDTO>}
     * @memberof DiagnosticoDTO
     */
    midiasIdentificacao?: Array<ArquivoDTO>;
    /**
     * 
     * @type {Array<ArquivoDTO>}
     * @memberof DiagnosticoDTO
     */
    midiasMarca?: Array<ArquivoDTO>;
    /**
     * 
     * @type {Array<ArquivoDTO>}
     * @memberof DiagnosticoDTO
     */
    midiasNecropsia?: Array<ArquivoDTO>;
}

/**
 * @export
 * @namespace DiagnosticoDTO
 */
export namespace DiagnosticoDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum EscoreCondicaoCorporalEnum {
        Debilitado = 'debilitado',
        Magro = 'magro',
        Gordo = 'gordo'
    }
}

/**
 * 
 * @export
 * @interface DocumentoDigitalDTO
 */
export interface DocumentoDigitalDTO {
    /**
     * 
     * @type {string}
     * @memberof DocumentoDigitalDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentoDigitalDTO
     */
    idExterno?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentoDigitalDTO
     */
    linkAssinatura?: string;
}

/**
 * 
 * @export
 * @interface DocumentosDesembarqueDTO
 */
export interface DocumentosDesembarqueDTO {
    /**
     * 
     * @type {number}
     * @memberof DocumentosDesembarqueDTO
     */
    id: number;
    /**
     * 
     * @type {ArquivoDTO}
     * @memberof DocumentosDesembarqueDTO
     */
    notaFiscal: ArquivoDTO;
    /**
     * 
     * @type {ArquivoDTO}
     * @memberof DocumentosDesembarqueDTO
     */
    declaracaoFemea?: ArquivoDTO;
    /**
     * 
     * @type {Array<ArquivoDTO>}
     * @memberof DocumentosDesembarqueDTO
     */
    gtas: Array<ArquivoDTO>;
}

/**
 * 
 * @export
 * @interface EmbarqueDTO
 */
export interface EmbarqueDTO {
    /**
     * 
     * @type {number}
     * @memberof EmbarqueDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EmbarqueDTO
     */
    codigo?: string;
    /**
     * Data e hora no formato yyyy-MM-ddTHH:mm:ss.SSSXX
     * @type {Date}
     * @memberof EmbarqueDTO
     */
    dataHora?: Date;
    /**
     * 
     * @type {string}
     * @memberof EmbarqueDTO
     */
    placaCarreta?: string;
    /**
     * 
     * @type {number}
     * @memberof EmbarqueDTO
     */
    qtdAnimais?: number;
    /**
     * 
     * @type {string}
     * @memberof EmbarqueDTO
     */
    pesoTotal?: string;
    /**
     * 
     * @type {string}
     * @memberof EmbarqueDTO
     */
    pesoLiquido?: string;
    /**
     * 
     * @type {string}
     * @memberof EmbarqueDTO
     */
    pesoTara?: string;
    /**
     * 
     * @type {Array<ArquivoDTO>}
     * @memberof EmbarqueDTO
     */
    notasFiscais?: Array<ArquivoDTO>;
    /**
     * 
     * @type {Array<ArquivoDTO>}
     * @memberof EmbarqueDTO
     */
    gtas?: Array<ArquivoDTO>;
    /**
     * 
     * @type {ArquivoDTO}
     * @memberof EmbarqueDTO
     */
    gtaUnificado?: ArquivoDTO;
    /**
     * 
     * @type {ArquivoDTO}
     * @memberof EmbarqueDTO
     */
    notasFiscalUnificada?: ArquivoDTO;
}

/**
 * 
 * @export
 * @interface EnvioNegociacaoDTO
 */
export interface EnvioNegociacaoDTO {
    /**
     * 
     * @type {string}
     * @memberof EnvioNegociacaoDTO
     */
    termo: string;
}

/**
 * DTO para tráfego de Erros
 * @export
 * @interface ErroDTO
 */
export interface ErroDTO {
    /**
     * 
     * @type {string}
     * @memberof ErroDTO
     */
    codigo?: string;
    /**
     * 
     * @type {string}
     * @memberof ErroDTO
     */
    mensagem?: string;
    /**
     * 
     * @type {Array(<any>} as any)
     * @memberof ErroDTO
     */
    parametros?: Array<any>;
    /**
     * 
     * @type {Array<ErroValidacaoDTO>}
     * @memberof ErroDTO
     */
    validacoes?: Array<ErroValidacaoDTO>;
    /**
     * 
     * @type {string}
     * @memberof ErroDTO
     */
    stacktrace?: string;
    /**
     * 
     * @type {string}
     * @memberof ErroDTO
     */
    referencia?: string;
}

/**
 * 
 * @export
 * @interface ErroValidacaoDTO
 */
export interface ErroValidacaoDTO {
    /**
     * 
     * @type {string}
     * @memberof ErroValidacaoDTO
     */
    propriedade?: string;
    /**
     * 
     * @type {string}
     * @memberof ErroValidacaoDTO
     */
    mensagem?: string;
    /**
     * 
     * @type {Array(<any>} as any)
     * @memberof ErroValidacaoDTO
     */
    argumentos?: Array<any>;
}

/**
 * 
 * @export
 * @interface FiltroComentarioDTO
 */
export interface FiltroComentarioDTO {
    /**
     * 
     * @type {number}
     * @memberof FiltroComentarioDTO
     */
    noticia?: number;
    /**
     * 
     * @type {number}
     * @memberof FiltroComentarioDTO
     */
    pagina?: number;
    /**
     * 
     * @type {number}
     * @memberof FiltroComentarioDTO
     */
    tamanhoPagina?: number;
}

/**
 * 
 * @export
 * @interface FinalizacaoLaudoMorteDTO
 */
export interface FinalizacaoLaudoMorteDTO {
    /**
     * 
     * @type {LocalMorteDTO}
     * @memberof FinalizacaoLaudoMorteDTO
     */
    local: LocalMorteDTO;
    /**
     * 
     * @type {TipoMorteDTO}
     * @memberof FinalizacaoLaudoMorteDTO
     */
    tipo?: TipoMorteDTO;
    /**
     * 
     * @type {string}
     * @memberof FinalizacaoLaudoMorteDTO
     */
    observacao?: string;
    /**
     * debilitado - DEBILITADO, magro - MAGRO, gordo - GORDO
     * @type {string}
     * @memberof FinalizacaoLaudoMorteDTO
     */
    escoreCondicaoCorporal?: FinalizacaoLaudoMorteDTO.EscoreCondicaoCorporalEnum;
    /**
     * 
     * @type {Array<ArquivoDTO>}
     * @memberof FinalizacaoLaudoMorteDTO
     */
    midiasAnimal: Array<ArquivoDTO>;
    /**
     * 
     * @type {Array<ArquivoDTO>}
     * @memberof FinalizacaoLaudoMorteDTO
     */
    midiasIdentificacao: Array<ArquivoDTO>;
    /**
     * 
     * @type {Array<ArquivoDTO>}
     * @memberof FinalizacaoLaudoMorteDTO
     */
    midiasMarca?: Array<ArquivoDTO>;
    /**
     * 
     * @type {Array<ArquivoDTO>}
     * @memberof FinalizacaoLaudoMorteDTO
     */
    midiasNecropsia?: Array<ArquivoDTO>;
}

/**
 * @export
 * @namespace FinalizacaoLaudoMorteDTO
 */
export namespace FinalizacaoLaudoMorteDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum EscoreCondicaoCorporalEnum {
        Debilitado = 'debilitado',
        Magro = 'magro',
        Gordo = 'gordo'
    }
}

/**
 * 
 * @export
 * @interface InicioAcessoDTO
 */
export interface InicioAcessoDTO {
    /**
     * 
     * @type {boolean}
     * @memberof InicioAcessoDTO
     */
    possuiSenha?: boolean;
}

/**
 * 
 * @export
 * @interface ItemTelaDTO
 */
export interface ItemTelaDTO {
    /**
     * 
     * @type {string}
     * @memberof ItemTelaDTO
     */
    path?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemTelaDTO
     */
    qtdeAcessos?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemTelaDTO
     */
    qtdeAcessosPorUsuario?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemTelaDTO
     */
    qtdeAcessosPorSessao?: string;
}

/**
 * 
 * @export
 * @interface LaudoEntradaDTO
 */
export interface LaudoEntradaDTO {
    /**
     * 
     * @type {string}
     * @memberof LaudoEntradaDTO
     */
    idade: string;
    /**
     * 
     * @type {string}
     * @memberof LaudoEntradaDTO
     */
    condEscoreCorporal: string;
    /**
     * 
     * @type {Array<OcorrenciaLaudoEntradaDTO>}
     * @memberof LaudoEntradaDTO
     */
    ocorrencias?: Array<OcorrenciaLaudoEntradaDTO>;
    /**
     * 
     * @type {Array<QuantidadeRacaDTO>}
     * @memberof LaudoEntradaDTO
     */
    racas: Array<QuantidadeRacaDTO>;
    /**
     * 
     * @type {number}
     * @memberof LaudoEntradaDTO
     */
    baiaProcessamentoTemporario: number;
    /**
     * 
     * @type {string}
     * @memberof LaudoEntradaDTO
     */
    observacao?: string;
    /**
     * 
     * @type {ArquivoDTO}
     * @memberof LaudoEntradaDTO
     */
    documentoAssinado?: ArquivoDTO;
    /**
     * 
     * @type {number}
     * @memberof LaudoEntradaDTO
     */
    total?: number;
    /**
     * 
     * @type {UsuarioAdministradorDTO}
     * @memberof LaudoEntradaDTO
     */
    veterinarioResponsavel?: UsuarioAdministradorDTO;
    /**
     * Data e hora no formato yyyy-MM-ddTHH:mm:ss.SSSXX
     * @type {Date}
     * @memberof LaudoEntradaDTO
     */
    dataHora?: Date;
}

/**
 * 
 * @export
 * @interface LaudoMorteDTO
 */
export interface LaudoMorteDTO {
    /**
     * 
     * @type {string}
     * @memberof LaudoMorteDTO
     */
    codigo?: string;
    /**
     * Data no formato yyyy-MM-dd
     * @type {string}
     * @memberof LaudoMorteDTO
     */
    dataSap?: string;
    /**
     * Data e hora no formato yyyy-MM-ddTHH:mm:ss.SSSXX
     * @type {Date}
     * @memberof LaudoMorteDTO
     */
    dataFinalizacao?: Date;
    /**
     * 
     * @type {AnimalDTO}
     * @memberof LaudoMorteDTO
     */
    animal?: AnimalDTO;
    /**
     * 
     * @type {DiagnosticoDTO}
     * @memberof LaudoMorteDTO
     */
    diagnostico?: DiagnosticoDTO;
    /**
     * 
     * @type {UsuarioAdministradorDTO}
     * @memberof LaudoMorteDTO
     */
    veterinarioResponsavel?: UsuarioAdministradorDTO;
    /**
     * pendente - PENDENTE, iniciado - INICIADO, finalizado - FINALIZADO
     * @type {string}
     * @memberof LaudoMorteDTO
     */
    status?: LaudoMorteDTO.StatusEnum;
    /**
     * 
     * @type {ArquivoDTO}
     * @memberof LaudoMorteDTO
     */
    documentoAssinado?: ArquivoDTO;
}

/**
 * @export
 * @namespace LaudoMorteDTO
 */
export namespace LaudoMorteDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Pendente = 'pendente',
        Iniciado = 'iniciado',
        Finalizado = 'finalizado'
    }
}

/**
 * 
 * @export
 * @interface LinhaRelatorioAcessoDTO
 */
export interface LinhaRelatorioAcessoDTO {
    /**
     * Data e hora no formato yyyy-MM-ddTHH:mm:ss.SSSXX
     * @type {Date}
     * @memberof LinhaRelatorioAcessoDTO
     */
    data?: Date;
    /**
     * 
     * @type {number}
     * @memberof LinhaRelatorioAcessoDTO
     */
    qtdeAtivos1Dia?: number;
    /**
     * 
     * @type {number}
     * @memberof LinhaRelatorioAcessoDTO
     */
    qtdeAtivos7Dias?: number;
    /**
     * 
     * @type {number}
     * @memberof LinhaRelatorioAcessoDTO
     */
    qtdeAtivos28Dias?: number;
}

/**
 * 
 * @export
 * @interface LocalDTO
 */
export interface LocalDTO {
    /**
     * 
     * @type {number}
     * @memberof LocalDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof LocalDTO
     */
    codigo?: string;
    /**
     * manejo - MANEJO, confinamento - CONFINAMENTO, enfermaria - ENFERMARIA, pasto - PASTO
     * @type {string}
     * @memberof LocalDTO
     */
    tipo?: LocalDTO.TipoEnum;
    /**
     * 
     * @type {string}
     * @memberof LocalDTO
     */
    nome?: string;
}

/**
 * @export
 * @namespace LocalDTO
 */
export namespace LocalDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum TipoEnum {
        Manejo = 'manejo',
        Confinamento = 'confinamento',
        Enfermaria = 'enfermaria',
        Pasto = 'pasto'
    }
}

/**
 * 
 * @export
 * @interface LocalMorteDTO
 */
export interface LocalMorteDTO {
    /**
     * 
     * @type {number}
     * @memberof LocalMorteDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LocalMorteDTO
     */
    nome: string;
}

/**
 * 
 * @export
 * @interface LoteDTO
 */
export interface LoteDTO {
    /**
     * 
     * @type {number}
     * @memberof LoteDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LoteDTO
     */
    codigo?: string;
    /**
     * Data e hora no formato yyyy-MM-ddTHH:mm:ss.SSSXX
     * @type {Date}
     * @memberof LoteDTO
     */
    dataHora?: Date;
    /**
     * macho - MACHO, femea - FEMEA
     * @type {string}
     * @memberof LoteDTO
     */
    sexoAnimais?: LoteDTO.SexoAnimaisEnum;
    /**
     * 
     * @type {ClienteDTO}
     * @memberof LoteDTO
     */
    proprietario?: ClienteDTO;
    /**
     * 
     * @type {string}
     * @memberof LoteDTO
     */
    pesoTotal?: string;
    /**
     * 
     * @type {string}
     * @memberof LoteDTO
     */
    pesoMedio?: string;
    /**
     * 
     * @type {string}
     * @memberof LoteDTO
     */
    pesoLiquido?: string;
    /**
     * 
     * @type {string}
     * @memberof LoteDTO
     */
    pesoTara?: string;
    /**
     * 
     * @type {string}
     * @memberof LoteDTO
     */
    mediaValorDiarias?: string;
    /**
     * pendente - PENDENTE, ativo - ATIVO, inativo - INATIVO
     * @type {string}
     * @memberof LoteDTO
     */
    status?: LoteDTO.StatusEnum;
    /**
     * 
     * @type {number}
     * @memberof LoteDTO
     */
    qtdAnimais?: number;
    /**
     * desembarque - DESEMBARQUE, processamento_entrada - PROCESSAMENTO_ENTRADA, processamento_saida - PROCESSAMENTO_SAIDA, manejo - MANEJO, encerrado - ENCERRADO
     * @type {string}
     * @memberof LoteDTO
     */
    etapa?: LoteDTO.EtapaEnum;
    /**
     * 
     * @type {number}
     * @memberof LoteDTO
     */
    qtdAnimaisProcessados?: number;
    /**
     * 
     * @type {number}
     * @memberof LoteDTO
     */
    qtdAnimaisRestantes?: number;
    /**
     * 
     * @type {number}
     * @memberof LoteDTO
     */
    mediaDiasLimites?: number;
}

/**
 * @export
 * @namespace LoteDTO
 */
export namespace LoteDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum SexoAnimaisEnum {
        Macho = 'macho',
        Femea = 'femea'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Pendente = 'pendente',
        Ativo = 'ativo',
        Inativo = 'inativo'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum EtapaEnum {
        Desembarque = 'desembarque',
        ProcessamentoEntrada = 'processamento_entrada',
        ProcessamentoSaida = 'processamento_saida',
        Manejo = 'manejo',
        Encerrado = 'encerrado'
    }
}

/**
 * 
 * @export
 * @interface MidiasLoteDTO
 */
export interface MidiasLoteDTO {
    /**
     * 
     * @type {Array<ArquivoDTO>}
     * @memberof MidiasLoteDTO
     */
    midias?: Array<ArquivoDTO>;
}

/**
 * 
 * @export
 * @interface MidiasMovimentacaoDTO
 */
export interface MidiasMovimentacaoDTO {
    /**
     * 
     * @type {Array<ArquivoDTO>}
     * @memberof MidiasMovimentacaoDTO
     */
    midias?: Array<ArquivoDTO>;
}

/**
 * 
 * @export
 * @interface MotivoMovimentacaoDTO
 */
export interface MotivoMovimentacaoDTO {
    /**
     * 
     * @type {number}
     * @memberof MotivoMovimentacaoDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof MotivoMovimentacaoDTO
     */
    codigo?: string;
    /**
     * 
     * @type {string}
     * @memberof MotivoMovimentacaoDTO
     */
    nome?: string;
}

/**
 * 
 * @export
 * @interface MotivoTratamentoDTO
 */
export interface MotivoTratamentoDTO {
    /**
     * 
     * @type {number}
     * @memberof MotivoTratamentoDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof MotivoTratamentoDTO
     */
    codigo?: string;
    /**
     * 
     * @type {string}
     * @memberof MotivoTratamentoDTO
     */
    nome?: string;
}

/**
 * 
 * @export
 * @interface MovimentacaoDTO
 */
export interface MovimentacaoDTO {
    /**
     * 
     * @type {number}
     * @memberof MovimentacaoDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MovimentacaoDTO
     */
    codigo?: string;
    /**
     * Data no formato yyyy-MM-dd
     * @type {string}
     * @memberof MovimentacaoDTO
     */
    dataDeEntrada?: string;
    /**
     * 
     * @type {LocalDTO}
     * @memberof MovimentacaoDTO
     */
    de?: LocalDTO;
    /**
     * 
     * @type {LocalDTO}
     * @memberof MovimentacaoDTO
     */
    para?: LocalDTO;
    /**
     * 
     * @type {MotivoMovimentacaoDTO}
     * @memberof MovimentacaoDTO
     */
    motivo?: MotivoMovimentacaoDTO;
    /**
     * 
     * @type {AnimalDTO}
     * @memberof MovimentacaoDTO
     */
    animal?: AnimalDTO;
    /**
     * 
     * @type {Array<ArquivoDTO>}
     * @memberof MovimentacaoDTO
     */
    midias?: Array<ArquivoDTO>;
}

/**
 * 
 * @export
 * @interface NoticiaDTO
 */
export interface NoticiaDTO {
    /**
     * 
     * @type {number}
     * @memberof NoticiaDTO
     */
    id?: number;
    /**
     * Data no formato yyyy-MM-dd
     * @type {string}
     * @memberof NoticiaDTO
     */
    data?: string;
    /**
     * 
     * @type {string}
     * @memberof NoticiaDTO
     */
    titulo: string;
    /**
     * 
     * @type {ArquivoDTO}
     * @memberof NoticiaDTO
     */
    capa: ArquivoDTO;
    /**
     * 
     * @type {string}
     * @memberof NoticiaDTO
     */
    noticia: string;
    /**
     * 
     * @type {number}
     * @memberof NoticiaDTO
     */
    comentarios?: number;
    /**
     * 
     * @type {string}
     * @memberof NoticiaDTO
     */
    link: string;
    /**
     * 
     * @type {string}
     * @memberof NoticiaDTO
     */
    autor: string;
    /**
     * 
     * @type {UsuarioAdministradorDTO}
     * @memberof NoticiaDTO
     */
    responsavel?: UsuarioAdministradorDTO;
}

/**
 * 
 * @export
 * @interface NotificacaoDTO
 */
export interface NotificacaoDTO {
    /**
     * 
     * @type {number}
     * @memberof NotificacaoDTO
     */
    id?: number;
    /**
     * Data e hora no formato yyyy-MM-ddTHH:mm:ss.SSSXX
     * @type {Date}
     * @memberof NotificacaoDTO
     */
    dataHora?: Date;
    /**
     * 
     * @type {string}
     * @memberof NotificacaoDTO
     */
    notificacao?: string;
    /**
     * desembarque_iniciado - DESEMBARQUE_INICIADO, desembarque_encerrado - DESEMBARQUE_ENCERRADO, lote_criado - LOTE_CRIADO, laudo_entrada - LAUDO_ENTRADA, laudo_morte - LAUDO_MORTE, houve_movimentacao_gado - HOUVE_MOVIMENTACAO_GADO, houve_tratamento_gado - HOUVE_TRATAMENTO_GADO, processamento_entrada - PROCESSAMENTO_ENTRADA, termos_pendencia - TERMOS_PENDENCIA, processamento_saida - PROCESSAMENTO_SAIDA, nova_noticia - NOVA_NOTICIA, confirmacao_negociacao - CONFIRMACAO_NEGOCIACAO, recusa_negociacao - RECUSA_NEGOCIACAO
     * @type {string}
     * @memberof NotificacaoDTO
     */
    tipo?: NotificacaoDTO.TipoEnum;
    /**
     * 
     * @type {LoteDTO}
     * @memberof NotificacaoDTO
     */
    lote?: LoteDTO;
    /**
     * 
     * @type {DesembarqueDTO}
     * @memberof NotificacaoDTO
     */
    desembarque?: DesembarqueDTO;
    /**
     * desembarque - DESEMBARQUE, processamento_entrada - PROCESSAMENTO_ENTRADA, processamento_saida - PROCESSAMENTO_SAIDA, manejo - MANEJO, encerrado - ENCERRADO
     * @type {string}
     * @memberof NotificacaoDTO
     */
    etapa?: NotificacaoDTO.EtapaEnum;
    /**
     * 
     * @type {LaudoEntradaDTO}
     * @memberof NotificacaoDTO
     */
    laudoEntrada?: LaudoEntradaDTO;
    /**
     * 
     * @type {LaudoMorteDTO}
     * @memberof NotificacaoDTO
     */
    laudoMorte?: LaudoMorteDTO;
    /**
     * 
     * @type {PendenciaDTO}
     * @memberof NotificacaoDTO
     */
    pendencia?: PendenciaDTO;
}

/**
 * @export
 * @namespace NotificacaoDTO
 */
export namespace NotificacaoDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum TipoEnum {
        DesembarqueIniciado = 'desembarque_iniciado',
        DesembarqueEncerrado = 'desembarque_encerrado',
        LoteCriado = 'lote_criado',
        LaudoEntrada = 'laudo_entrada',
        LaudoMorte = 'laudo_morte',
        HouveMovimentacaoGado = 'houve_movimentacao_gado',
        HouveTratamentoGado = 'houve_tratamento_gado',
        ProcessamentoEntrada = 'processamento_entrada',
        TermosPendencia = 'termos_pendencia',
        ProcessamentoSaida = 'processamento_saida',
        NovaNoticia = 'nova_noticia',
        ConfirmacaoNegociacao = 'confirmacao_negociacao',
        RecusaNegociacao = 'recusa_negociacao'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum EtapaEnum {
        Desembarque = 'desembarque',
        ProcessamentoEntrada = 'processamento_entrada',
        ProcessamentoSaida = 'processamento_saida',
        Manejo = 'manejo',
        Encerrado = 'encerrado'
    }
}

/**
 * 
 * @export
 * @interface OcorrenciaLaudoEntradaDTO
 */
export interface OcorrenciaLaudoEntradaDTO {
    /**
     * mancando - MANCANDO, animal_morto - ANIMAL_MORTO, carrapatos - CARRAPATOS, debilitado - DEBILITADO, deitado - DEITADO, fratura - FRATURA, ma_formacao - MA_FORMACAO, miiase - MIIASE, papilomatose - PAPILOMATOSE, pneumonia - PNEUMONIA, problema_de_casco - PROBLEMA_DE_CASCO, problema_de_pele - PROBLEMA_DE_PELE, quebrado - QUEBRADO, rastreado - RASTREADO, sem_orelha - SEM_ORELHA, sintoma_de_intoxicacao - SINTOMA_DE_INTOXICACAO
     * @type {string}
     * @memberof OcorrenciaLaudoEntradaDTO
     */
    ocorrencia: OcorrenciaLaudoEntradaDTO.OcorrenciaEnum;
    /**
     * 
     * @type {number}
     * @memberof OcorrenciaLaudoEntradaDTO
     */
    quantidade?: number;
    /**
     * 
     * @type {Array<ArquivoDTO>}
     * @memberof OcorrenciaLaudoEntradaDTO
     */
    midias?: Array<ArquivoDTO>;
}

/**
 * @export
 * @namespace OcorrenciaLaudoEntradaDTO
 */
export namespace OcorrenciaLaudoEntradaDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum OcorrenciaEnum {
        Mancando = 'mancando',
        AnimalMorto = 'animal_morto',
        Carrapatos = 'carrapatos',
        Debilitado = 'debilitado',
        Deitado = 'deitado',
        Fratura = 'fratura',
        MaFormacao = 'ma_formacao',
        Miiase = 'miiase',
        Papilomatose = 'papilomatose',
        Pneumonia = 'pneumonia',
        ProblemaDeCasco = 'problema_de_casco',
        ProblemaDePele = 'problema_de_pele',
        Quebrado = 'quebrado',
        Rastreado = 'rastreado',
        SemOrelha = 'sem_orelha',
        SintomaDeIntoxicacao = 'sintoma_de_intoxicacao'
    }
}

/**
 * 
 * @export
 * @interface PaginaAnimalDTO
 */
export interface PaginaAnimalDTO {
    /**
     * 
     * @type {Array<AnimalDTO>}
     * @memberof PaginaAnimalDTO
     */
    itens?: Array<AnimalDTO>;
    /**
     * 
     * @type {number}
     * @memberof PaginaAnimalDTO
     */
    totalItens?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaAnimalDTO
     */
    paginaAtual?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaAnimalDTO
     */
    tamanhoPagina?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaAnimalDTO
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaAnimalDTO
     */
    hasProxima?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaAnimalDTO
     */
    hasAnterior?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PaginaAnimalDTO
     */
    totalPaginas?: number;
}

/**
 * 
 * @export
 * @interface PaginaClienteDTO
 */
export interface PaginaClienteDTO {
    /**
     * 
     * @type {Array<ClienteDTO>}
     * @memberof PaginaClienteDTO
     */
    itens?: Array<ClienteDTO>;
    /**
     * 
     * @type {number}
     * @memberof PaginaClienteDTO
     */
    totalItens?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaClienteDTO
     */
    paginaAtual?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaClienteDTO
     */
    tamanhoPagina?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaClienteDTO
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaClienteDTO
     */
    hasProxima?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaClienteDTO
     */
    hasAnterior?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PaginaClienteDTO
     */
    totalPaginas?: number;
}

/**
 * 
 * @export
 * @interface PaginaComentarioDTO
 */
export interface PaginaComentarioDTO {
    /**
     * 
     * @type {Array<ComentarioDTO>}
     * @memberof PaginaComentarioDTO
     */
    itens?: Array<ComentarioDTO>;
    /**
     * 
     * @type {number}
     * @memberof PaginaComentarioDTO
     */
    totalItens?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaComentarioDTO
     */
    paginaAtual?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaComentarioDTO
     */
    tamanhoPagina?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaComentarioDTO
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaComentarioDTO
     */
    hasProxima?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaComentarioDTO
     */
    hasAnterior?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PaginaComentarioDTO
     */
    totalPaginas?: number;
}

/**
 * 
 * @export
 * @interface PaginaDenunciaDTO
 */
export interface PaginaDenunciaDTO {
    /**
     * 
     * @type {Array<DenunciaDTO>}
     * @memberof PaginaDenunciaDTO
     */
    itens?: Array<DenunciaDTO>;
    /**
     * 
     * @type {number}
     * @memberof PaginaDenunciaDTO
     */
    totalItens?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaDenunciaDTO
     */
    paginaAtual?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaDenunciaDTO
     */
    tamanhoPagina?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaDenunciaDTO
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaDenunciaDTO
     */
    hasProxima?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaDenunciaDTO
     */
    hasAnterior?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PaginaDenunciaDTO
     */
    totalPaginas?: number;
}

/**
 * 
 * @export
 * @interface PaginaDesembarqueDTO
 */
export interface PaginaDesembarqueDTO {
    /**
     * 
     * @type {Array<DesembarqueDTO>}
     * @memberof PaginaDesembarqueDTO
     */
    itens?: Array<DesembarqueDTO>;
    /**
     * 
     * @type {number}
     * @memberof PaginaDesembarqueDTO
     */
    totalItens?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaDesembarqueDTO
     */
    paginaAtual?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaDesembarqueDTO
     */
    tamanhoPagina?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaDesembarqueDTO
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaDesembarqueDTO
     */
    hasProxima?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaDesembarqueDTO
     */
    hasAnterior?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PaginaDesembarqueDTO
     */
    totalPaginas?: number;
}

/**
 * 
 * @export
 * @interface PaginaLaudoMorteDTO
 */
export interface PaginaLaudoMorteDTO {
    /**
     * 
     * @type {Array<LaudoMorteDTO>}
     * @memberof PaginaLaudoMorteDTO
     */
    itens?: Array<LaudoMorteDTO>;
    /**
     * 
     * @type {number}
     * @memberof PaginaLaudoMorteDTO
     */
    totalItens?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaLaudoMorteDTO
     */
    paginaAtual?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaLaudoMorteDTO
     */
    tamanhoPagina?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaLaudoMorteDTO
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaLaudoMorteDTO
     */
    hasProxima?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaLaudoMorteDTO
     */
    hasAnterior?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PaginaLaudoMorteDTO
     */
    totalPaginas?: number;
}

/**
 * 
 * @export
 * @interface PaginaLoteDTO
 */
export interface PaginaLoteDTO {
    /**
     * 
     * @type {Array<LoteDTO>}
     * @memberof PaginaLoteDTO
     */
    itens?: Array<LoteDTO>;
    /**
     * 
     * @type {number}
     * @memberof PaginaLoteDTO
     */
    totalItens?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaLoteDTO
     */
    paginaAtual?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaLoteDTO
     */
    tamanhoPagina?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaLoteDTO
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaLoteDTO
     */
    hasProxima?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaLoteDTO
     */
    hasAnterior?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PaginaLoteDTO
     */
    totalPaginas?: number;
}

/**
 * 
 * @export
 * @interface PaginaMovimentacaoDTO
 */
export interface PaginaMovimentacaoDTO {
    /**
     * 
     * @type {Array<MovimentacaoDTO>}
     * @memberof PaginaMovimentacaoDTO
     */
    itens?: Array<MovimentacaoDTO>;
    /**
     * 
     * @type {number}
     * @memberof PaginaMovimentacaoDTO
     */
    totalItens?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaMovimentacaoDTO
     */
    paginaAtual?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaMovimentacaoDTO
     */
    tamanhoPagina?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaMovimentacaoDTO
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaMovimentacaoDTO
     */
    hasProxima?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaMovimentacaoDTO
     */
    hasAnterior?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PaginaMovimentacaoDTO
     */
    totalPaginas?: number;
}

/**
 * 
 * @export
 * @interface PaginaNoticiaDTO
 */
export interface PaginaNoticiaDTO {
    /**
     * 
     * @type {Array<NoticiaDTO>}
     * @memberof PaginaNoticiaDTO
     */
    itens?: Array<NoticiaDTO>;
    /**
     * 
     * @type {number}
     * @memberof PaginaNoticiaDTO
     */
    totalItens?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaNoticiaDTO
     */
    paginaAtual?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaNoticiaDTO
     */
    tamanhoPagina?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaNoticiaDTO
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaNoticiaDTO
     */
    hasProxima?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaNoticiaDTO
     */
    hasAnterior?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PaginaNoticiaDTO
     */
    totalPaginas?: number;
}

/**
 * 
 * @export
 * @interface PaginaNotificacaoDTO
 */
export interface PaginaNotificacaoDTO {
    /**
     * 
     * @type {Array<NotificacaoDTO>}
     * @memberof PaginaNotificacaoDTO
     */
    itens?: Array<NotificacaoDTO>;
    /**
     * 
     * @type {number}
     * @memberof PaginaNotificacaoDTO
     */
    totalItens?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaNotificacaoDTO
     */
    paginaAtual?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaNotificacaoDTO
     */
    tamanhoPagina?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaNotificacaoDTO
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaNotificacaoDTO
     */
    hasProxima?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaNotificacaoDTO
     */
    hasAnterior?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PaginaNotificacaoDTO
     */
    totalPaginas?: number;
}

/**
 * 
 * @export
 * @interface PaginaObject
 */
export interface PaginaObject {
    /**
     * 
     * @type {Array(<any>} as any)
     * @memberof PaginaObject
     */
    itens?: Array<any>;
    /**
     * 
     * @type {number}
     * @memberof PaginaObject
     */
    totalItens?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaObject
     */
    paginaAtual?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaObject
     */
    tamanhoPagina?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaObject
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaObject
     */
    hasProxima?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaObject
     */
    hasAnterior?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PaginaObject
     */
    totalPaginas?: number;
}

/**
 * 
 * @export
 * @interface PaginaPendenciaDTO
 */
export interface PaginaPendenciaDTO {
    /**
     * 
     * @type {Array<PendenciaDTO>}
     * @memberof PaginaPendenciaDTO
     */
    itens?: Array<PendenciaDTO>;
    /**
     * 
     * @type {number}
     * @memberof PaginaPendenciaDTO
     */
    totalItens?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaPendenciaDTO
     */
    paginaAtual?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaPendenciaDTO
     */
    tamanhoPagina?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaPendenciaDTO
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaPendenciaDTO
     */
    hasProxima?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaPendenciaDTO
     */
    hasAnterior?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PaginaPendenciaDTO
     */
    totalPaginas?: number;
}

/**
 * 
 * @export
 * @interface PaginaProcessamentoSaidaDTO
 */
export interface PaginaProcessamentoSaidaDTO {
    /**
     * 
     * @type {Array<ProcessamentoSaidaDTO>}
     * @memberof PaginaProcessamentoSaidaDTO
     */
    itens?: Array<ProcessamentoSaidaDTO>;
    /**
     * 
     * @type {number}
     * @memberof PaginaProcessamentoSaidaDTO
     */
    totalItens?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaProcessamentoSaidaDTO
     */
    paginaAtual?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaProcessamentoSaidaDTO
     */
    tamanhoPagina?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaProcessamentoSaidaDTO
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaProcessamentoSaidaDTO
     */
    hasProxima?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaProcessamentoSaidaDTO
     */
    hasAnterior?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PaginaProcessamentoSaidaDTO
     */
    totalPaginas?: number;
}

/**
 * 
 * @export
 * @interface PaginaProcessamentoSaidaLoteDTO
 */
export interface PaginaProcessamentoSaidaLoteDTO {
    /**
     * 
     * @type {Array<ProcessamentoSaidaDTO>}
     * @memberof PaginaProcessamentoSaidaLoteDTO
     */
    itens?: Array<ProcessamentoSaidaDTO>;
    /**
     * 
     * @type {number}
     * @memberof PaginaProcessamentoSaidaLoteDTO
     */
    totalItens?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaProcessamentoSaidaLoteDTO
     */
    paginaAtual?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaProcessamentoSaidaLoteDTO
     */
    tamanhoPagina?: number;
    /**
     * 
     * @type {LoteDTO}
     * @memberof PaginaProcessamentoSaidaLoteDTO
     */
    lote?: LoteDTO;
    /**
     * 
     * @type {number}
     * @memberof PaginaProcessamentoSaidaLoteDTO
     */
    qtdAnimaisEmbarcados?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaProcessamentoSaidaLoteDTO
     */
    qtdAnimaisRestantes?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaProcessamentoSaidaLoteDTO
     */
    hasProxima?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaProcessamentoSaidaLoteDTO
     */
    hasAnterior?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PaginaProcessamentoSaidaLoteDTO
     */
    totalPaginas?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaProcessamentoSaidaLoteDTO
     */
    empty?: boolean;
}

/**
 * 
 * @export
 * @interface PaginaRegistroAuditoriaDTO
 */
export interface PaginaRegistroAuditoriaDTO {
    /**
     * 
     * @type {Array<RegistroAuditoriaDTO>}
     * @memberof PaginaRegistroAuditoriaDTO
     */
    itens?: Array<RegistroAuditoriaDTO>;
    /**
     * 
     * @type {number}
     * @memberof PaginaRegistroAuditoriaDTO
     */
    totalItens?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaRegistroAuditoriaDTO
     */
    paginaAtual?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaRegistroAuditoriaDTO
     */
    tamanhoPagina?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaRegistroAuditoriaDTO
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaRegistroAuditoriaDTO
     */
    hasProxima?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaRegistroAuditoriaDTO
     */
    hasAnterior?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PaginaRegistroAuditoriaDTO
     */
    totalPaginas?: number;
}

/**
 * 
 * @export
 * @interface PaginaTotalPendenciaDTO
 */
export interface PaginaTotalPendenciaDTO {
    /**
     * 
     * @type {Array<PendenciaDTO>}
     * @memberof PaginaTotalPendenciaDTO
     */
    itens?: Array<PendenciaDTO>;
    /**
     * 
     * @type {number}
     * @memberof PaginaTotalPendenciaDTO
     */
    totalItens?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaTotalPendenciaDTO
     */
    paginaAtual?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaTotalPendenciaDTO
     */
    tamanhoPagina?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaTotalPendenciaDTO
     */
    totalMachos?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaTotalPendenciaDTO
     */
    totalFemeas?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaTotalPendenciaDTO
     */
    hasProxima?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaTotalPendenciaDTO
     */
    hasAnterior?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PaginaTotalPendenciaDTO
     */
    totalPaginas?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaTotalPendenciaDTO
     */
    empty?: boolean;
}

/**
 * 
 * @export
 * @interface PaginaTratamentoDTO
 */
export interface PaginaTratamentoDTO {
    /**
     * 
     * @type {Array<TratamentoDTO>}
     * @memberof PaginaTratamentoDTO
     */
    itens?: Array<TratamentoDTO>;
    /**
     * 
     * @type {number}
     * @memberof PaginaTratamentoDTO
     */
    totalItens?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaTratamentoDTO
     */
    paginaAtual?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaTratamentoDTO
     */
    tamanhoPagina?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaTratamentoDTO
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaTratamentoDTO
     */
    hasProxima?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaTratamentoDTO
     */
    hasAnterior?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PaginaTratamentoDTO
     */
    totalPaginas?: number;
}

/**
 * 
 * @export
 * @interface PaginaUsuarioAdministradorDTO
 */
export interface PaginaUsuarioAdministradorDTO {
    /**
     * 
     * @type {Array<UsuarioAdministradorDTO>}
     * @memberof PaginaUsuarioAdministradorDTO
     */
    itens?: Array<UsuarioAdministradorDTO>;
    /**
     * 
     * @type {number}
     * @memberof PaginaUsuarioAdministradorDTO
     */
    totalItens?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaUsuarioAdministradorDTO
     */
    paginaAtual?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginaUsuarioAdministradorDTO
     */
    tamanhoPagina?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaUsuarioAdministradorDTO
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaUsuarioAdministradorDTO
     */
    hasProxima?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginaUsuarioAdministradorDTO
     */
    hasAnterior?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PaginaUsuarioAdministradorDTO
     */
    totalPaginas?: number;
}

/**
 * 
 * @export
 * @interface PendenciaDTO
 */
export interface PendenciaDTO {
    /**
     * 
     * @type {number}
     * @memberof PendenciaDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PendenciaDTO
     */
    codigo: string;
    /**
     * 
     * @type {ClienteDTO}
     * @memberof PendenciaDTO
     */
    cliente: ClienteDTO;
    /**
     * Data e hora no formato yyyy-MM-ddTHH:mm:ss.SSSXX
     * @type {Date}
     * @memberof PendenciaDTO
     */
    dataHora?: Date;
    /**
     * contagem - CONTAGEM, negociacao - NEGOCIACAO
     * @type {string}
     * @memberof PendenciaDTO
     */
    tipo: PendenciaDTO.TipoEnum;
    /**
     * pendente - PENDENTE, aprovado - APROVADO, recusa_enviada - RECUSA_ENVIADA, recusa_confirmada - RECUSA_CONFIRMADA, recusa_visualizada - RECUSA_VISUALIZADA, enviado - ENVIADO, visualizado - VISUALIZADO, confirmado - CONFIRMADO, concluido - CONCLUIDO, cancelado - CANCELADO
     * @type {string}
     * @memberof PendenciaDTO
     */
    status: PendenciaDTO.StatusEnum;
    /**
     * 
     * @type {UsuarioAdministradorDTO}
     * @memberof PendenciaDTO
     */
    responsavel?: UsuarioAdministradorDTO;
    /**
     * 
     * @type {string}
     * @memberof PendenciaDTO
     */
    termo?: string;
    /**
     * sem_app - SEM_APP, nao_utiliza_app - NAO_UTILIZA_APP, impossibilitado - IMPOSSIBILITADO, outro - OUTRO
     * @type {string}
     * @memberof PendenciaDTO
     */
    motivoCliente?: PendenciaDTO.MotivoClienteEnum;
    /**
     * 
     * @type {string}
     * @memberof PendenciaDTO
     */
    outroMotivoAlteracaoCliente?: string;
    /**
     * comprador_cancelou - COMPRADOR_CANCELOU, negociacao_melhor - NEGOCIACAO_MELHOR, frigorifico_cancelou - FRIGORIFICO_CANCELOU, outro - OUTRO
     * @type {string}
     * @memberof PendenciaDTO
     */
    motivoCancelamento?: PendenciaDTO.MotivoCancelamentoEnum;
    /**
     * 
     * @type {string}
     * @memberof PendenciaDTO
     */
    outroMotivoCancelamento?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PendenciaDTO
     */
    motivoRecusaNegociacao?: Array<PendenciaDTO.MotivoRecusaNegociacaoEnum>;
    /**
     * 
     * @type {string}
     * @memberof PendenciaDTO
     */
    outroMotivoRecusaNegociacao?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PendenciaDTO
     */
    motivoRecusaContagem?: Array<PendenciaDTO.MotivoRecusaContagemEnum>;
    /**
     * 
     * @type {string}
     * @memberof PendenciaDTO
     */
    outroMotivoRecusaContagem?: string;
    /**
     * 
     * @type {Array<ArquivoDTO>}
     * @memberof PendenciaDTO
     */
    anexosMotivoCliente?: Array<ArquivoDTO>;
    /**
     * 
     * @type {number}
     * @memberof PendenciaDTO
     */
    quantidadeAnimais59diasMacho?: number;
    /**
     * 
     * @type {number}
     * @memberof PendenciaDTO
     */
    quantidadeAnimais59DiasFemea?: number;
    /**
     * 
     * @type {number}
     * @memberof PendenciaDTO
     */
    quantidadeAnimais60a74DiasMacho?: number;
    /**
     * 
     * @type {number}
     * @memberof PendenciaDTO
     */
    quantidadeAnimais60a74DiasFemea?: number;
    /**
     * 
     * @type {number}
     * @memberof PendenciaDTO
     */
    quantidadeAnimais75a94DiasMacho?: number;
    /**
     * 
     * @type {number}
     * @memberof PendenciaDTO
     */
    quantidadeAnimais75a94DiasFemea?: number;
    /**
     * 
     * @type {number}
     * @memberof PendenciaDTO
     */
    quantidadeAnimais95DiasMacho?: number;
    /**
     * 
     * @type {number}
     * @memberof PendenciaDTO
     */
    quantidadeAnimais95DiasFemea?: number;
    /**
     * 
     * @type {number}
     * @memberof PendenciaDTO
     */
    quantidadeTotalAnimaisMachos?: number;
    /**
     * 
     * @type {number}
     * @memberof PendenciaDTO
     */
    quantidadeTotalAnimaisFemeas?: number;
}

/**
 * @export
 * @namespace PendenciaDTO
 */
export namespace PendenciaDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum TipoEnum {
        Contagem = 'contagem',
        Negociacao = 'negociacao'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Pendente = 'pendente',
        Aprovado = 'aprovado',
        RecusaEnviada = 'recusa_enviada',
        RecusaConfirmada = 'recusa_confirmada',
        RecusaVisualizada = 'recusa_visualizada',
        Enviado = 'enviado',
        Visualizado = 'visualizado',
        Confirmado = 'confirmado',
        Concluido = 'concluido',
        Cancelado = 'cancelado'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum MotivoClienteEnum {
        SemApp = 'sem_app',
        NaoUtilizaApp = 'nao_utiliza_app',
        Impossibilitado = 'impossibilitado',
        Outro = 'outro'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum MotivoCancelamentoEnum {
        CompradorCancelou = 'comprador_cancelou',
        NegociacaoMelhor = 'negociacao_melhor',
        FrigorificoCancelou = 'frigorifico_cancelou',
        Outro = 'outro'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum MotivoRecusaNegociacaoEnum {
        EscalaLonga = 'escala_longa',
        EsperandoNegMelhor = 'esperando_neg_melhor',
        TenhoNegMelhor = 'tenho_neg_melhor',
        OutroFigorifico = 'outro_figorifico',
        PagamentoAVista = 'pagamento_a_vista',
        RendimentoFrigorifico = 'rendimento_frigorifico',
        PrefiroPesoVivo = 'prefiro_peso_vivo',
        PrefiroPesoGancho = 'prefiro_peso_gancho',
        PrazoPagamento = 'prazo_pagamento',
        RendimentoCarcaca = 'rendimento_carcaca',
        Outro = 'outro'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum MotivoRecusaContagemEnum {
        SemNecessidade = 'sem_necessidade',
        EsperandoQuantidadeMaior = 'esperando_quantidade_maior',
        Outro = 'outro'
    }
}

/**
 * 
 * @export
 * @interface PesagemEmbarqueDTO
 */
export interface PesagemEmbarqueDTO {
    /**
     * 
     * @type {number}
     * @memberof PesagemEmbarqueDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof PesagemEmbarqueDTO
     */
    quantidade?: number;
    /**
     * 
     * @type {string}
     * @memberof PesagemEmbarqueDTO
     */
    pesoTotal?: string;
    /**
     * 
     * @type {string}
     * @memberof PesagemEmbarqueDTO
     */
    pesoMedio?: string;
    /**
     * 
     * @type {string}
     * @memberof PesagemEmbarqueDTO
     */
    pesoLiquido?: string;
    /**
     * 
     * @type {string}
     * @memberof PesagemEmbarqueDTO
     */
    pesoTara?: string;
}

/**
 * 
 * @export
 * @interface ProcessamentoSaidaDTO
 */
export interface ProcessamentoSaidaDTO {
    /**
     * 
     * @type {number}
     * @memberof ProcessamentoSaidaDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProcessamentoSaidaDTO
     */
    codigo?: string;
    /**
     * Data no formato yyyy-MM-dd
     * @type {string}
     * @memberof ProcessamentoSaidaDTO
     */
    data?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProcessamentoSaidaDTO
     */
    observacao?: string;
    /**
     * pendente - PENDENTE, iniciado - INICIADO, aguardando_financeiro - AGUARDANDO_FINANCEIRO, encerrado - ENCERRADO
     * @type {string}
     * @memberof ProcessamentoSaidaDTO
     */
    status?: ProcessamentoSaidaDTO.StatusEnum;
    /**
     * 
     * @type {ClienteDTO}
     * @memberof ProcessamentoSaidaDTO
     */
    proprietario?: ClienteDTO;
    /**
     * 
     * @type {PesagemEmbarqueDTO}
     * @memberof ProcessamentoSaidaDTO
     */
    pesagem?: PesagemEmbarqueDTO;
    /**
     * 
     * @type {Array<EmbarqueDTO>}
     * @memberof ProcessamentoSaidaDTO
     */
    embarques?: Array<EmbarqueDTO>;
    /**
     * 
     * @type {Array<ArquivoDTO>}
     * @memberof ProcessamentoSaidaDTO
     */
    midias?: Array<ArquivoDTO>;
    /**
     * 
     * @type {Array<ArquivoDTO>}
     * @memberof ProcessamentoSaidaDTO
     */
    gtas?: Array<ArquivoDTO>;
    /**
     * 
     * @type {Array<ArquivoDTO>}
     * @memberof ProcessamentoSaidaDTO
     */
    notaFiscal?: Array<ArquivoDTO>;
    /**
     * 
     * @type {ArquivoDTO}
     * @memberof ProcessamentoSaidaDTO
     */
    gtaUnificada?: ArquivoDTO;
    /**
     * 
     * @type {ArquivoDTO}
     * @memberof ProcessamentoSaidaDTO
     */
    notaFiscalUnificada?: ArquivoDTO;
    /**
     * 
     * @type {Array<ArquivoDTO>}
     * @memberof ProcessamentoSaidaDTO
     */
    relatoriosPesagem?: Array<ArquivoDTO>;
    /**
     * 
     * @type {Array<ArquivoDTO>}
     * @memberof ProcessamentoSaidaDTO
     */
    relatoriosLote?: Array<ArquivoDTO>;
    /**
     * 
     * @type {Array<ArquivoDTO>}
     * @memberof ProcessamentoSaidaDTO
     */
    relatoriosIndividual?: Array<ArquivoDTO>;
    /**
     * 
     * @type {Array<ArquivoDTO>}
     * @memberof ProcessamentoSaidaDTO
     */
    relatoriosFrigorifico?: Array<ArquivoDTO>;
    /**
     * 
     * @type {Array<AnimalDTO>}
     * @memberof ProcessamentoSaidaDTO
     */
    animais?: Array<AnimalDTO>;
}

/**
 * @export
 * @namespace ProcessamentoSaidaDTO
 */
export namespace ProcessamentoSaidaDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Pendente = 'pendente',
        Iniciado = 'iniciado',
        AguardandoFinanceiro = 'aguardando_financeiro',
        Encerrado = 'encerrado'
    }
}

/**
 * 
 * @export
 * @interface QuantidadeAnimaisClienteFaixaDTO
 */
export interface QuantidadeAnimaisClienteFaixaDTO {
    /**
     * 
     * @type {ClienteDTO}
     * @memberof QuantidadeAnimaisClienteFaixaDTO
     */
    cliente?: ClienteDTO;
    /**
     * 
     * @type {number}
     * @memberof QuantidadeAnimaisClienteFaixaDTO
     */
    quantidadeAnimais59diasMacho?: number;
    /**
     * 
     * @type {number}
     * @memberof QuantidadeAnimaisClienteFaixaDTO
     */
    quantidadeAnimais59DiasFemea?: number;
    /**
     * 
     * @type {number}
     * @memberof QuantidadeAnimaisClienteFaixaDTO
     */
    quantidadeAnimais60a74DiasMacho?: number;
    /**
     * 
     * @type {number}
     * @memberof QuantidadeAnimaisClienteFaixaDTO
     */
    quantidadeAnimais60a74DiasFemea?: number;
    /**
     * 
     * @type {number}
     * @memberof QuantidadeAnimaisClienteFaixaDTO
     */
    quantidadeAnimais75a94DiasMacho?: number;
    /**
     * 
     * @type {number}
     * @memberof QuantidadeAnimaisClienteFaixaDTO
     */
    quantidadeAnimais75a94DiasFemea?: number;
    /**
     * 
     * @type {number}
     * @memberof QuantidadeAnimaisClienteFaixaDTO
     */
    quantidadeAnimais95DiasMacho?: number;
    /**
     * 
     * @type {number}
     * @memberof QuantidadeAnimaisClienteFaixaDTO
     */
    quantidadeAnimais95DiasFemea?: number;
}

/**
 * 
 * @export
 * @interface QuantidadeAnimaisPorDiasDeCochoDTO
 */
export interface QuantidadeAnimaisPorDiasDeCochoDTO {
    /**
     * 
     * @type {number}
     * @memberof QuantidadeAnimaisPorDiasDeCochoDTO
     */
    diasDeCocho?: number;
    /**
     * 
     * @type {number}
     * @memberof QuantidadeAnimaisPorDiasDeCochoDTO
     */
    quantidadeMacho?: number;
    /**
     * 
     * @type {number}
     * @memberof QuantidadeAnimaisPorDiasDeCochoDTO
     */
    quantidadeFemea?: number;
}

/**
 * 
 * @export
 * @interface QuantidadePendenciasStatusTipoDTO
 */
export interface QuantidadePendenciasStatusTipoDTO {
    /**
     * 
     * @type {number}
     * @memberof QuantidadePendenciasStatusTipoDTO
     */
    quantidadePendenciasPendentes?: number;
    /**
     * 
     * @type {number}
     * @memberof QuantidadePendenciasStatusTipoDTO
     */
    quantidadePendenciasContagemAprovadas?: number;
    /**
     * 
     * @type {number}
     * @memberof QuantidadePendenciasStatusTipoDTO
     */
    quantidadePendenciasNegociacaoAprovadas?: number;
    /**
     * 
     * @type {number}
     * @memberof QuantidadePendenciasStatusTipoDTO
     */
    quantidadePendenciasRecusadasConfirmadas?: number;
}

/**
 * 
 * @export
 * @interface QuantidadeRacaDTO
 */
export interface QuantidadeRacaDTO {
    /**
     * 
     * @type {RacaDTO}
     * @memberof QuantidadeRacaDTO
     */
    raca: RacaDTO;
    /**
     * 
     * @type {number}
     * @memberof QuantidadeRacaDTO
     */
    quantidade: number;
}

/**
 * 
 * @export
 * @interface QuantidadesAnimaisPorDiasDeCochoDTO
 */
export interface QuantidadesAnimaisPorDiasDeCochoDTO {
    /**
     * 
     * @type {Array<QuantidadeAnimaisPorDiasDeCochoDTO>}
     * @memberof QuantidadesAnimaisPorDiasDeCochoDTO
     */
    quantidades?: Array<QuantidadeAnimaisPorDiasDeCochoDTO>;
    /**
     * 
     * @type {number}
     * @memberof QuantidadesAnimaisPorDiasDeCochoDTO
     */
    proximoContato?: number;
}

/**
 * 
 * @export
 * @interface RacaDTO
 */
export interface RacaDTO {
    /**
     * 
     * @type {number}
     * @memberof RacaDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof RacaDTO
     */
    codigo?: string;
    /**
     * 
     * @type {string}
     * @memberof RacaDTO
     */
    nome?: string;
}

/**
 * Dados para redefinição de senha
 * @export
 * @interface RedefinicaoSenhaDTO
 */
export interface RedefinicaoSenhaDTO {
    /**
     * E-mail de acesso do usuário
     * @type {string}
     * @memberof RedefinicaoSenhaDTO
     */
    email?: string;
    /**
     * 
     * @type {SenhaDTO}
     * @memberof RedefinicaoSenhaDTO
     */
    otpCriptografado: SenhaDTO;
    /**
     * Nova senha criptografada usuário
     * @type {SenhaDTO}
     * @memberof RedefinicaoSenhaDTO
     */
    senhaCriptografada?: SenhaDTO;
}

/**
 * 
 * @export
 * @interface RegistroAuditoriaDTO
 */
export interface RegistroAuditoriaDTO {
    /**
     * 
     * @type {number}
     * @memberof RegistroAuditoriaDTO
     */
    id?: number;
    /**
     * 
     * @type {UsuarioDTO}
     * @memberof RegistroAuditoriaDTO
     */
    usuario?: UsuarioDTO;
    /**
     * 
     * @type {string}
     * @memberof RegistroAuditoriaDTO
     */
    sessao?: string;
    /**
     * 1 - USUARIO_ADMINISTRADOR, 2 - AUDITORIA, 3 - DESEMBARQUE, 4 - LOTE, 5 - CLIENTE, 6 - GERENCIA_CLIENTE, 7 - TIMELINE, 8 - MEU_LOTE, 9 - MEU_DESEMBARQUE, 10 - MINHA_MOVIMENTACAO, 11 - MINHA_PENDENCIA, 12 - MEU_LAUDO_MORTE, 13 - CARGA, 14 - LAUDO_ENTRADA, 15 - LAUDO_MORTE, 16 - ANIMAL, 17 - PENDENCIA, 18 - MEU_TRATAMENTO, 19 - PROCESSAMENTO_SAIDA, 20 - DASHBOARD, 21 - NOTICIA
     * @type {string}
     * @memberof RegistroAuditoriaDTO
     */
    funcionalidade?: RegistroAuditoriaDTO.FuncionalidadeEnum;
    /**
     * consulta - CONSULTA, edicao - EDICAO, exclusao - EXCLUSAO
     * @type {string}
     * @memberof RegistroAuditoriaDTO
     */
    acao?: RegistroAuditoriaDTO.AcaoEnum;
    /**
     * android - ANDROID, ios - IOS, browser - BROWSER, servidor - SERVIDOR
     * @type {string}
     * @memberof RegistroAuditoriaDTO
     */
    tipoDispositivo?: RegistroAuditoriaDTO.TipoDispositivoEnum;
    /**
     * 
     * @type {string}
     * @memberof RegistroAuditoriaDTO
     */
    uuidDispositivo?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistroAuditoriaDTO
     */
    path?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistroAuditoriaDTO
     */
    method?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistroAuditoriaDTO
     */
    enderecoIp?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistroAuditoriaDTO
     */
    userAgent?: string;
    /**
     * Data e hora no formato yyyy-MM-ddTHH:mm:ss.SSSXX
     * @type {Date}
     * @memberof RegistroAuditoriaDTO
     */
    inicio?: Date;
    /**
     * Data e hora no formato yyyy-MM-ddTHH:mm:ss.SSSXX
     * @type {Date}
     * @memberof RegistroAuditoriaDTO
     */
    termino?: Date;
    /**
     * sucesso - SUCESSO, erro - EROR, falha_negocial - FALHA_NEGOCIAL
     * @type {string}
     * @memberof RegistroAuditoriaDTO
     */
    status?: RegistroAuditoriaDTO.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof RegistroAuditoriaDTO
     */
    mensagem?: string;
    /**
     * 
     * @type {ArquivoDTO}
     * @memberof RegistroAuditoriaDTO
     */
    stacktrace?: ArquivoDTO;
    /**
     * 
     * @type {Array<AlteracaoAuditoriaDTO>}
     * @memberof RegistroAuditoriaDTO
     */
    alteracoes?: Array<AlteracaoAuditoriaDTO>;
}

/**
 * @export
 * @namespace RegistroAuditoriaDTO
 */
export namespace RegistroAuditoriaDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum FuncionalidadeEnum {
        _1 = '1',
        _2 = '2',
        _3 = '3',
        _4 = '4',
        _5 = '5',
        _6 = '6',
        _7 = '7',
        _8 = '8',
        _9 = '9',
        _10 = '10',
        _11 = '11',
        _12 = '12',
        _13 = '13',
        _14 = '14',
        _15 = '15',
        _16 = '16',
        _17 = '17',
        _18 = '18',
        _19 = '19',
        _20 = '20',
        _21 = '21'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum AcaoEnum {
        Consulta = 'consulta',
        Edicao = 'edicao',
        Exclusao = 'exclusao'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum TipoDispositivoEnum {
        Android = 'android',
        Ios = 'ios',
        Browser = 'browser',
        Servidor = 'servidor'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Sucesso = 'sucesso',
        Erro = 'erro',
        FalhaNegocial = 'falha_negocial'
    }
}

/**
 * 
 * @export
 * @interface RejeicaoContagemDTO
 */
export interface RejeicaoContagemDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof RejeicaoContagemDTO
     */
    motivos: Array<RejeicaoContagemDTO.MotivosEnum>;
    /**
     * 
     * @type {string}
     * @memberof RejeicaoContagemDTO
     */
    outroMotivo?: string;
}

/**
 * @export
 * @namespace RejeicaoContagemDTO
 */
export namespace RejeicaoContagemDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum MotivosEnum {
        SemNecessidade = 'sem_necessidade',
        EsperandoQuantidadeMaior = 'esperando_quantidade_maior',
        Outro = 'outro'
    }
}

/**
 * 
 * @export
 * @interface RejeicaoNegociacaoDTO
 */
export interface RejeicaoNegociacaoDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof RejeicaoNegociacaoDTO
     */
    motivos: Array<RejeicaoNegociacaoDTO.MotivosEnum>;
    /**
     * 
     * @type {string}
     * @memberof RejeicaoNegociacaoDTO
     */
    outroMotivo?: string;
}

/**
 * @export
 * @namespace RejeicaoNegociacaoDTO
 */
export namespace RejeicaoNegociacaoDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum MotivosEnum {
        EscalaLonga = 'escala_longa',
        EsperandoNegMelhor = 'esperando_neg_melhor',
        TenhoNegMelhor = 'tenho_neg_melhor',
        OutroFigorifico = 'outro_figorifico',
        PagamentoAVista = 'pagamento_a_vista',
        RendimentoFrigorifico = 'rendimento_frigorifico',
        PrefiroPesoVivo = 'prefiro_peso_vivo',
        PrefiroPesoGancho = 'prefiro_peso_gancho',
        PrazoPagamento = 'prazo_pagamento',
        RendimentoCarcaca = 'rendimento_carcaca',
        Outro = 'outro'
    }
}

/**
 * 
 * @export
 * @interface RelatorioAcessoGoogleAnalyticsDTO
 */
export interface RelatorioAcessoGoogleAnalyticsDTO {
    /**
     * 
     * @type {Array<LinhaRelatorioAcessoDTO>}
     * @memberof RelatorioAcessoGoogleAnalyticsDTO
     */
    linhas?: Array<LinhaRelatorioAcessoDTO>;
}

/**
 * 
 * @export
 * @interface RelatorioBoschDTO
 */
export interface RelatorioBoschDTO {
    /**
     * 
     * @type {number}
     * @memberof RelatorioBoschDTO
     */
    totalAnimaisLote?: number;
    /**
     * 
     * @type {number}
     * @memberof RelatorioBoschDTO
     */
    animaisValidadosBosch?: number;
    /**
     * 
     * @type {string}
     * @memberof RelatorioBoschDTO
     */
    pesoEntrada?: string;
    /**
     * 
     * @type {string}
     * @memberof RelatorioBoschDTO
     */
    pesoAtual?: string;
    /**
     * 
     * @type {number}
     * @memberof RelatorioBoschDTO
     */
    diasConfinamento?: number;
    /**
     * 
     * @type {string}
     * @memberof RelatorioBoschDTO
     */
    gmd?: string;
    /**
     * 
     * @type {LoteDTO}
     * @memberof RelatorioBoschDTO
     */
    loteDTO?: LoteDTO;
    /**
     * 
     * @type {number}
     * @memberof RelatorioBoschDTO
     */
    acima1600gGmdPesoAcima450kg?: number;
    /**
     * 
     * @type {number}
     * @memberof RelatorioBoschDTO
     */
    acima1600gGmdPesoAbaixo450kg?: number;
    /**
     * 
     * @type {number}
     * @memberof RelatorioBoschDTO
     */
    abaixo1600gGmdPesoAcima450kg?: number;
    /**
     * 
     * @type {number}
     * @memberof RelatorioBoschDTO
     */
    abaixo1600gGmdPesoAbaixo450kg?: number;
}

/**
 * 
 * @export
 * @interface RelatorioTelasGoogleAnalyticsDTO
 */
export interface RelatorioTelasGoogleAnalyticsDTO {
    /**
     * 
     * @type {Array<ItemTelaDTO>}
     * @memberof RelatorioTelasGoogleAnalyticsDTO
     */
    telas?: Array<ItemTelaDTO>;
}

/**
 * 
 * @export
 * @interface RequisicaoInicioAcessoDTO
 */
export interface RequisicaoInicioAcessoDTO {
    /**
     * E-mail de acesso do usuário
     * @type {string}
     * @memberof RequisicaoInicioAcessoDTO
     */
    email?: string;
}

/**
 * DTO de requisição de login
 * @export
 * @interface RequisicaoLoginDTO
 */
export interface RequisicaoLoginDTO {
    /**
     * E-mail de acesso do usuário
     * @type {string}
     * @memberof RequisicaoLoginDTO
     */
    email?: string;
    /**
     * Senha criptografada do usuário
     * @type {SenhaDTO}
     * @memberof RequisicaoLoginDTO
     */
    senhaCriptografada: SenhaDTO;
    /**
     * Resultado do captcha quando necessário
     * @type {string}
     * @memberof RequisicaoLoginDTO
     */
    captcha?: string;
    /**
     * Indicador de token prolongado (manter usuário logado)
     * @type {boolean}
     * @memberof RequisicaoLoginDTO
     */
    tokenProlongado?: boolean;
}

/**
 * 
 * @export
 * @interface RequisicaoLoginOTPDTO
 */
export interface RequisicaoLoginOTPDTO {
    /**
     * E-mail de acesso do usuário
     * @type {string}
     * @memberof RequisicaoLoginOTPDTO
     */
    email?: string;
    /**
     * 
     * @type {SenhaDTO}
     * @memberof RequisicaoLoginOTPDTO
     */
    otpCriptografado: SenhaDTO;
    /**
     * Resultado do captcha quando necessário
     * @type {string}
     * @memberof RequisicaoLoginOTPDTO
     */
    captcha?: string;
}

/**
 * 
 * @export
 * @interface SenhaDTO
 */
export interface SenhaDTO {
    /**
     * 
     * @type {string}
     * @memberof SenhaDTO
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof SenhaDTO
     */
    chavePublica?: string;
    /**
     * 
     * @type {string}
     * @memberof SenhaDTO
     */
    senha: string;
}

/**
 * 
 * @export
 * @interface SolicitacaoOTPDTO
 */
export interface SolicitacaoOTPDTO {
    /**
     * E-mail de acesso do usuário
     * @type {string}
     * @memberof SolicitacaoOTPDTO
     */
    email?: string;
}

/**
 * 
 * @export
 * @interface TipoMorteDTO
 */
export interface TipoMorteDTO {
    /**
     * 
     * @type {number}
     * @memberof TipoMorteDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TipoMorteDTO
     */
    codigo?: string;
    /**
     * 
     * @type {string}
     * @memberof TipoMorteDTO
     */
    nome?: string;
}

/**
 * 
 * @export
 * @interface TratamentoDTO
 */
export interface TratamentoDTO {
    /**
     * 
     * @type {number}
     * @memberof TratamentoDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TratamentoDTO
     */
    codigo?: string;
    /**
     * Data no formato yyyy-MM-dd
     * @type {string}
     * @memberof TratamentoDTO
     */
    data?: string;
    /**
     * 
     * @type {MotivoTratamentoDTO}
     * @memberof TratamentoDTO
     */
    motivo?: MotivoTratamentoDTO;
    /**
     * 
     * @type {string}
     * @memberof TratamentoDTO
     */
    valorProtocolo?: string;
    /**
     * 
     * @type {AnimalDTO}
     * @memberof TratamentoDTO
     */
    animal?: AnimalDTO;
}

/**
 * 
 * @export
 * @interface UsuarioAdministradorDTO
 */
export interface UsuarioAdministradorDTO {
    /**
     * Obrigatório apenas para edição
     * @type {number}
     * @memberof UsuarioAdministradorDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UsuarioAdministradorDTO
     */
    nome: string;
    /**
     * 
     * @type {string}
     * @memberof UsuarioAdministradorDTO
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UsuarioAdministradorDTO
     */
    cpfCnpj: string;
    /**
     * inativo - INATIVO, ativo - ATIVO, bloqueado - BLOQUEADO, excluido - EXCLUIDO
     * @type {string}
     * @memberof UsuarioAdministradorDTO
     */
    status?: UsuarioAdministradorDTO.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UsuarioAdministradorDTO
     */
    celular: string;
    /**
     * gerencial - GERENCIAL, administrativo - ADMINISTRATIVO, comercial - COMERCIAL, veterinario - VETERINARIO
     * @type {string}
     * @memberof UsuarioAdministradorDTO
     */
    perfil: UsuarioAdministradorDTO.PerfilEnum;
    /**
     * 
     * @type {string}
     * @memberof UsuarioAdministradorDTO
     */
    identificacao?: string;
    /**
     * ac - AC, al - AL, ap - AP, am - AM, ba - BA, ce - CE, df - DF, es - ES, go - GO, ma - MA, mt - MT, ms - MS, mg - MG, pa - PA, pb - PB, pr - PR, pe - PE, pi - PI, rj - RJ, rn - RN, rs - RS, ro - RO, rr - RR, sc - SC, sp - SP, se - SE, to - TO
     * @type {string}
     * @memberof UsuarioAdministradorDTO
     */
    estadoIdentificacao?: UsuarioAdministradorDTO.EstadoIdentificacaoEnum;
}

/**
 * @export
 * @namespace UsuarioAdministradorDTO
 */
export namespace UsuarioAdministradorDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Inativo = 'inativo',
        Ativo = 'ativo',
        Bloqueado = 'bloqueado',
        Excluido = 'excluido'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum PerfilEnum {
        Gerencial = 'gerencial',
        Administrativo = 'administrativo',
        Comercial = 'comercial',
        Veterinario = 'veterinario'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum EstadoIdentificacaoEnum {
        Ac = 'ac',
        Al = 'al',
        Ap = 'ap',
        Am = 'am',
        Ba = 'ba',
        Ce = 'ce',
        Df = 'df',
        Es = 'es',
        Go = 'go',
        Ma = 'ma',
        Mt = 'mt',
        Ms = 'ms',
        Mg = 'mg',
        Pa = 'pa',
        Pb = 'pb',
        Pr = 'pr',
        Pe = 'pe',
        Pi = 'pi',
        Rj = 'rj',
        Rn = 'rn',
        Rs = 'rs',
        Ro = 'ro',
        Rr = 'rr',
        Sc = 'sc',
        Sp = 'sp',
        Se = 'se',
        To = 'to'
    }
}

/**
 * 
 * @export
 * @interface UsuarioDTO
 */
export interface UsuarioDTO {
    /**
     * Obrigatório apenas para edição
     * @type {number}
     * @memberof UsuarioDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UsuarioDTO
     */
    nome: string;
    /**
     * 
     * @type {string}
     * @memberof UsuarioDTO
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UsuarioDTO
     */
    cpfCnpj: string;
    /**
     * inativo - INATIVO, ativo - ATIVO, bloqueado - BLOQUEADO, excluido - EXCLUIDO
     * @type {string}
     * @memberof UsuarioDTO
     */
    status?: UsuarioDTO.StatusEnum;
}

/**
 * @export
 * @namespace UsuarioDTO
 */
export namespace UsuarioDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Inativo = 'inativo',
        Ativo = 'ativo',
        Bloqueado = 'bloqueado',
        Excluido = 'excluido'
    }
}

/**
 * 
 * @export
 * @interface UsuarioLogadoDTO
 */
export interface UsuarioLogadoDTO {
    /**
     * ID do usuário
     * @type {number}
     * @memberof UsuarioLogadoDTO
     */
    id?: number;
    /**
     * Nome do usuário
     * @type {string}
     * @memberof UsuarioLogadoDTO
     */
    nome?: string;
    /**
     * Telefone do usuário
     * @type {string}
     * @memberof UsuarioLogadoDTO
     */
    telefone?: string;
    /**
     * E-mail do usuário
     * @type {string}
     * @memberof UsuarioLogadoDTO
     */
    email?: string;
}

/**
 * 
 * @export
 * @interface ValidacaoOTPDTO
 */
export interface ValidacaoOTPDTO {
    /**
     * E-mail de acesso do usuário
     * @type {string}
     * @memberof ValidacaoOTPDTO
     */
    email?: string;
    /**
     * 
     * @type {SenhaDTO}
     * @memberof ValidacaoOTPDTO
     */
    otpCriptografado: SenhaDTO;
}

/**
 * 
 * @export
 * @interface VersaoDTO
 */
export interface VersaoDTO {
    /**
     * Número da versão da API em execução
     * @type {string}
     * @memberof VersaoDTO
     */
    versao?: string;
    /**
     * Timestamp da build da versão da API em execução
     * @type {Date}
     * @memberof VersaoDTO
     */
    build?: Date;
}


/**
 * AcessoApi - fetch parameter creator
 * @export
 */
export const AcessoApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Altera a senha do usuário loagdo
         * @param {AlteracaoSenhaDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alteraSenha(body?: AlteracaoSenhaDTO, options: any = {}): FetchArgs {
            const localVarPath = `/acesso/senha/alteracao`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (("AlteracaoSenhaDTO" !== "string" as any)) || localVarRequestOptions.headers['Content-Type'] === 'application/json' as any;
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Recupera chave pública para autenticação
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChavePublica(options: any = {}): FetchArgs {
            const localVarPath = `/acesso/chave-publica`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Inicia o login de um usuario
         * @param {RequisicaoInicioAcessoDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iniciaLogin(body?: RequisicaoInicioAcessoDTO, options: any = {}): FetchArgs {
            const localVarPath = `/acesso/inicio`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (("RequisicaoInicioAcessoDTO" !== "string" as any)) || localVarRequestOptions.headers['Content-Type'] === 'application/json' as any;
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Realiza o login no sistema
         * @param {RequisicaoLoginDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(body?: RequisicaoLoginDTO, options: any = {}): FetchArgs {
            const localVarPath = `/acesso/login`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (("RequisicaoLoginDTO" !== "string" as any)) || localVarRequestOptions.headers['Content-Type'] === 'application/json' as any;
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Realiza o login via OTP
         * @param {RequisicaoLoginOTPDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginOTP(body?: RequisicaoLoginOTPDTO, options: any = {}): FetchArgs {
            const localVarPath = `/acesso/login/otp`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (("RequisicaoLoginOTPDTO" !== "string" as any)) || localVarRequestOptions.headers['Content-Type'] === 'application/json' as any;
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Realiza o logout da aplicação para o token de acesso
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options: any = {}): FetchArgs {
            const localVarPath = `/acesso/logout`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Redefine a senha do usuário identificado pelo token de redefinição
         * @param {RedefinicaoSenhaDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redefineSenha(body?: RedefinicaoSenhaDTO, options: any = {}): FetchArgs {
            const localVarPath = `/acesso/senha/redefinicao`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (("RedefinicaoSenhaDTO" !== "string" as any)) || localVarRequestOptions.headers['Content-Type'] === 'application/json' as any;
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Realiza o refresh do token de sessão do usuário
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh(options: any = {}): FetchArgs {
            const localVarPath = `/acesso/refresh`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Registro o push token do dispositivo
         * @param {string} pushToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registraPushToken(pushToken: string, options: any = {}): FetchArgs {
            // verify required parameter 'pushToken' is not null or undefined
            if (pushToken === null || pushToken === undefined) {
                throw new RequiredError('pushToken','Required parameter pushToken was null or undefined when calling registraPushToken.');
            }
            const localVarPath = `/acesso/push-token/{pushToken}`
                .replace(`{${"pushToken"}}`, encodeURIComponent(String(pushToken)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Solicita um OTP para o usuário indicado
         * @param {SolicitacaoOTPDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solicitaOTP(body?: SolicitacaoOTPDTO, options: any = {}): FetchArgs {
            const localVarPath = `/acesso/otp`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (("SolicitacaoOTPDTO" !== "string" as any)) || localVarRequestOptions.headers['Content-Type'] === 'application/json' as any;
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Valida o OTP indicado
         * @param {ValidacaoOTPDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validaOTP(body?: ValidacaoOTPDTO, options: any = {}): FetchArgs {
            const localVarPath = `/acesso/otp/valida`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (("ValidacaoOTPDTO" !== "string" as any)) || localVarRequestOptions.headers['Content-Type'] === 'application/json' as any;
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AcessoApi - functional programming interface
 * @export
 */
export const AcessoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Altera a senha do usuário loagdo
         * @param {AlteracaoSenhaDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alteraSenha(body?: AlteracaoSenhaDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AcessoApiFetchParamCreator(configuration).alteraSenha(body, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Recupera chave pública para autenticação
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChavePublica(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SenhaDTO> {
            const localVarFetchArgs = AcessoApiFetchParamCreator(configuration).getChavePublica(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Inicia o login de um usuario
         * @param {RequisicaoInicioAcessoDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iniciaLogin(body?: RequisicaoInicioAcessoDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InicioAcessoDTO> {
            const localVarFetchArgs = AcessoApiFetchParamCreator(configuration).iniciaLogin(body, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Realiza o login no sistema
         * @param {RequisicaoLoginDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(body?: RequisicaoLoginDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UsuarioLogadoDTO> {
            const localVarFetchArgs = AcessoApiFetchParamCreator(configuration).login(body, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Realiza o login via OTP
         * @param {RequisicaoLoginOTPDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginOTP(body?: RequisicaoLoginOTPDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UsuarioLogadoDTO> {
            const localVarFetchArgs = AcessoApiFetchParamCreator(configuration).loginOTP(body, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Realiza o logout da aplicação para o token de acesso
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AcessoApiFetchParamCreator(configuration).logout(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Redefine a senha do usuário identificado pelo token de redefinição
         * @param {RedefinicaoSenhaDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redefineSenha(body?: RedefinicaoSenhaDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UsuarioLogadoDTO> {
            const localVarFetchArgs = AcessoApiFetchParamCreator(configuration).redefineSenha(body, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Realiza o refresh do token de sessão do usuário
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UsuarioLogadoDTO> {
            const localVarFetchArgs = AcessoApiFetchParamCreator(configuration).refresh(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Registro o push token do dispositivo
         * @param {string} pushToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registraPushToken(pushToken: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AcessoApiFetchParamCreator(configuration).registraPushToken(pushToken, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Solicita um OTP para o usuário indicado
         * @param {SolicitacaoOTPDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solicitaOTP(body?: SolicitacaoOTPDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AcessoApiFetchParamCreator(configuration).solicitaOTP(body, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Valida o OTP indicado
         * @param {ValidacaoOTPDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validaOTP(body?: ValidacaoOTPDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AcessoApiFetchParamCreator(configuration).validaOTP(body, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AcessoApi - factory interface
 * @export
 */
export const AcessoApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Altera a senha do usuário loagdo
         * @param {AlteracaoSenhaDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alteraSenha(body?: AlteracaoSenhaDTO, options?: any) {
            return AcessoApiFp(configuration).alteraSenha(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Recupera chave pública para autenticação
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChavePublica(options?: any) {
            return AcessoApiFp(configuration).getChavePublica(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Inicia o login de um usuario
         * @param {RequisicaoInicioAcessoDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iniciaLogin(body?: RequisicaoInicioAcessoDTO, options?: any) {
            return AcessoApiFp(configuration).iniciaLogin(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Realiza o login no sistema
         * @param {RequisicaoLoginDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(body?: RequisicaoLoginDTO, options?: any) {
            return AcessoApiFp(configuration).login(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Realiza o login via OTP
         * @param {RequisicaoLoginOTPDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginOTP(body?: RequisicaoLoginOTPDTO, options?: any) {
            return AcessoApiFp(configuration).loginOTP(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Realiza o logout da aplicação para o token de acesso
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any) {
            return AcessoApiFp(configuration).logout(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Redefine a senha do usuário identificado pelo token de redefinição
         * @param {RedefinicaoSenhaDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redefineSenha(body?: RedefinicaoSenhaDTO, options?: any) {
            return AcessoApiFp(configuration).redefineSenha(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Realiza o refresh do token de sessão do usuário
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh(options?: any) {
            return AcessoApiFp(configuration).refresh(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Registro o push token do dispositivo
         * @param {string} pushToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registraPushToken(pushToken: string, options?: any) {
            return AcessoApiFp(configuration).registraPushToken(pushToken, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Solicita um OTP para o usuário indicado
         * @param {SolicitacaoOTPDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solicitaOTP(body?: SolicitacaoOTPDTO, options?: any) {
            return AcessoApiFp(configuration).solicitaOTP(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Valida o OTP indicado
         * @param {ValidacaoOTPDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validaOTP(body?: ValidacaoOTPDTO, options?: any) {
            return AcessoApiFp(configuration).validaOTP(body, options)(fetch, basePath);
        },
    };
};

/**
 * AcessoApi - object-oriented interface
 * @export
 * @class AcessoApi
 * @extends {BaseAPI}
 */
export class AcessoApi extends BaseAPI {
    /**
     * 
     * @summary Altera a senha do usuário loagdo
     * @param {AlteracaoSenhaDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcessoApi
     */
    public alteraSenha(body?: AlteracaoSenhaDTO, options?: any) {
        return AcessoApiFp(this.configuration).alteraSenha(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Recupera chave pública para autenticação
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcessoApi
     */
    public getChavePublica(options?: any) {
        return AcessoApiFp(this.configuration).getChavePublica(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Inicia o login de um usuario
     * @param {RequisicaoInicioAcessoDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcessoApi
     */
    public iniciaLogin(body?: RequisicaoInicioAcessoDTO, options?: any) {
        return AcessoApiFp(this.configuration).iniciaLogin(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Realiza o login no sistema
     * @param {RequisicaoLoginDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcessoApi
     */
    public login(body?: RequisicaoLoginDTO, options?: any) {
        return AcessoApiFp(this.configuration).login(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Realiza o login via OTP
     * @param {RequisicaoLoginOTPDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcessoApi
     */
    public loginOTP(body?: RequisicaoLoginOTPDTO, options?: any) {
        return AcessoApiFp(this.configuration).loginOTP(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Realiza o logout da aplicação para o token de acesso
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcessoApi
     */
    public logout(options?: any) {
        return AcessoApiFp(this.configuration).logout(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Redefine a senha do usuário identificado pelo token de redefinição
     * @param {RedefinicaoSenhaDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcessoApi
     */
    public redefineSenha(body?: RedefinicaoSenhaDTO, options?: any) {
        return AcessoApiFp(this.configuration).redefineSenha(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Realiza o refresh do token de sessão do usuário
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcessoApi
     */
    public refresh(options?: any) {
        return AcessoApiFp(this.configuration).refresh(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Registro o push token do dispositivo
     * @param {string} pushToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcessoApi
     */
    public registraPushToken(pushToken: string, options?: any) {
        return AcessoApiFp(this.configuration).registraPushToken(pushToken, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Solicita um OTP para o usuário indicado
     * @param {SolicitacaoOTPDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcessoApi
     */
    public solicitaOTP(body?: SolicitacaoOTPDTO, options?: any) {
        return AcessoApiFp(this.configuration).solicitaOTP(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Valida o OTP indicado
     * @param {ValidacaoOTPDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcessoApi
     */
    public validaOTP(body?: ValidacaoOTPDTO, options?: any) {
        return AcessoApiFp(this.configuration).validaOTP(body, options)(this.fetch, this.basePath);
    }

}

/**
 * AnimalApi - fetch parameter creator
 * @export
 */
export const AnimalApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Consulta os registro de animal
         * @param {string} [sisbov] 
         * @param {string} [bosch] 
         * @param {number} [raca] 
         * @param {'inativo' | 'vendido' | 'ativo'} [status] 
         * @param {string} [pesoMinimoBalancinha] 
         * @param {string} [pesoMaximoBalancinha] 
         * @param {number} [lote] 
         * @param {Date} [data] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consulta(sisbov?: string, bosch?: string, raca?: number, status?: 'inativo' | 'vendido' | 'ativo', pesoMinimoBalancinha?: string, pesoMaximoBalancinha?: string, lote?: number, data?: Date, pagina?: number, tamanhoPagina?: number, options: any = {}): FetchArgs {
            const localVarPath = `/animal`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sisbov !== undefined) {
                localVarQueryParameter['sisbov'] = sisbov;
            }

            if (bosch !== undefined) {
                localVarQueryParameter['bosch'] = bosch;
            }

            if (raca !== undefined) {
                localVarQueryParameter['raca'] = raca;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (pesoMinimoBalancinha !== undefined) {
                localVarQueryParameter['pesoMinimoBalancinha'] = pesoMinimoBalancinha;
            }

            if (pesoMaximoBalancinha !== undefined) {
                localVarQueryParameter['pesoMaximoBalancinha'] = pesoMaximoBalancinha;
            }

            if (lote !== undefined) {
                localVarQueryParameter['lote'] = lote;
            }

            if (data !== undefined) {
                localVarQueryParameter['data'] = (data as any).toISOString();
            }

            if (pagina !== undefined) {
                localVarQueryParameter['pagina'] = pagina;
            }

            if (tamanhoPagina !== undefined) {
                localVarQueryParameter['tamanhoPagina'] = tamanhoPagina;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta os registros de laudo de morte
         * @param {string} [codigo] 
         * @param {Date} [data] 
         * @param {string} [codigoLote] 
         * @param {string} [sisbov] 
         * @param {string} [bosch] 
         * @param {number} [local] 
         * @param {number} [tipo] 
         * @param {'pendente' | 'iniciado' | 'finalizado'} [status] 
         * @param {number} [veterinario] 
         * @param {number} [lote] 
         * @param {number} [idProprietario] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaLaudoMorte(codigo?: string, data?: Date, codigoLote?: string, sisbov?: string, bosch?: string, local?: number, tipo?: number, status?: 'pendente' | 'iniciado' | 'finalizado', veterinario?: number, lote?: number, idProprietario?: number, pagina?: number, tamanhoPagina?: number, options: any = {}): FetchArgs {
            const localVarPath = `/animal/laudo-morte`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (codigo !== undefined) {
                localVarQueryParameter['codigo'] = codigo;
            }

            if (data !== undefined) {
                localVarQueryParameter['data'] = (data as any).toISOString();
            }

            if (codigoLote !== undefined) {
                localVarQueryParameter['codigoLote'] = codigoLote;
            }

            if (sisbov !== undefined) {
                localVarQueryParameter['sisbov'] = sisbov;
            }

            if (bosch !== undefined) {
                localVarQueryParameter['bosch'] = bosch;
            }

            if (local !== undefined) {
                localVarQueryParameter['local'] = local;
            }

            if (tipo !== undefined) {
                localVarQueryParameter['tipo'] = tipo;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (veterinario !== undefined) {
                localVarQueryParameter['veterinario'] = veterinario;
            }

            if (lote !== undefined) {
                localVarQueryParameter['lote'] = lote;
            }

            if (idProprietario !== undefined) {
                localVarQueryParameter['idProprietario'] = idProprietario;
            }

            if (pagina !== undefined) {
                localVarQueryParameter['pagina'] = pagina;
            }

            if (tamanhoPagina !== undefined) {
                localVarQueryParameter['tamanhoPagina'] = tamanhoPagina;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta os registros de locais
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaLocais(options: any = {}): FetchArgs {
            const localVarPath = `/animal/local`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta os registros de locais de morte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaLocaisMorte(options: any = {}): FetchArgs {
            const localVarPath = `/animal/local-morte`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta os registros de laudo de morte do cliente logado
         * @param {string} [codigo] 
         * @param {Date} [data] 
         * @param {string} [codigoLote] 
         * @param {string} [sisbov] 
         * @param {string} [bosch] 
         * @param {number} [local] 
         * @param {number} [tipo] 
         * @param {'pendente' | 'iniciado' | 'finalizado'} [status] 
         * @param {number} [veterinario] 
         * @param {number} [lote] 
         * @param {number} [idProprietario] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMeusLaudoMorte(codigo?: string, data?: Date, codigoLote?: string, sisbov?: string, bosch?: string, local?: number, tipo?: number, status?: 'pendente' | 'iniciado' | 'finalizado', veterinario?: number, lote?: number, idProprietario?: number, pagina?: number, tamanhoPagina?: number, options: any = {}): FetchArgs {
            const localVarPath = `/animal/laudo-morte/meus`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (codigo !== undefined) {
                localVarQueryParameter['codigo'] = codigo;
            }

            if (data !== undefined) {
                localVarQueryParameter['data'] = (data as any).toISOString();
            }

            if (codigoLote !== undefined) {
                localVarQueryParameter['codigoLote'] = codigoLote;
            }

            if (sisbov !== undefined) {
                localVarQueryParameter['sisbov'] = sisbov;
            }

            if (bosch !== undefined) {
                localVarQueryParameter['bosch'] = bosch;
            }

            if (local !== undefined) {
                localVarQueryParameter['local'] = local;
            }

            if (tipo !== undefined) {
                localVarQueryParameter['tipo'] = tipo;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (veterinario !== undefined) {
                localVarQueryParameter['veterinario'] = veterinario;
            }

            if (lote !== undefined) {
                localVarQueryParameter['lote'] = lote;
            }

            if (idProprietario !== undefined) {
                localVarQueryParameter['idProprietario'] = idProprietario;
            }

            if (pagina !== undefined) {
                localVarQueryParameter['pagina'] = pagina;
            }

            if (tamanhoPagina !== undefined) {
                localVarQueryParameter['tamanhoPagina'] = tamanhoPagina;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta os registros de tratamento do cliente logado
         * @param {number} [lote] 
         * @param {number} [proprietario] 
         * @param {string} [pesquisa] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMeusTratamentos(lote?: number, proprietario?: number, pesquisa?: string, pagina?: number, tamanhoPagina?: number, options: any = {}): FetchArgs {
            const localVarPath = `/animal/tratamento/meus`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lote !== undefined) {
                localVarQueryParameter['lote'] = lote;
            }

            if (proprietario !== undefined) {
                localVarQueryParameter['proprietario'] = proprietario;
            }

            if (pesquisa !== undefined) {
                localVarQueryParameter['pesquisa'] = pesquisa;
            }

            if (pagina !== undefined) {
                localVarQueryParameter['pagina'] = pagina;
            }

            if (tamanhoPagina !== undefined) {
                localVarQueryParameter['tamanhoPagina'] = tamanhoPagina;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta os registros de movimentacao do cliente logado
         * @param {Date} [data] 
         * @param {string} [sisbovAnimal] 
         * @param {string} [bosch] 
         * @param {number} [motivo] 
         * @param {string} [codigoLote] 
         * @param {number} [de] 
         * @param {number} [para] 
         * @param {number} [animal] 
         * @param {number} [idLote] 
         * @param {number} [idProprietario] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMinhasMovimentacoes(data?: Date, sisbovAnimal?: string, bosch?: string, motivo?: number, codigoLote?: string, de?: number, para?: number, animal?: number, idLote?: number, idProprietario?: number, pagina?: number, tamanhoPagina?: number, options: any = {}): FetchArgs {
            const localVarPath = `/animal/movimentacao/meus`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (data !== undefined) {
                localVarQueryParameter['data'] = (data as any).toISOString();
            }

            if (sisbovAnimal !== undefined) {
                localVarQueryParameter['sisbovAnimal'] = sisbovAnimal;
            }

            if (bosch !== undefined) {
                localVarQueryParameter['bosch'] = bosch;
            }

            if (motivo !== undefined) {
                localVarQueryParameter['motivo'] = motivo;
            }

            if (codigoLote !== undefined) {
                localVarQueryParameter['codigoLote'] = codigoLote;
            }

            if (de !== undefined) {
                localVarQueryParameter['de'] = de;
            }

            if (para !== undefined) {
                localVarQueryParameter['para'] = para;
            }

            if (animal !== undefined) {
                localVarQueryParameter['animal'] = animal;
            }

            if (idLote !== undefined) {
                localVarQueryParameter['idLote'] = idLote;
            }

            if (idProprietario !== undefined) {
                localVarQueryParameter['idProprietario'] = idProprietario;
            }

            if (pagina !== undefined) {
                localVarQueryParameter['pagina'] = pagina;
            }

            if (tamanhoPagina !== undefined) {
                localVarQueryParameter['tamanhoPagina'] = tamanhoPagina;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta os registros de motivos de movimentação
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMotivosMovimentacao(options: any = {}): FetchArgs {
            const localVarPath = `/animal/motivo-movimentacao`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta os registros de motivos de tratamento
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMotivosTratamento(options: any = {}): FetchArgs {
            const localVarPath = `/animal/motivo-tratamento`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta os registros de movimentacao
         * @param {Date} [data] 
         * @param {string} [sisbovAnimal] 
         * @param {string} [bosch] 
         * @param {number} [motivo] 
         * @param {string} [codigoLote] 
         * @param {number} [de] 
         * @param {number} [para] 
         * @param {number} [animal] 
         * @param {number} [idLote] 
         * @param {number} [idProprietario] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMovimentacoes(data?: Date, sisbovAnimal?: string, bosch?: string, motivo?: number, codigoLote?: string, de?: number, para?: number, animal?: number, idLote?: number, idProprietario?: number, pagina?: number, tamanhoPagina?: number, options: any = {}): FetchArgs {
            const localVarPath = `/animal/movimentacao`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (data !== undefined) {
                localVarQueryParameter['data'] = (data as any).toISOString();
            }

            if (sisbovAnimal !== undefined) {
                localVarQueryParameter['sisbovAnimal'] = sisbovAnimal;
            }

            if (bosch !== undefined) {
                localVarQueryParameter['bosch'] = bosch;
            }

            if (motivo !== undefined) {
                localVarQueryParameter['motivo'] = motivo;
            }

            if (codigoLote !== undefined) {
                localVarQueryParameter['codigoLote'] = codigoLote;
            }

            if (de !== undefined) {
                localVarQueryParameter['de'] = de;
            }

            if (para !== undefined) {
                localVarQueryParameter['para'] = para;
            }

            if (animal !== undefined) {
                localVarQueryParameter['animal'] = animal;
            }

            if (idLote !== undefined) {
                localVarQueryParameter['idLote'] = idLote;
            }

            if (idProprietario !== undefined) {
                localVarQueryParameter['idProprietario'] = idProprietario;
            }

            if (pagina !== undefined) {
                localVarQueryParameter['pagina'] = pagina;
            }

            if (tamanhoPagina !== undefined) {
                localVarQueryParameter['tamanhoPagina'] = tamanhoPagina;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta os registros de raça
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaRacas(options: any = {}): FetchArgs {
            const localVarPath = `/animal/raca`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta os registros de motivos de tipo de morte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaTiposMorte(options: any = {}): FetchArgs {
            const localVarPath = `/animal/tipo-morte`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta os registros de tratamento
         * @param {Date} [data] 
         * @param {string} [sisbov] 
         * @param {string} [bosch] 
         * @param {number} [motivo] 
         * @param {string} [lote] 
         * @param {number} [animal] 
         * @param {string} [valorMinimo] 
         * @param {string} [valorMaximo] 
         * @param {number} [idLote] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaTratamentos(data?: Date, sisbov?: string, bosch?: string, motivo?: number, lote?: string, animal?: number, valorMinimo?: string, valorMaximo?: string, idLote?: number, pagina?: number, tamanhoPagina?: number, options: any = {}): FetchArgs {
            const localVarPath = `/animal/tratamento`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (data !== undefined) {
                localVarQueryParameter['data'] = (data as any).toISOString();
            }

            if (sisbov !== undefined) {
                localVarQueryParameter['sisbov'] = sisbov;
            }

            if (bosch !== undefined) {
                localVarQueryParameter['bosch'] = bosch;
            }

            if (motivo !== undefined) {
                localVarQueryParameter['motivo'] = motivo;
            }

            if (lote !== undefined) {
                localVarQueryParameter['lote'] = lote;
            }

            if (animal !== undefined) {
                localVarQueryParameter['animal'] = animal;
            }

            if (valorMinimo !== undefined) {
                localVarQueryParameter['valorMinimo'] = valorMinimo;
            }

            if (valorMaximo !== undefined) {
                localVarQueryParameter['valorMaximo'] = valorMaximo;
            }

            if (idLote !== undefined) {
                localVarQueryParameter['idLote'] = idLote;
            }

            if (pagina !== undefined) {
                localVarQueryParameter['pagina'] = pagina;
            }

            if (tamanhoPagina !== undefined) {
                localVarQueryParameter['tamanhoPagina'] = tamanhoPagina;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta animal pelo id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalha(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling detalha.');
            }
            const localVarPath = `/animal/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta o laudo de morte indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalhaLaudoMorte(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling detalhaLaudoMorte.');
            }
            const localVarPath = `/animal/{id}/laudo-morte`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Finaliza o laudo de morte
         * @param {number} id 
         * @param {FinalizacaoLaudoMorteDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizaLaudoMorte(id: number, body?: FinalizacaoLaudoMorteDTO, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling finalizaLaudoMorte.');
            }
            const localVarPath = `/animal/{id}/laudo-morte/finaliza`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (("FinalizacaoLaudoMorteDTO" !== "string" as any)) || localVarRequestOptions.headers['Content-Type'] === 'application/json' as any;
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Salva o Laudo de morte
         * @param {number} id 
         * @param {DiagnosticoDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salvaLaudoMorte(id: number, body?: DiagnosticoDTO, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling salvaLaudoMorte.');
            }
            const localVarPath = `/animal/{id}/laudo-morte`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (("DiagnosticoDTO" !== "string" as any)) || localVarRequestOptions.headers['Content-Type'] === 'application/json' as any;
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Salva um local de óbito
         * @param {LocalMorteDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salvaLocalMorte(body?: LocalMorteDTO, options: any = {}): FetchArgs {
            const localVarPath = `/animal/local-morte`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (("LocalMorteDTO" !== "string" as any)) || localVarRequestOptions.headers['Content-Type'] === 'application/json' as any;
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Salva as mídias de movimentação
         * @param {number} id 
         * @param {MidiasMovimentacaoDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salvaMidiasMovimentacao(id: number, body?: MidiasMovimentacaoDTO, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling salvaMidiasMovimentacao.');
            }
            const localVarPath = `/animal/movimentacao/{id}/midias`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (("MidiasMovimentacaoDTO" !== "string" as any)) || localVarRequestOptions.headers['Content-Type'] === 'application/json' as any;
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnimalApi - functional programming interface
 * @export
 */
export const AnimalApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Consulta os registro de animal
         * @param {string} [sisbov] 
         * @param {string} [bosch] 
         * @param {number} [raca] 
         * @param {'inativo' | 'vendido' | 'ativo'} [status] 
         * @param {string} [pesoMinimoBalancinha] 
         * @param {string} [pesoMaximoBalancinha] 
         * @param {number} [lote] 
         * @param {Date} [data] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consulta(sisbov?: string, bosch?: string, raca?: number, status?: 'inativo' | 'vendido' | 'ativo', pesoMinimoBalancinha?: string, pesoMaximoBalancinha?: string, lote?: number, data?: Date, pagina?: number, tamanhoPagina?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaginaAnimalDTO> {
            const localVarFetchArgs = AnimalApiFetchParamCreator(configuration).consulta(sisbov, bosch, raca, status, pesoMinimoBalancinha, pesoMaximoBalancinha, lote, data, pagina, tamanhoPagina, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta os registros de laudo de morte
         * @param {string} [codigo] 
         * @param {Date} [data] 
         * @param {string} [codigoLote] 
         * @param {string} [sisbov] 
         * @param {string} [bosch] 
         * @param {number} [local] 
         * @param {number} [tipo] 
         * @param {'pendente' | 'iniciado' | 'finalizado'} [status] 
         * @param {number} [veterinario] 
         * @param {number} [lote] 
         * @param {number} [idProprietario] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaLaudoMorte(codigo?: string, data?: Date, codigoLote?: string, sisbov?: string, bosch?: string, local?: number, tipo?: number, status?: 'pendente' | 'iniciado' | 'finalizado', veterinario?: number, lote?: number, idProprietario?: number, pagina?: number, tamanhoPagina?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaginaLaudoMorteDTO> {
            const localVarFetchArgs = AnimalApiFetchParamCreator(configuration).consultaLaudoMorte(codigo, data, codigoLote, sisbov, bosch, local, tipo, status, veterinario, lote, idProprietario, pagina, tamanhoPagina, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta os registros de locais
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaLocais(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<LocalDTO>> {
            const localVarFetchArgs = AnimalApiFetchParamCreator(configuration).consultaLocais(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta os registros de locais de morte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaLocaisMorte(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<LocalMorteDTO>> {
            const localVarFetchArgs = AnimalApiFetchParamCreator(configuration).consultaLocaisMorte(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta os registros de laudo de morte do cliente logado
         * @param {string} [codigo] 
         * @param {Date} [data] 
         * @param {string} [codigoLote] 
         * @param {string} [sisbov] 
         * @param {string} [bosch] 
         * @param {number} [local] 
         * @param {number} [tipo] 
         * @param {'pendente' | 'iniciado' | 'finalizado'} [status] 
         * @param {number} [veterinario] 
         * @param {number} [lote] 
         * @param {number} [idProprietario] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMeusLaudoMorte(codigo?: string, data?: Date, codigoLote?: string, sisbov?: string, bosch?: string, local?: number, tipo?: number, status?: 'pendente' | 'iniciado' | 'finalizado', veterinario?: number, lote?: number, idProprietario?: number, pagina?: number, tamanhoPagina?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaginaLaudoMorteDTO> {
            const localVarFetchArgs = AnimalApiFetchParamCreator(configuration).consultaMeusLaudoMorte(codigo, data, codigoLote, sisbov, bosch, local, tipo, status, veterinario, lote, idProprietario, pagina, tamanhoPagina, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta os registros de tratamento do cliente logado
         * @param {number} [lote] 
         * @param {number} [proprietario] 
         * @param {string} [pesquisa] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMeusTratamentos(lote?: number, proprietario?: number, pesquisa?: string, pagina?: number, tamanhoPagina?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaginaTratamentoDTO> {
            const localVarFetchArgs = AnimalApiFetchParamCreator(configuration).consultaMeusTratamentos(lote, proprietario, pesquisa, pagina, tamanhoPagina, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta os registros de movimentacao do cliente logado
         * @param {Date} [data] 
         * @param {string} [sisbovAnimal] 
         * @param {string} [bosch] 
         * @param {number} [motivo] 
         * @param {string} [codigoLote] 
         * @param {number} [de] 
         * @param {number} [para] 
         * @param {number} [animal] 
         * @param {number} [idLote] 
         * @param {number} [idProprietario] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMinhasMovimentacoes(data?: Date, sisbovAnimal?: string, bosch?: string, motivo?: number, codigoLote?: string, de?: number, para?: number, animal?: number, idLote?: number, idProprietario?: number, pagina?: number, tamanhoPagina?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaginaMovimentacaoDTO> {
            const localVarFetchArgs = AnimalApiFetchParamCreator(configuration).consultaMinhasMovimentacoes(data, sisbovAnimal, bosch, motivo, codigoLote, de, para, animal, idLote, idProprietario, pagina, tamanhoPagina, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta os registros de motivos de movimentação
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMotivosMovimentacao(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<MotivoMovimentacaoDTO>> {
            const localVarFetchArgs = AnimalApiFetchParamCreator(configuration).consultaMotivosMovimentacao(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta os registros de motivos de tratamento
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMotivosTratamento(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<MotivoTratamentoDTO>> {
            const localVarFetchArgs = AnimalApiFetchParamCreator(configuration).consultaMotivosTratamento(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta os registros de movimentacao
         * @param {Date} [data] 
         * @param {string} [sisbovAnimal] 
         * @param {string} [bosch] 
         * @param {number} [motivo] 
         * @param {string} [codigoLote] 
         * @param {number} [de] 
         * @param {number} [para] 
         * @param {number} [animal] 
         * @param {number} [idLote] 
         * @param {number} [idProprietario] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMovimentacoes(data?: Date, sisbovAnimal?: string, bosch?: string, motivo?: number, codigoLote?: string, de?: number, para?: number, animal?: number, idLote?: number, idProprietario?: number, pagina?: number, tamanhoPagina?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaginaMovimentacaoDTO> {
            const localVarFetchArgs = AnimalApiFetchParamCreator(configuration).consultaMovimentacoes(data, sisbovAnimal, bosch, motivo, codigoLote, de, para, animal, idLote, idProprietario, pagina, tamanhoPagina, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta os registros de raça
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaRacas(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<RacaDTO>> {
            const localVarFetchArgs = AnimalApiFetchParamCreator(configuration).consultaRacas(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta os registros de motivos de tipo de morte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaTiposMorte(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<TipoMorteDTO>> {
            const localVarFetchArgs = AnimalApiFetchParamCreator(configuration).consultaTiposMorte(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta os registros de tratamento
         * @param {Date} [data] 
         * @param {string} [sisbov] 
         * @param {string} [bosch] 
         * @param {number} [motivo] 
         * @param {string} [lote] 
         * @param {number} [animal] 
         * @param {string} [valorMinimo] 
         * @param {string} [valorMaximo] 
         * @param {number} [idLote] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaTratamentos(data?: Date, sisbov?: string, bosch?: string, motivo?: number, lote?: string, animal?: number, valorMinimo?: string, valorMaximo?: string, idLote?: number, pagina?: number, tamanhoPagina?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaginaTratamentoDTO> {
            const localVarFetchArgs = AnimalApiFetchParamCreator(configuration).consultaTratamentos(data, sisbov, bosch, motivo, lote, animal, valorMinimo, valorMaximo, idLote, pagina, tamanhoPagina, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta animal pelo id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalha(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AnimalDTO> {
            const localVarFetchArgs = AnimalApiFetchParamCreator(configuration).detalha(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta o laudo de morte indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalhaLaudoMorte(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LaudoMorteDTO> {
            const localVarFetchArgs = AnimalApiFetchParamCreator(configuration).detalhaLaudoMorte(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Finaliza o laudo de morte
         * @param {number} id 
         * @param {FinalizacaoLaudoMorteDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizaLaudoMorte(id: number, body?: FinalizacaoLaudoMorteDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocumentoDigitalDTO> {
            const localVarFetchArgs = AnimalApiFetchParamCreator(configuration).finalizaLaudoMorte(id, body, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Salva o Laudo de morte
         * @param {number} id 
         * @param {DiagnosticoDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salvaLaudoMorte(id: number, body?: DiagnosticoDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LaudoMorteDTO> {
            const localVarFetchArgs = AnimalApiFetchParamCreator(configuration).salvaLaudoMorte(id, body, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Salva um local de óbito
         * @param {LocalMorteDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salvaLocalMorte(body?: LocalMorteDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LocalMorteDTO> {
            const localVarFetchArgs = AnimalApiFetchParamCreator(configuration).salvaLocalMorte(body, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Salva as mídias de movimentação
         * @param {number} id 
         * @param {MidiasMovimentacaoDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salvaMidiasMovimentacao(id: number, body?: MidiasMovimentacaoDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MidiasMovimentacaoDTO> {
            const localVarFetchArgs = AnimalApiFetchParamCreator(configuration).salvaMidiasMovimentacao(id, body, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AnimalApi - factory interface
 * @export
 */
export const AnimalApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Consulta os registro de animal
         * @param {string} [sisbov] 
         * @param {string} [bosch] 
         * @param {number} [raca] 
         * @param {'inativo' | 'vendido' | 'ativo'} [status] 
         * @param {string} [pesoMinimoBalancinha] 
         * @param {string} [pesoMaximoBalancinha] 
         * @param {number} [lote] 
         * @param {Date} [data] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consulta(sisbov?: string, bosch?: string, raca?: number, status?: 'inativo' | 'vendido' | 'ativo', pesoMinimoBalancinha?: string, pesoMaximoBalancinha?: string, lote?: number, data?: Date, pagina?: number, tamanhoPagina?: number, options?: any) {
            return AnimalApiFp(configuration).consulta(sisbov, bosch, raca, status, pesoMinimoBalancinha, pesoMaximoBalancinha, lote, data, pagina, tamanhoPagina, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta os registros de laudo de morte
         * @param {string} [codigo] 
         * @param {Date} [data] 
         * @param {string} [codigoLote] 
         * @param {string} [sisbov] 
         * @param {string} [bosch] 
         * @param {number} [local] 
         * @param {number} [tipo] 
         * @param {'pendente' | 'iniciado' | 'finalizado'} [status] 
         * @param {number} [veterinario] 
         * @param {number} [lote] 
         * @param {number} [idProprietario] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaLaudoMorte(codigo?: string, data?: Date, codigoLote?: string, sisbov?: string, bosch?: string, local?: number, tipo?: number, status?: 'pendente' | 'iniciado' | 'finalizado', veterinario?: number, lote?: number, idProprietario?: number, pagina?: number, tamanhoPagina?: number, options?: any) {
            return AnimalApiFp(configuration).consultaLaudoMorte(codigo, data, codigoLote, sisbov, bosch, local, tipo, status, veterinario, lote, idProprietario, pagina, tamanhoPagina, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta os registros de locais
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaLocais(options?: any) {
            return AnimalApiFp(configuration).consultaLocais(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta os registros de locais de morte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaLocaisMorte(options?: any) {
            return AnimalApiFp(configuration).consultaLocaisMorte(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta os registros de laudo de morte do cliente logado
         * @param {string} [codigo] 
         * @param {Date} [data] 
         * @param {string} [codigoLote] 
         * @param {string} [sisbov] 
         * @param {string} [bosch] 
         * @param {number} [local] 
         * @param {number} [tipo] 
         * @param {'pendente' | 'iniciado' | 'finalizado'} [status] 
         * @param {number} [veterinario] 
         * @param {number} [lote] 
         * @param {number} [idProprietario] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMeusLaudoMorte(codigo?: string, data?: Date, codigoLote?: string, sisbov?: string, bosch?: string, local?: number, tipo?: number, status?: 'pendente' | 'iniciado' | 'finalizado', veterinario?: number, lote?: number, idProprietario?: number, pagina?: number, tamanhoPagina?: number, options?: any) {
            return AnimalApiFp(configuration).consultaMeusLaudoMorte(codigo, data, codigoLote, sisbov, bosch, local, tipo, status, veterinario, lote, idProprietario, pagina, tamanhoPagina, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta os registros de tratamento do cliente logado
         * @param {number} [lote] 
         * @param {number} [proprietario] 
         * @param {string} [pesquisa] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMeusTratamentos(lote?: number, proprietario?: number, pesquisa?: string, pagina?: number, tamanhoPagina?: number, options?: any) {
            return AnimalApiFp(configuration).consultaMeusTratamentos(lote, proprietario, pesquisa, pagina, tamanhoPagina, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta os registros de movimentacao do cliente logado
         * @param {Date} [data] 
         * @param {string} [sisbovAnimal] 
         * @param {string} [bosch] 
         * @param {number} [motivo] 
         * @param {string} [codigoLote] 
         * @param {number} [de] 
         * @param {number} [para] 
         * @param {number} [animal] 
         * @param {number} [idLote] 
         * @param {number} [idProprietario] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMinhasMovimentacoes(data?: Date, sisbovAnimal?: string, bosch?: string, motivo?: number, codigoLote?: string, de?: number, para?: number, animal?: number, idLote?: number, idProprietario?: number, pagina?: number, tamanhoPagina?: number, options?: any) {
            return AnimalApiFp(configuration).consultaMinhasMovimentacoes(data, sisbovAnimal, bosch, motivo, codigoLote, de, para, animal, idLote, idProprietario, pagina, tamanhoPagina, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta os registros de motivos de movimentação
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMotivosMovimentacao(options?: any) {
            return AnimalApiFp(configuration).consultaMotivosMovimentacao(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta os registros de motivos de tratamento
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMotivosTratamento(options?: any) {
            return AnimalApiFp(configuration).consultaMotivosTratamento(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta os registros de movimentacao
         * @param {Date} [data] 
         * @param {string} [sisbovAnimal] 
         * @param {string} [bosch] 
         * @param {number} [motivo] 
         * @param {string} [codigoLote] 
         * @param {number} [de] 
         * @param {number} [para] 
         * @param {number} [animal] 
         * @param {number} [idLote] 
         * @param {number} [idProprietario] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMovimentacoes(data?: Date, sisbovAnimal?: string, bosch?: string, motivo?: number, codigoLote?: string, de?: number, para?: number, animal?: number, idLote?: number, idProprietario?: number, pagina?: number, tamanhoPagina?: number, options?: any) {
            return AnimalApiFp(configuration).consultaMovimentacoes(data, sisbovAnimal, bosch, motivo, codigoLote, de, para, animal, idLote, idProprietario, pagina, tamanhoPagina, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta os registros de raça
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaRacas(options?: any) {
            return AnimalApiFp(configuration).consultaRacas(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta os registros de motivos de tipo de morte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaTiposMorte(options?: any) {
            return AnimalApiFp(configuration).consultaTiposMorte(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta os registros de tratamento
         * @param {Date} [data] 
         * @param {string} [sisbov] 
         * @param {string} [bosch] 
         * @param {number} [motivo] 
         * @param {string} [lote] 
         * @param {number} [animal] 
         * @param {string} [valorMinimo] 
         * @param {string} [valorMaximo] 
         * @param {number} [idLote] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaTratamentos(data?: Date, sisbov?: string, bosch?: string, motivo?: number, lote?: string, animal?: number, valorMinimo?: string, valorMaximo?: string, idLote?: number, pagina?: number, tamanhoPagina?: number, options?: any) {
            return AnimalApiFp(configuration).consultaTratamentos(data, sisbov, bosch, motivo, lote, animal, valorMinimo, valorMaximo, idLote, pagina, tamanhoPagina, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta animal pelo id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalha(id: number, options?: any) {
            return AnimalApiFp(configuration).detalha(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta o laudo de morte indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalhaLaudoMorte(id: number, options?: any) {
            return AnimalApiFp(configuration).detalhaLaudoMorte(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Finaliza o laudo de morte
         * @param {number} id 
         * @param {FinalizacaoLaudoMorteDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizaLaudoMorte(id: number, body?: FinalizacaoLaudoMorteDTO, options?: any) {
            return AnimalApiFp(configuration).finalizaLaudoMorte(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Salva o Laudo de morte
         * @param {number} id 
         * @param {DiagnosticoDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salvaLaudoMorte(id: number, body?: DiagnosticoDTO, options?: any) {
            return AnimalApiFp(configuration).salvaLaudoMorte(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Salva um local de óbito
         * @param {LocalMorteDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salvaLocalMorte(body?: LocalMorteDTO, options?: any) {
            return AnimalApiFp(configuration).salvaLocalMorte(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Salva as mídias de movimentação
         * @param {number} id 
         * @param {MidiasMovimentacaoDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salvaMidiasMovimentacao(id: number, body?: MidiasMovimentacaoDTO, options?: any) {
            return AnimalApiFp(configuration).salvaMidiasMovimentacao(id, body, options)(fetch, basePath);
        },
    };
};

/**
 * AnimalApi - object-oriented interface
 * @export
 * @class AnimalApi
 * @extends {BaseAPI}
 */
export class AnimalApi extends BaseAPI {
    /**
     * 
     * @summary Consulta os registro de animal
     * @param {string} [sisbov] 
     * @param {string} [bosch] 
     * @param {number} [raca] 
     * @param {'inativo' | 'vendido' | 'ativo'} [status] 
     * @param {string} [pesoMinimoBalancinha] 
     * @param {string} [pesoMaximoBalancinha] 
     * @param {number} [lote] 
     * @param {Date} [data] 
     * @param {number} [pagina] 
     * @param {number} [tamanhoPagina] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalApi
     */
    public consulta(sisbov?: string, bosch?: string, raca?: number, status?: 'inativo' | 'vendido' | 'ativo', pesoMinimoBalancinha?: string, pesoMaximoBalancinha?: string, lote?: number, data?: Date, pagina?: number, tamanhoPagina?: number, options?: any) {
        return AnimalApiFp(this.configuration).consulta(sisbov, bosch, raca, status, pesoMinimoBalancinha, pesoMaximoBalancinha, lote, data, pagina, tamanhoPagina, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta os registros de laudo de morte
     * @param {string} [codigo] 
     * @param {Date} [data] 
     * @param {string} [codigoLote] 
     * @param {string} [sisbov] 
     * @param {string} [bosch] 
     * @param {number} [local] 
     * @param {number} [tipo] 
     * @param {'pendente' | 'iniciado' | 'finalizado'} [status] 
     * @param {number} [veterinario] 
     * @param {number} [lote] 
     * @param {number} [idProprietario] 
     * @param {number} [pagina] 
     * @param {number} [tamanhoPagina] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalApi
     */
    public consultaLaudoMorte(codigo?: string, data?: Date, codigoLote?: string, sisbov?: string, bosch?: string, local?: number, tipo?: number, status?: 'pendente' | 'iniciado' | 'finalizado', veterinario?: number, lote?: number, idProprietario?: number, pagina?: number, tamanhoPagina?: number, options?: any) {
        return AnimalApiFp(this.configuration).consultaLaudoMorte(codigo, data, codigoLote, sisbov, bosch, local, tipo, status, veterinario, lote, idProprietario, pagina, tamanhoPagina, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta os registros de locais
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalApi
     */
    public consultaLocais(options?: any) {
        return AnimalApiFp(this.configuration).consultaLocais(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta os registros de locais de morte
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalApi
     */
    public consultaLocaisMorte(options?: any) {
        return AnimalApiFp(this.configuration).consultaLocaisMorte(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta os registros de laudo de morte do cliente logado
     * @param {string} [codigo] 
     * @param {Date} [data] 
     * @param {string} [codigoLote] 
     * @param {string} [sisbov] 
     * @param {string} [bosch] 
     * @param {number} [local] 
     * @param {number} [tipo] 
     * @param {'pendente' | 'iniciado' | 'finalizado'} [status] 
     * @param {number} [veterinario] 
     * @param {number} [lote] 
     * @param {number} [idProprietario] 
     * @param {number} [pagina] 
     * @param {number} [tamanhoPagina] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalApi
     */
    public consultaMeusLaudoMorte(codigo?: string, data?: Date, codigoLote?: string, sisbov?: string, bosch?: string, local?: number, tipo?: number, status?: 'pendente' | 'iniciado' | 'finalizado', veterinario?: number, lote?: number, idProprietario?: number, pagina?: number, tamanhoPagina?: number, options?: any) {
        return AnimalApiFp(this.configuration).consultaMeusLaudoMorte(codigo, data, codigoLote, sisbov, bosch, local, tipo, status, veterinario, lote, idProprietario, pagina, tamanhoPagina, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta os registros de tratamento do cliente logado
     * @param {number} [lote] 
     * @param {number} [proprietario] 
     * @param {string} [pesquisa] 
     * @param {number} [pagina] 
     * @param {number} [tamanhoPagina] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalApi
     */
    public consultaMeusTratamentos(lote?: number, proprietario?: number, pesquisa?: string, pagina?: number, tamanhoPagina?: number, options?: any) {
        return AnimalApiFp(this.configuration).consultaMeusTratamentos(lote, proprietario, pesquisa, pagina, tamanhoPagina, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta os registros de movimentacao do cliente logado
     * @param {Date} [data] 
     * @param {string} [sisbovAnimal] 
     * @param {string} [bosch] 
     * @param {number} [motivo] 
     * @param {string} [codigoLote] 
     * @param {number} [de] 
     * @param {number} [para] 
     * @param {number} [animal] 
     * @param {number} [idLote] 
     * @param {number} [idProprietario] 
     * @param {number} [pagina] 
     * @param {number} [tamanhoPagina] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalApi
     */
    public consultaMinhasMovimentacoes(data?: Date, sisbovAnimal?: string, bosch?: string, motivo?: number, codigoLote?: string, de?: number, para?: number, animal?: number, idLote?: number, idProprietario?: number, pagina?: number, tamanhoPagina?: number, options?: any) {
        return AnimalApiFp(this.configuration).consultaMinhasMovimentacoes(data, sisbovAnimal, bosch, motivo, codigoLote, de, para, animal, idLote, idProprietario, pagina, tamanhoPagina, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta os registros de motivos de movimentação
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalApi
     */
    public consultaMotivosMovimentacao(options?: any) {
        return AnimalApiFp(this.configuration).consultaMotivosMovimentacao(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta os registros de motivos de tratamento
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalApi
     */
    public consultaMotivosTratamento(options?: any) {
        return AnimalApiFp(this.configuration).consultaMotivosTratamento(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta os registros de movimentacao
     * @param {Date} [data] 
     * @param {string} [sisbovAnimal] 
     * @param {string} [bosch] 
     * @param {number} [motivo] 
     * @param {string} [codigoLote] 
     * @param {number} [de] 
     * @param {number} [para] 
     * @param {number} [animal] 
     * @param {number} [idLote] 
     * @param {number} [idProprietario] 
     * @param {number} [pagina] 
     * @param {number} [tamanhoPagina] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalApi
     */
    public consultaMovimentacoes(data?: Date, sisbovAnimal?: string, bosch?: string, motivo?: number, codigoLote?: string, de?: number, para?: number, animal?: number, idLote?: number, idProprietario?: number, pagina?: number, tamanhoPagina?: number, options?: any) {
        return AnimalApiFp(this.configuration).consultaMovimentacoes(data, sisbovAnimal, bosch, motivo, codigoLote, de, para, animal, idLote, idProprietario, pagina, tamanhoPagina, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta os registros de raça
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalApi
     */
    public consultaRacas(options?: any) {
        return AnimalApiFp(this.configuration).consultaRacas(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta os registros de motivos de tipo de morte
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalApi
     */
    public consultaTiposMorte(options?: any) {
        return AnimalApiFp(this.configuration).consultaTiposMorte(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta os registros de tratamento
     * @param {Date} [data] 
     * @param {string} [sisbov] 
     * @param {string} [bosch] 
     * @param {number} [motivo] 
     * @param {string} [lote] 
     * @param {number} [animal] 
     * @param {string} [valorMinimo] 
     * @param {string} [valorMaximo] 
     * @param {number} [idLote] 
     * @param {number} [pagina] 
     * @param {number} [tamanhoPagina] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalApi
     */
    public consultaTratamentos(data?: Date, sisbov?: string, bosch?: string, motivo?: number, lote?: string, animal?: number, valorMinimo?: string, valorMaximo?: string, idLote?: number, pagina?: number, tamanhoPagina?: number, options?: any) {
        return AnimalApiFp(this.configuration).consultaTratamentos(data, sisbov, bosch, motivo, lote, animal, valorMinimo, valorMaximo, idLote, pagina, tamanhoPagina, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta animal pelo id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalApi
     */
    public detalha(id: number, options?: any) {
        return AnimalApiFp(this.configuration).detalha(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta o laudo de morte indicado
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalApi
     */
    public detalhaLaudoMorte(id: number, options?: any) {
        return AnimalApiFp(this.configuration).detalhaLaudoMorte(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Finaliza o laudo de morte
     * @param {number} id 
     * @param {FinalizacaoLaudoMorteDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalApi
     */
    public finalizaLaudoMorte(id: number, body?: FinalizacaoLaudoMorteDTO, options?: any) {
        return AnimalApiFp(this.configuration).finalizaLaudoMorte(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Salva o Laudo de morte
     * @param {number} id 
     * @param {DiagnosticoDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalApi
     */
    public salvaLaudoMorte(id: number, body?: DiagnosticoDTO, options?: any) {
        return AnimalApiFp(this.configuration).salvaLaudoMorte(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Salva um local de óbito
     * @param {LocalMorteDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalApi
     */
    public salvaLocalMorte(body?: LocalMorteDTO, options?: any) {
        return AnimalApiFp(this.configuration).salvaLocalMorte(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Salva as mídias de movimentação
     * @param {number} id 
     * @param {MidiasMovimentacaoDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalApi
     */
    public salvaMidiasMovimentacao(id: number, body?: MidiasMovimentacaoDTO, options?: any) {
        return AnimalApiFp(this.configuration).salvaMidiasMovimentacao(id, body, options)(this.fetch, this.basePath);
    }

}

/**
 * ArquivoApi - fetch parameter creator
 * @export
 */
export const ArquivoApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gera as configurações de upload de um novo arquivo
         * @param {DadosArquivoDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geraConfiguracaoUpload(body?: DadosArquivoDTO, options: any = {}): FetchArgs {
            const localVarPath = `/arquivo/upload/config`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (("DadosArquivoDTO" !== "string" as any)) || localVarRequestOptions.headers['Content-Type'] === 'application/json' as any;
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArquivoApi - functional programming interface
 * @export
 */
export const ArquivoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gera as configurações de upload de um novo arquivo
         * @param {DadosArquivoDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geraConfiguracaoUpload(body?: DadosArquivoDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ConfiguracaoUploadArquivoDTO> {
            const localVarFetchArgs = ArquivoApiFetchParamCreator(configuration).geraConfiguracaoUpload(body, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ArquivoApi - factory interface
 * @export
 */
export const ArquivoApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Gera as configurações de upload de um novo arquivo
         * @param {DadosArquivoDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geraConfiguracaoUpload(body?: DadosArquivoDTO, options?: any) {
            return ArquivoApiFp(configuration).geraConfiguracaoUpload(body, options)(fetch, basePath);
        },
    };
};

/**
 * ArquivoApi - object-oriented interface
 * @export
 * @class ArquivoApi
 * @extends {BaseAPI}
 */
export class ArquivoApi extends BaseAPI {
    /**
     * 
     * @summary Gera as configurações de upload de um novo arquivo
     * @param {DadosArquivoDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArquivoApi
     */
    public geraConfiguracaoUpload(body?: DadosArquivoDTO, options?: any) {
        return ArquivoApiFp(this.configuration).geraConfiguracaoUpload(body, options)(this.fetch, this.basePath);
    }

}

/**
 * AuditoriaApi - fetch parameter creator
 * @export
 */
export const AuditoriaApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Consulta os registros de auditoria com base no filtro indicado
         * @param {number} [cliente] 
         * @param {number} [usuario] 
         * @param {Date} [inicio] 
         * @param {Date} [termino] 
         * @param {'1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14' | '15' | '16' | '17' | '18' | '19' | '20' | '21'} [funcionalidade] 
         * @param {'sucesso' | 'erro' | 'falha_negocial'} [status] 
         * @param {string} [sessao] 
         * @param {'android' | 'ios' | 'browser' | 'servidor'} [tipoDispositivo] 
         * @param {string} [uuidDispositivo] 
         * @param {string} [entityType] 
         * @param {string} [entityId] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consulta(cliente?: number, usuario?: number, inicio?: Date, termino?: Date, funcionalidade?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14' | '15' | '16' | '17' | '18' | '19' | '20' | '21', status?: 'sucesso' | 'erro' | 'falha_negocial', sessao?: string, tipoDispositivo?: 'android' | 'ios' | 'browser' | 'servidor', uuidDispositivo?: string, entityType?: string, entityId?: string, pagina?: number, tamanhoPagina?: number, options: any = {}): FetchArgs {
            const localVarPath = `/auditoria`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cliente !== undefined) {
                localVarQueryParameter['cliente'] = cliente;
            }

            if (usuario !== undefined) {
                localVarQueryParameter['usuario'] = usuario;
            }

            if (inicio !== undefined) {
                localVarQueryParameter['inicio'] = (inicio as any).toISOString();
            }

            if (termino !== undefined) {
                localVarQueryParameter['termino'] = (termino as any).toISOString();
            }

            if (funcionalidade !== undefined) {
                localVarQueryParameter['funcionalidade'] = funcionalidade;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (sessao !== undefined) {
                localVarQueryParameter['sessao'] = sessao;
            }

            if (tipoDispositivo !== undefined) {
                localVarQueryParameter['tipoDispositivo'] = tipoDispositivo;
            }

            if (uuidDispositivo !== undefined) {
                localVarQueryParameter['uuidDispositivo'] = uuidDispositivo;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entityType'] = entityType;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entityId'] = entityId;
            }

            if (pagina !== undefined) {
                localVarQueryParameter['pagina'] = pagina;
            }

            if (tamanhoPagina !== undefined) {
                localVarQueryParameter['tamanhoPagina'] = tamanhoPagina;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Detalha o registro de auditoria indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalha(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling detalha.');
            }
            const localVarPath = `/auditoria/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta os registros de auditoria com base no filtro indicado
         * @param {number} [cliente] 
         * @param {number} [usuario] 
         * @param {Date} [inicio] 
         * @param {Date} [termino] 
         * @param {'1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14' | '15' | '16' | '17' | '18' | '19' | '20' | '21'} [funcionalidade] 
         * @param {'sucesso' | 'erro' | 'falha_negocial'} [status] 
         * @param {string} [sessao] 
         * @param {'android' | 'ios' | 'browser' | 'servidor'} [tipoDispositivo] 
         * @param {string} [uuidDispositivo] 
         * @param {string} [entityType] 
         * @param {string} [entityId] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exporta(cliente?: number, usuario?: number, inicio?: Date, termino?: Date, funcionalidade?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14' | '15' | '16' | '17' | '18' | '19' | '20' | '21', status?: 'sucesso' | 'erro' | 'falha_negocial', sessao?: string, tipoDispositivo?: 'android' | 'ios' | 'browser' | 'servidor', uuidDispositivo?: string, entityType?: string, entityId?: string, pagina?: number, tamanhoPagina?: number, options: any = {}): FetchArgs {
            const localVarPath = `/auditoria/exporta`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cliente !== undefined) {
                localVarQueryParameter['cliente'] = cliente;
            }

            if (usuario !== undefined) {
                localVarQueryParameter['usuario'] = usuario;
            }

            if (inicio !== undefined) {
                localVarQueryParameter['inicio'] = (inicio as any).toISOString();
            }

            if (termino !== undefined) {
                localVarQueryParameter['termino'] = (termino as any).toISOString();
            }

            if (funcionalidade !== undefined) {
                localVarQueryParameter['funcionalidade'] = funcionalidade;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (sessao !== undefined) {
                localVarQueryParameter['sessao'] = sessao;
            }

            if (tipoDispositivo !== undefined) {
                localVarQueryParameter['tipoDispositivo'] = tipoDispositivo;
            }

            if (uuidDispositivo !== undefined) {
                localVarQueryParameter['uuidDispositivo'] = uuidDispositivo;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entityType'] = entityType;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entityId'] = entityId;
            }

            if (pagina !== undefined) {
                localVarQueryParameter['pagina'] = pagina;
            }

            if (tamanhoPagina !== undefined) {
                localVarQueryParameter['tamanhoPagina'] = tamanhoPagina;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuditoriaApi - functional programming interface
 * @export
 */
export const AuditoriaApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Consulta os registros de auditoria com base no filtro indicado
         * @param {number} [cliente] 
         * @param {number} [usuario] 
         * @param {Date} [inicio] 
         * @param {Date} [termino] 
         * @param {'1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14' | '15' | '16' | '17' | '18' | '19' | '20' | '21'} [funcionalidade] 
         * @param {'sucesso' | 'erro' | 'falha_negocial'} [status] 
         * @param {string} [sessao] 
         * @param {'android' | 'ios' | 'browser' | 'servidor'} [tipoDispositivo] 
         * @param {string} [uuidDispositivo] 
         * @param {string} [entityType] 
         * @param {string} [entityId] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consulta(cliente?: number, usuario?: number, inicio?: Date, termino?: Date, funcionalidade?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14' | '15' | '16' | '17' | '18' | '19' | '20' | '21', status?: 'sucesso' | 'erro' | 'falha_negocial', sessao?: string, tipoDispositivo?: 'android' | 'ios' | 'browser' | 'servidor', uuidDispositivo?: string, entityType?: string, entityId?: string, pagina?: number, tamanhoPagina?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaginaRegistroAuditoriaDTO> {
            const localVarFetchArgs = AuditoriaApiFetchParamCreator(configuration).consulta(cliente, usuario, inicio, termino, funcionalidade, status, sessao, tipoDispositivo, uuidDispositivo, entityType, entityId, pagina, tamanhoPagina, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Detalha o registro de auditoria indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalha(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RegistroAuditoriaDTO> {
            const localVarFetchArgs = AuditoriaApiFetchParamCreator(configuration).detalha(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta os registros de auditoria com base no filtro indicado
         * @param {number} [cliente] 
         * @param {number} [usuario] 
         * @param {Date} [inicio] 
         * @param {Date} [termino] 
         * @param {'1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14' | '15' | '16' | '17' | '18' | '19' | '20' | '21'} [funcionalidade] 
         * @param {'sucesso' | 'erro' | 'falha_negocial'} [status] 
         * @param {string} [sessao] 
         * @param {'android' | 'ios' | 'browser' | 'servidor'} [tipoDispositivo] 
         * @param {string} [uuidDispositivo] 
         * @param {string} [entityType] 
         * @param {string} [entityId] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exporta(cliente?: number, usuario?: number, inicio?: Date, termino?: Date, funcionalidade?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14' | '15' | '16' | '17' | '18' | '19' | '20' | '21', status?: 'sucesso' | 'erro' | 'falha_negocial', sessao?: string, tipoDispositivo?: 'android' | 'ios' | 'browser' | 'servidor', uuidDispositivo?: string, entityType?: string, entityId?: string, pagina?: number, tamanhoPagina?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ArquivoDTO> {
            const localVarFetchArgs = AuditoriaApiFetchParamCreator(configuration).exporta(cliente, usuario, inicio, termino, funcionalidade, status, sessao, tipoDispositivo, uuidDispositivo, entityType, entityId, pagina, tamanhoPagina, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuditoriaApi - factory interface
 * @export
 */
export const AuditoriaApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Consulta os registros de auditoria com base no filtro indicado
         * @param {number} [cliente] 
         * @param {number} [usuario] 
         * @param {Date} [inicio] 
         * @param {Date} [termino] 
         * @param {'1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14' | '15' | '16' | '17' | '18' | '19' | '20' | '21'} [funcionalidade] 
         * @param {'sucesso' | 'erro' | 'falha_negocial'} [status] 
         * @param {string} [sessao] 
         * @param {'android' | 'ios' | 'browser' | 'servidor'} [tipoDispositivo] 
         * @param {string} [uuidDispositivo] 
         * @param {string} [entityType] 
         * @param {string} [entityId] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consulta(cliente?: number, usuario?: number, inicio?: Date, termino?: Date, funcionalidade?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14' | '15' | '16' | '17' | '18' | '19' | '20' | '21', status?: 'sucesso' | 'erro' | 'falha_negocial', sessao?: string, tipoDispositivo?: 'android' | 'ios' | 'browser' | 'servidor', uuidDispositivo?: string, entityType?: string, entityId?: string, pagina?: number, tamanhoPagina?: number, options?: any) {
            return AuditoriaApiFp(configuration).consulta(cliente, usuario, inicio, termino, funcionalidade, status, sessao, tipoDispositivo, uuidDispositivo, entityType, entityId, pagina, tamanhoPagina, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Detalha o registro de auditoria indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalha(id: number, options?: any) {
            return AuditoriaApiFp(configuration).detalha(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta os registros de auditoria com base no filtro indicado
         * @param {number} [cliente] 
         * @param {number} [usuario] 
         * @param {Date} [inicio] 
         * @param {Date} [termino] 
         * @param {'1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14' | '15' | '16' | '17' | '18' | '19' | '20' | '21'} [funcionalidade] 
         * @param {'sucesso' | 'erro' | 'falha_negocial'} [status] 
         * @param {string} [sessao] 
         * @param {'android' | 'ios' | 'browser' | 'servidor'} [tipoDispositivo] 
         * @param {string} [uuidDispositivo] 
         * @param {string} [entityType] 
         * @param {string} [entityId] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exporta(cliente?: number, usuario?: number, inicio?: Date, termino?: Date, funcionalidade?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14' | '15' | '16' | '17' | '18' | '19' | '20' | '21', status?: 'sucesso' | 'erro' | 'falha_negocial', sessao?: string, tipoDispositivo?: 'android' | 'ios' | 'browser' | 'servidor', uuidDispositivo?: string, entityType?: string, entityId?: string, pagina?: number, tamanhoPagina?: number, options?: any) {
            return AuditoriaApiFp(configuration).exporta(cliente, usuario, inicio, termino, funcionalidade, status, sessao, tipoDispositivo, uuidDispositivo, entityType, entityId, pagina, tamanhoPagina, options)(fetch, basePath);
        },
    };
};

/**
 * AuditoriaApi - object-oriented interface
 * @export
 * @class AuditoriaApi
 * @extends {BaseAPI}
 */
export class AuditoriaApi extends BaseAPI {
    /**
     * 
     * @summary Consulta os registros de auditoria com base no filtro indicado
     * @param {number} [cliente] 
     * @param {number} [usuario] 
     * @param {Date} [inicio] 
     * @param {Date} [termino] 
     * @param {'1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14' | '15' | '16' | '17' | '18' | '19' | '20' | '21'} [funcionalidade] 
     * @param {'sucesso' | 'erro' | 'falha_negocial'} [status] 
     * @param {string} [sessao] 
     * @param {'android' | 'ios' | 'browser' | 'servidor'} [tipoDispositivo] 
     * @param {string} [uuidDispositivo] 
     * @param {string} [entityType] 
     * @param {string} [entityId] 
     * @param {number} [pagina] 
     * @param {number} [tamanhoPagina] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditoriaApi
     */
    public consulta(cliente?: number, usuario?: number, inicio?: Date, termino?: Date, funcionalidade?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14' | '15' | '16' | '17' | '18' | '19' | '20' | '21', status?: 'sucesso' | 'erro' | 'falha_negocial', sessao?: string, tipoDispositivo?: 'android' | 'ios' | 'browser' | 'servidor', uuidDispositivo?: string, entityType?: string, entityId?: string, pagina?: number, tamanhoPagina?: number, options?: any) {
        return AuditoriaApiFp(this.configuration).consulta(cliente, usuario, inicio, termino, funcionalidade, status, sessao, tipoDispositivo, uuidDispositivo, entityType, entityId, pagina, tamanhoPagina, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Detalha o registro de auditoria indicado
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditoriaApi
     */
    public detalha(id: number, options?: any) {
        return AuditoriaApiFp(this.configuration).detalha(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta os registros de auditoria com base no filtro indicado
     * @param {number} [cliente] 
     * @param {number} [usuario] 
     * @param {Date} [inicio] 
     * @param {Date} [termino] 
     * @param {'1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14' | '15' | '16' | '17' | '18' | '19' | '20' | '21'} [funcionalidade] 
     * @param {'sucesso' | 'erro' | 'falha_negocial'} [status] 
     * @param {string} [sessao] 
     * @param {'android' | 'ios' | 'browser' | 'servidor'} [tipoDispositivo] 
     * @param {string} [uuidDispositivo] 
     * @param {string} [entityType] 
     * @param {string} [entityId] 
     * @param {number} [pagina] 
     * @param {number} [tamanhoPagina] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditoriaApi
     */
    public exporta(cliente?: number, usuario?: number, inicio?: Date, termino?: Date, funcionalidade?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14' | '15' | '16' | '17' | '18' | '19' | '20' | '21', status?: 'sucesso' | 'erro' | 'falha_negocial', sessao?: string, tipoDispositivo?: 'android' | 'ios' | 'browser' | 'servidor', uuidDispositivo?: string, entityType?: string, entityId?: string, pagina?: number, tamanhoPagina?: number, options?: any) {
        return AuditoriaApiFp(this.configuration).exporta(cliente, usuario, inicio, termino, funcionalidade, status, sessao, tipoDispositivo, uuidDispositivo, entityType, entityId, pagina, tamanhoPagina, options)(this.fetch, this.basePath);
    }

}

/**
 * CargaApi - fetch parameter creator
 * @export
 */
export const CargaApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Consulta os dados da última carga
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaDadosUltimaCarga(options: any = {}): FetchArgs {
            const localVarPath = `/carga`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Executa um novo processamento de carga
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exceutaProcessamentoCarga(options: any = {}): FetchArgs {
            const localVarPath = `/carga`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CargaApi - functional programming interface
 * @export
 */
export const CargaApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Consulta os dados da última carga
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaDadosUltimaCarga(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DadosUltimaCargaDTO> {
            const localVarFetchArgs = CargaApiFetchParamCreator(configuration).consultaDadosUltimaCarga(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Executa um novo processamento de carga
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exceutaProcessamentoCarga(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CargaApiFetchParamCreator(configuration).exceutaProcessamentoCarga(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CargaApi - factory interface
 * @export
 */
export const CargaApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Consulta os dados da última carga
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaDadosUltimaCarga(options?: any) {
            return CargaApiFp(configuration).consultaDadosUltimaCarga(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Executa um novo processamento de carga
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exceutaProcessamentoCarga(options?: any) {
            return CargaApiFp(configuration).exceutaProcessamentoCarga(options)(fetch, basePath);
        },
    };
};

/**
 * CargaApi - object-oriented interface
 * @export
 * @class CargaApi
 * @extends {BaseAPI}
 */
export class CargaApi extends BaseAPI {
    /**
     * 
     * @summary Consulta os dados da última carga
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CargaApi
     */
    public consultaDadosUltimaCarga(options?: any) {
        return CargaApiFp(this.configuration).consultaDadosUltimaCarga(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Executa um novo processamento de carga
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CargaApi
     */
    public exceutaProcessamentoCarga(options?: any) {
        return CargaApiFp(this.configuration).exceutaProcessamentoCarga(options)(this.fetch, this.basePath);
    }

}

/**
 * ClienteApi - fetch parameter creator
 * @export
 */
export const ClienteApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Consulta os registros cadastrados
         * @param {string} [codigo] 
         * @param {string} [nome] 
         * @param {string} [cpfCnpj] 
         * @param {'inativo' | 'ativo' | 'bloqueado' | 'excluido'} [status] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consulta(codigo?: string, nome?: string, cpfCnpj?: string, status?: 'inativo' | 'ativo' | 'bloqueado' | 'excluido', pagina?: number, tamanhoPagina?: number, options: any = {}): FetchArgs {
            const localVarPath = `/cliente`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (codigo !== undefined) {
                localVarQueryParameter['codigo'] = codigo;
            }

            if (nome !== undefined) {
                localVarQueryParameter['nome'] = nome;
            }

            if (cpfCnpj !== undefined) {
                localVarQueryParameter['cpfCnpj'] = cpfCnpj;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (pagina !== undefined) {
                localVarQueryParameter['pagina'] = pagina;
            }

            if (tamanhoPagina !== undefined) {
                localVarQueryParameter['tamanhoPagina'] = tamanhoPagina;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta as notificações do cliente logado
         * @param {Date} [dataInicio] 
         * @param {string} [filtro] 
         * @param {'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado'} [etapa] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMinhasNotificacoes(dataInicio?: Date, filtro?: string, etapa?: 'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado', pagina?: number, tamanhoPagina?: number, options: any = {}): FetchArgs {
            const localVarPath = `/cliente/minhas/notificacoes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dataInicio !== undefined) {
                localVarQueryParameter['dataInicio'] = (dataInicio as any).toISOString();
            }

            if (filtro !== undefined) {
                localVarQueryParameter['filtro'] = filtro;
            }

            if (etapa !== undefined) {
                localVarQueryParameter['etapa'] = etapa;
            }

            if (pagina !== undefined) {
                localVarQueryParameter['pagina'] = pagina;
            }

            if (tamanhoPagina !== undefined) {
                localVarQueryParameter['tamanhoPagina'] = tamanhoPagina;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta as notificações do cliente indicado
         * @param {number} cliente 
         * @param {Date} [dataInicio] 
         * @param {string} [filtro] 
         * @param {'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado'} [etapa] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaNotificacoes(cliente: number, dataInicio?: Date, filtro?: string, etapa?: 'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado', pagina?: number, tamanhoPagina?: number, options: any = {}): FetchArgs {
            // verify required parameter 'cliente' is not null or undefined
            if (cliente === null || cliente === undefined) {
                throw new RequiredError('cliente','Required parameter cliente was null or undefined when calling consultaNotificacoes.');
            }
            const localVarPath = `/cliente/{cliente}/notificacoes`
                .replace(`{${"cliente"}}`, encodeURIComponent(String(cliente)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dataInicio !== undefined) {
                localVarQueryParameter['dataInicio'] = (dataInicio as any).toISOString();
            }

            if (filtro !== undefined) {
                localVarQueryParameter['filtro'] = filtro;
            }

            if (etapa !== undefined) {
                localVarQueryParameter['etapa'] = etapa;
            }

            if (pagina !== undefined) {
                localVarQueryParameter['pagina'] = pagina;
            }

            if (tamanhoPagina !== undefined) {
                localVarQueryParameter['tamanhoPagina'] = tamanhoPagina;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Detalha as quantidades de animais do registro indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalhaQuantidadesAnimaisCliente(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling detalhaQuantidadesAnimaisCliente.');
            }
            const localVarPath = `/cliente/{id}/detalha-animais`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Detalha o registro indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalhe(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling detalhe.');
            }
            const localVarPath = `/cliente/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reseta a senha do cliente indicado indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetaSenha(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling resetaSenha.');
            }
            const localVarPath = `/cliente/{id}/reseta-senha`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ativa/desativa o acesso do cliente indicado indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleAcesso(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling toggleAcesso.');
            }
            const localVarPath = `/cliente/{id}/toggle-acesso`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClienteApi - functional programming interface
 * @export
 */
export const ClienteApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Consulta os registros cadastrados
         * @param {string} [codigo] 
         * @param {string} [nome] 
         * @param {string} [cpfCnpj] 
         * @param {'inativo' | 'ativo' | 'bloqueado' | 'excluido'} [status] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consulta(codigo?: string, nome?: string, cpfCnpj?: string, status?: 'inativo' | 'ativo' | 'bloqueado' | 'excluido', pagina?: number, tamanhoPagina?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaginaClienteDTO> {
            const localVarFetchArgs = ClienteApiFetchParamCreator(configuration).consulta(codigo, nome, cpfCnpj, status, pagina, tamanhoPagina, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta as notificações do cliente logado
         * @param {Date} [dataInicio] 
         * @param {string} [filtro] 
         * @param {'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado'} [etapa] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMinhasNotificacoes(dataInicio?: Date, filtro?: string, etapa?: 'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado', pagina?: number, tamanhoPagina?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaginaNotificacaoDTO> {
            const localVarFetchArgs = ClienteApiFetchParamCreator(configuration).consultaMinhasNotificacoes(dataInicio, filtro, etapa, pagina, tamanhoPagina, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta as notificações do cliente indicado
         * @param {number} cliente 
         * @param {Date} [dataInicio] 
         * @param {string} [filtro] 
         * @param {'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado'} [etapa] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaNotificacoes(cliente: number, dataInicio?: Date, filtro?: string, etapa?: 'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado', pagina?: number, tamanhoPagina?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaginaNotificacaoDTO> {
            const localVarFetchArgs = ClienteApiFetchParamCreator(configuration).consultaNotificacoes(cliente, dataInicio, filtro, etapa, pagina, tamanhoPagina, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Detalha as quantidades de animais do registro indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalhaQuantidadesAnimaisCliente(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClienteQuantidadeAnimaisDTO> {
            const localVarFetchArgs = ClienteApiFetchParamCreator(configuration).detalhaQuantidadesAnimaisCliente(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Detalha o registro indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalhe(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClienteDTO> {
            const localVarFetchArgs = ClienteApiFetchParamCreator(configuration).detalhe(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Reseta a senha do cliente indicado indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetaSenha(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ClienteApiFetchParamCreator(configuration).resetaSenha(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Ativa/desativa o acesso do cliente indicado indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleAcesso(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ClienteApiFetchParamCreator(configuration).toggleAcesso(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ClienteApi - factory interface
 * @export
 */
export const ClienteApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Consulta os registros cadastrados
         * @param {string} [codigo] 
         * @param {string} [nome] 
         * @param {string} [cpfCnpj] 
         * @param {'inativo' | 'ativo' | 'bloqueado' | 'excluido'} [status] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consulta(codigo?: string, nome?: string, cpfCnpj?: string, status?: 'inativo' | 'ativo' | 'bloqueado' | 'excluido', pagina?: number, tamanhoPagina?: number, options?: any) {
            return ClienteApiFp(configuration).consulta(codigo, nome, cpfCnpj, status, pagina, tamanhoPagina, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta as notificações do cliente logado
         * @param {Date} [dataInicio] 
         * @param {string} [filtro] 
         * @param {'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado'} [etapa] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMinhasNotificacoes(dataInicio?: Date, filtro?: string, etapa?: 'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado', pagina?: number, tamanhoPagina?: number, options?: any) {
            return ClienteApiFp(configuration).consultaMinhasNotificacoes(dataInicio, filtro, etapa, pagina, tamanhoPagina, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta as notificações do cliente indicado
         * @param {number} cliente 
         * @param {Date} [dataInicio] 
         * @param {string} [filtro] 
         * @param {'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado'} [etapa] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaNotificacoes(cliente: number, dataInicio?: Date, filtro?: string, etapa?: 'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado', pagina?: number, tamanhoPagina?: number, options?: any) {
            return ClienteApiFp(configuration).consultaNotificacoes(cliente, dataInicio, filtro, etapa, pagina, tamanhoPagina, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Detalha as quantidades de animais do registro indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalhaQuantidadesAnimaisCliente(id: number, options?: any) {
            return ClienteApiFp(configuration).detalhaQuantidadesAnimaisCliente(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Detalha o registro indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalhe(id: number, options?: any) {
            return ClienteApiFp(configuration).detalhe(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Reseta a senha do cliente indicado indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetaSenha(id: number, options?: any) {
            return ClienteApiFp(configuration).resetaSenha(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Ativa/desativa o acesso do cliente indicado indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleAcesso(id: number, options?: any) {
            return ClienteApiFp(configuration).toggleAcesso(id, options)(fetch, basePath);
        },
    };
};

/**
 * ClienteApi - object-oriented interface
 * @export
 * @class ClienteApi
 * @extends {BaseAPI}
 */
export class ClienteApi extends BaseAPI {
    /**
     * 
     * @summary Consulta os registros cadastrados
     * @param {string} [codigo] 
     * @param {string} [nome] 
     * @param {string} [cpfCnpj] 
     * @param {'inativo' | 'ativo' | 'bloqueado' | 'excluido'} [status] 
     * @param {number} [pagina] 
     * @param {number} [tamanhoPagina] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClienteApi
     */
    public consulta(codigo?: string, nome?: string, cpfCnpj?: string, status?: 'inativo' | 'ativo' | 'bloqueado' | 'excluido', pagina?: number, tamanhoPagina?: number, options?: any) {
        return ClienteApiFp(this.configuration).consulta(codigo, nome, cpfCnpj, status, pagina, tamanhoPagina, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta as notificações do cliente logado
     * @param {Date} [dataInicio] 
     * @param {string} [filtro] 
     * @param {'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado'} [etapa] 
     * @param {number} [pagina] 
     * @param {number} [tamanhoPagina] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClienteApi
     */
    public consultaMinhasNotificacoes(dataInicio?: Date, filtro?: string, etapa?: 'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado', pagina?: number, tamanhoPagina?: number, options?: any) {
        return ClienteApiFp(this.configuration).consultaMinhasNotificacoes(dataInicio, filtro, etapa, pagina, tamanhoPagina, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta as notificações do cliente indicado
     * @param {number} cliente 
     * @param {Date} [dataInicio] 
     * @param {string} [filtro] 
     * @param {'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado'} [etapa] 
     * @param {number} [pagina] 
     * @param {number} [tamanhoPagina] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClienteApi
     */
    public consultaNotificacoes(cliente: number, dataInicio?: Date, filtro?: string, etapa?: 'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado', pagina?: number, tamanhoPagina?: number, options?: any) {
        return ClienteApiFp(this.configuration).consultaNotificacoes(cliente, dataInicio, filtro, etapa, pagina, tamanhoPagina, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Detalha as quantidades de animais do registro indicado
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClienteApi
     */
    public detalhaQuantidadesAnimaisCliente(id: number, options?: any) {
        return ClienteApiFp(this.configuration).detalhaQuantidadesAnimaisCliente(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Detalha o registro indicado
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClienteApi
     */
    public detalhe(id: number, options?: any) {
        return ClienteApiFp(this.configuration).detalhe(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Reseta a senha do cliente indicado indicado
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClienteApi
     */
    public resetaSenha(id: number, options?: any) {
        return ClienteApiFp(this.configuration).resetaSenha(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Ativa/desativa o acesso do cliente indicado indicado
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClienteApi
     */
    public toggleAcesso(id: number, options?: any) {
        return ClienteApiFp(this.configuration).toggleAcesso(id, options)(this.fetch, this.basePath);
    }

}

/**
 * ComentarioApi - fetch parameter creator
 * @export
 */
export const ComentarioApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Consulta comentarios
         * @param {FiltroComentarioDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consulta(body?: FiltroComentarioDTO, options: any = {}): FetchArgs {
            const localVarPath = `/noticia/{noticia}/comentario/comentario`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (("FiltroComentarioDTO" !== "string" as any)) || localVarRequestOptions.headers['Content-Type'] === 'application/json' as any;
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove o comentário indicado
         * @param {number} noticia 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(noticia: number, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'noticia' is not null or undefined
            if (noticia === null || noticia === undefined) {
                throw new RequiredError('noticia','Required parameter noticia was null or undefined when calling remove.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling remove.');
            }
            const localVarPath = `/noticia/{noticia}/comentario/comentario/{id}`
                .replace(`{${"noticia"}}`, encodeURIComponent(String(noticia)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Registra um novo comentario
         * @param {number} noticia 
         * @param {ComentarioDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salva(noticia: number, body?: ComentarioDTO, options: any = {}): FetchArgs {
            // verify required parameter 'noticia' is not null or undefined
            if (noticia === null || noticia === undefined) {
                throw new RequiredError('noticia','Required parameter noticia was null or undefined when calling salva.');
            }
            const localVarPath = `/noticia/{noticia}/comentario`
                .replace(`{${"noticia"}}`, encodeURIComponent(String(noticia)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (("ComentarioDTO" !== "string" as any)) || localVarRequestOptions.headers['Content-Type'] === 'application/json' as any;
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ComentarioApi - functional programming interface
 * @export
 */
export const ComentarioApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Consulta comentarios
         * @param {FiltroComentarioDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consulta(body?: FiltroComentarioDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaginaComentarioDTO> {
            const localVarFetchArgs = ComentarioApiFetchParamCreator(configuration).consulta(body, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Remove o comentário indicado
         * @param {number} noticia 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(noticia: number, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ComentarioApiFetchParamCreator(configuration).remove(noticia, id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Registra um novo comentario
         * @param {number} noticia 
         * @param {ComentarioDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salva(noticia: number, body?: ComentarioDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ComentarioDTO> {
            const localVarFetchArgs = ComentarioApiFetchParamCreator(configuration).salva(noticia, body, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ComentarioApi - factory interface
 * @export
 */
export const ComentarioApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Consulta comentarios
         * @param {FiltroComentarioDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consulta(body?: FiltroComentarioDTO, options?: any) {
            return ComentarioApiFp(configuration).consulta(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Remove o comentário indicado
         * @param {number} noticia 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(noticia: number, id: number, options?: any) {
            return ComentarioApiFp(configuration).remove(noticia, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Registra um novo comentario
         * @param {number} noticia 
         * @param {ComentarioDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salva(noticia: number, body?: ComentarioDTO, options?: any) {
            return ComentarioApiFp(configuration).salva(noticia, body, options)(fetch, basePath);
        },
    };
};

/**
 * ComentarioApi - object-oriented interface
 * @export
 * @class ComentarioApi
 * @extends {BaseAPI}
 */
export class ComentarioApi extends BaseAPI {
    /**
     * 
     * @summary Consulta comentarios
     * @param {FiltroComentarioDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComentarioApi
     */
    public consulta(body?: FiltroComentarioDTO, options?: any) {
        return ComentarioApiFp(this.configuration).consulta(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Remove o comentário indicado
     * @param {number} noticia 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComentarioApi
     */
    public remove(noticia: number, id: number, options?: any) {
        return ComentarioApiFp(this.configuration).remove(noticia, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Registra um novo comentario
     * @param {number} noticia 
     * @param {ComentarioDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComentarioApi
     */
    public salva(noticia: number, body?: ComentarioDTO, options?: any) {
        return ComentarioApiFp(this.configuration).salva(noticia, body, options)(this.fetch, this.basePath);
    }

}

/**
 * DashboardApi - fetch parameter creator
 * @export
 */
export const DashboardApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gera o relatório de acesso registrado no Google Analytics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaRelatorioAcesso(options: any = {}): FetchArgs {
            const localVarPath = `/dashboard/relatorio-acesso`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gera o relatório de acessos a telas registrados no Google Analytics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaRelatorioTelas(options: any = {}): FetchArgs {
            const localVarPath = `/dashboard/relatorio-telas`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gera o relatório de acesso registrado no Google Analytics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaRelatorioAcesso(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RelatorioAcessoGoogleAnalyticsDTO> {
            const localVarFetchArgs = DashboardApiFetchParamCreator(configuration).consultaRelatorioAcesso(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Gera o relatório de acessos a telas registrados no Google Analytics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaRelatorioTelas(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RelatorioTelasGoogleAnalyticsDTO> {
            const localVarFetchArgs = DashboardApiFetchParamCreator(configuration).consultaRelatorioTelas(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Gera o relatório de acesso registrado no Google Analytics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaRelatorioAcesso(options?: any) {
            return DashboardApiFp(configuration).consultaRelatorioAcesso(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Gera o relatório de acessos a telas registrados no Google Analytics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaRelatorioTelas(options?: any) {
            return DashboardApiFp(configuration).consultaRelatorioTelas(options)(fetch, basePath);
        },
    };
};

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
    /**
     * 
     * @summary Gera o relatório de acesso registrado no Google Analytics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public consultaRelatorioAcesso(options?: any) {
        return DashboardApiFp(this.configuration).consultaRelatorioAcesso(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Gera o relatório de acessos a telas registrados no Google Analytics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public consultaRelatorioTelas(options?: any) {
        return DashboardApiFp(this.configuration).consultaRelatorioTelas(options)(this.fetch, this.basePath);
    }

}

/**
 * DenunciaApi - fetch parameter creator
 * @export
 */
export const DenunciaApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Aprova a denúncia indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aprova(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling aprova.');
            }
            const localVarPath = `/noticia/denuncia/{id}/aprova`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta todas as denuncias
         * @param {Date} [data] 
         * @param {number} [responsavel] 
         * @param {'pendente' | 'aprovado' | 'rejeitado'} [status] 
         * @param {'spam' | 'golpe_fraude' | 'informacao_falsa' | 'violencia_linguajar_inadequado' | 'outro'} [motivo] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consulta(data?: Date, responsavel?: number, status?: 'pendente' | 'aprovado' | 'rejeitado', motivo?: 'spam' | 'golpe_fraude' | 'informacao_falsa' | 'violencia_linguajar_inadequado' | 'outro', pagina?: number, tamanhoPagina?: number, options: any = {}): FetchArgs {
            const localVarPath = `/noticia/denuncia`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (data !== undefined) {
                localVarQueryParameter['data'] = (data as any).toISOString();
            }

            if (responsavel !== undefined) {
                localVarQueryParameter['responsavel'] = responsavel;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (motivo !== undefined) {
                localVarQueryParameter['motivo'] = motivo;
            }

            if (pagina !== undefined) {
                localVarQueryParameter['pagina'] = pagina;
            }

            if (tamanhoPagina !== undefined) {
                localVarQueryParameter['tamanhoPagina'] = tamanhoPagina;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Rejeita a denúncia indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejeita(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling rejeita.');
            }
            const localVarPath = `/noticia/denuncia/{id}/rejeita`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Registra uma denuncia para um comentario
         * @param {number} id 
         * @param {DenunciaDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salva(id: number, body?: DenunciaDTO, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling salva.');
            }
            const localVarPath = `/noticia/denuncia/comentario/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (("DenunciaDTO" !== "string" as any)) || localVarRequestOptions.headers['Content-Type'] === 'application/json' as any;
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DenunciaApi - functional programming interface
 * @export
 */
export const DenunciaApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Aprova a denúncia indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aprova(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DenunciaDTO> {
            const localVarFetchArgs = DenunciaApiFetchParamCreator(configuration).aprova(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta todas as denuncias
         * @param {Date} [data] 
         * @param {number} [responsavel] 
         * @param {'pendente' | 'aprovado' | 'rejeitado'} [status] 
         * @param {'spam' | 'golpe_fraude' | 'informacao_falsa' | 'violencia_linguajar_inadequado' | 'outro'} [motivo] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consulta(data?: Date, responsavel?: number, status?: 'pendente' | 'aprovado' | 'rejeitado', motivo?: 'spam' | 'golpe_fraude' | 'informacao_falsa' | 'violencia_linguajar_inadequado' | 'outro', pagina?: number, tamanhoPagina?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaginaDenunciaDTO> {
            const localVarFetchArgs = DenunciaApiFetchParamCreator(configuration).consulta(data, responsavel, status, motivo, pagina, tamanhoPagina, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Rejeita a denúncia indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejeita(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DenunciaDTO> {
            const localVarFetchArgs = DenunciaApiFetchParamCreator(configuration).rejeita(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Registra uma denuncia para um comentario
         * @param {number} id 
         * @param {DenunciaDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salva(id: number, body?: DenunciaDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DenunciaDTO> {
            const localVarFetchArgs = DenunciaApiFetchParamCreator(configuration).salva(id, body, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DenunciaApi - factory interface
 * @export
 */
export const DenunciaApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Aprova a denúncia indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aprova(id: number, options?: any) {
            return DenunciaApiFp(configuration).aprova(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta todas as denuncias
         * @param {Date} [data] 
         * @param {number} [responsavel] 
         * @param {'pendente' | 'aprovado' | 'rejeitado'} [status] 
         * @param {'spam' | 'golpe_fraude' | 'informacao_falsa' | 'violencia_linguajar_inadequado' | 'outro'} [motivo] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consulta(data?: Date, responsavel?: number, status?: 'pendente' | 'aprovado' | 'rejeitado', motivo?: 'spam' | 'golpe_fraude' | 'informacao_falsa' | 'violencia_linguajar_inadequado' | 'outro', pagina?: number, tamanhoPagina?: number, options?: any) {
            return DenunciaApiFp(configuration).consulta(data, responsavel, status, motivo, pagina, tamanhoPagina, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Rejeita a denúncia indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejeita(id: number, options?: any) {
            return DenunciaApiFp(configuration).rejeita(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Registra uma denuncia para um comentario
         * @param {number} id 
         * @param {DenunciaDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salva(id: number, body?: DenunciaDTO, options?: any) {
            return DenunciaApiFp(configuration).salva(id, body, options)(fetch, basePath);
        },
    };
};

/**
 * DenunciaApi - object-oriented interface
 * @export
 * @class DenunciaApi
 * @extends {BaseAPI}
 */
export class DenunciaApi extends BaseAPI {
    /**
     * 
     * @summary Aprova a denúncia indicada
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DenunciaApi
     */
    public aprova(id: number, options?: any) {
        return DenunciaApiFp(this.configuration).aprova(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta todas as denuncias
     * @param {Date} [data] 
     * @param {number} [responsavel] 
     * @param {'pendente' | 'aprovado' | 'rejeitado'} [status] 
     * @param {'spam' | 'golpe_fraude' | 'informacao_falsa' | 'violencia_linguajar_inadequado' | 'outro'} [motivo] 
     * @param {number} [pagina] 
     * @param {number} [tamanhoPagina] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DenunciaApi
     */
    public consulta(data?: Date, responsavel?: number, status?: 'pendente' | 'aprovado' | 'rejeitado', motivo?: 'spam' | 'golpe_fraude' | 'informacao_falsa' | 'violencia_linguajar_inadequado' | 'outro', pagina?: number, tamanhoPagina?: number, options?: any) {
        return DenunciaApiFp(this.configuration).consulta(data, responsavel, status, motivo, pagina, tamanhoPagina, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Rejeita a denúncia indicada
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DenunciaApi
     */
    public rejeita(id: number, options?: any) {
        return DenunciaApiFp(this.configuration).rejeita(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Registra uma denuncia para um comentario
     * @param {number} id 
     * @param {DenunciaDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DenunciaApi
     */
    public salva(id: number, body?: DenunciaDTO, options?: any) {
        return DenunciaApiFp(this.configuration).salva(id, body, options)(this.fetch, this.basePath);
    }

}

/**
 * DesembarqueApi - fetch parameter creator
 * @export
 */
export const DesembarqueApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancela o desembarque indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancela(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling cancela.');
            }
            const localVarPath = `/desembarque/{id}/cancela`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta os registros cadastrados
         * @param {string} [codigo] 
         * @param {number} [proprietario] 
         * @param {number} [lote] 
         * @param {Date} [data] 
         * @param {'pendente' | 'iniciado' | 'encerrado' | 'cancelado'} [status] 
         * @param {string} [carreta] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consulta(codigo?: string, proprietario?: number, lote?: number, data?: Date, status?: 'pendente' | 'iniciado' | 'encerrado' | 'cancelado', carreta?: string, pagina?: number, tamanhoPagina?: number, options: any = {}): FetchArgs {
            const localVarPath = `/desembarque`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (codigo !== undefined) {
                localVarQueryParameter['codigo'] = codigo;
            }

            if (proprietario !== undefined) {
                localVarQueryParameter['proprietario'] = proprietario;
            }

            if (lote !== undefined) {
                localVarQueryParameter['lote'] = lote;
            }

            if (data !== undefined) {
                localVarQueryParameter['data'] = (data as any).toISOString();
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (carreta !== undefined) {
                localVarQueryParameter['carreta'] = carreta;
            }

            if (pagina !== undefined) {
                localVarQueryParameter['pagina'] = pagina;
            }

            if (tamanhoPagina !== undefined) {
                localVarQueryParameter['tamanhoPagina'] = tamanhoPagina;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta os registros cadastrados para o usuário logado
         * @param {string} [codigo] 
         * @param {number} [lote] 
         * @param {Date} [data] 
         * @param {'pendente' | 'iniciado' | 'encerrado' | 'cancelado'} [status] 
         * @param {string} [carreta] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMeusDesembarques(codigo?: string, lote?: number, data?: Date, status?: 'pendente' | 'iniciado' | 'encerrado' | 'cancelado', carreta?: string, pagina?: number, tamanhoPagina?: number, options: any = {}): FetchArgs {
            const localVarPath = `/desembarque/meus`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (codigo !== undefined) {
                localVarQueryParameter['codigo'] = codigo;
            }

            if (lote !== undefined) {
                localVarQueryParameter['lote'] = lote;
            }

            if (data !== undefined) {
                localVarQueryParameter['data'] = (data as any).toISOString();
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (carreta !== undefined) {
                localVarQueryParameter['carreta'] = carreta;
            }

            if (pagina !== undefined) {
                localVarQueryParameter['pagina'] = pagina;
            }

            if (tamanhoPagina !== undefined) {
                localVarQueryParameter['tamanhoPagina'] = tamanhoPagina;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Detalha o desembarque indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalha(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling detalha.');
            }
            const localVarPath = `/desembarque/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Encerra o desembarque indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        encerra(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling encerra.');
            }
            const localVarPath = `/desembarque/{id}/encerra`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Salva os documentos do desembarque
         * @param {DocumentosDesembarqueDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salvaDocumentos(body?: DocumentosDesembarqueDTO, options: any = {}): FetchArgs {
            const localVarPath = `/desembarque/documento`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (("DocumentosDesembarqueDTO" !== "string" as any)) || localVarRequestOptions.headers['Content-Type'] === 'application/json' as any;
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DesembarqueApi - functional programming interface
 * @export
 */
export const DesembarqueApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancela o desembarque indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancela(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DesembarqueApiFetchParamCreator(configuration).cancela(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta os registros cadastrados
         * @param {string} [codigo] 
         * @param {number} [proprietario] 
         * @param {number} [lote] 
         * @param {Date} [data] 
         * @param {'pendente' | 'iniciado' | 'encerrado' | 'cancelado'} [status] 
         * @param {string} [carreta] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consulta(codigo?: string, proprietario?: number, lote?: number, data?: Date, status?: 'pendente' | 'iniciado' | 'encerrado' | 'cancelado', carreta?: string, pagina?: number, tamanhoPagina?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaginaDesembarqueDTO> {
            const localVarFetchArgs = DesembarqueApiFetchParamCreator(configuration).consulta(codigo, proprietario, lote, data, status, carreta, pagina, tamanhoPagina, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta os registros cadastrados para o usuário logado
         * @param {string} [codigo] 
         * @param {number} [lote] 
         * @param {Date} [data] 
         * @param {'pendente' | 'iniciado' | 'encerrado' | 'cancelado'} [status] 
         * @param {string} [carreta] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMeusDesembarques(codigo?: string, lote?: number, data?: Date, status?: 'pendente' | 'iniciado' | 'encerrado' | 'cancelado', carreta?: string, pagina?: number, tamanhoPagina?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaginaDesembarqueDTO> {
            const localVarFetchArgs = DesembarqueApiFetchParamCreator(configuration).consultaMeusDesembarques(codigo, lote, data, status, carreta, pagina, tamanhoPagina, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Detalha o desembarque indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalha(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DesembarqueDTO> {
            const localVarFetchArgs = DesembarqueApiFetchParamCreator(configuration).detalha(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Encerra o desembarque indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        encerra(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DesembarqueDTO> {
            const localVarFetchArgs = DesembarqueApiFetchParamCreator(configuration).encerra(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Salva os documentos do desembarque
         * @param {DocumentosDesembarqueDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salvaDocumentos(body?: DocumentosDesembarqueDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DesembarqueDTO> {
            const localVarFetchArgs = DesembarqueApiFetchParamCreator(configuration).salvaDocumentos(body, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DesembarqueApi - factory interface
 * @export
 */
export const DesembarqueApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Cancela o desembarque indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancela(id: number, options?: any) {
            return DesembarqueApiFp(configuration).cancela(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta os registros cadastrados
         * @param {string} [codigo] 
         * @param {number} [proprietario] 
         * @param {number} [lote] 
         * @param {Date} [data] 
         * @param {'pendente' | 'iniciado' | 'encerrado' | 'cancelado'} [status] 
         * @param {string} [carreta] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consulta(codigo?: string, proprietario?: number, lote?: number, data?: Date, status?: 'pendente' | 'iniciado' | 'encerrado' | 'cancelado', carreta?: string, pagina?: number, tamanhoPagina?: number, options?: any) {
            return DesembarqueApiFp(configuration).consulta(codigo, proprietario, lote, data, status, carreta, pagina, tamanhoPagina, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta os registros cadastrados para o usuário logado
         * @param {string} [codigo] 
         * @param {number} [lote] 
         * @param {Date} [data] 
         * @param {'pendente' | 'iniciado' | 'encerrado' | 'cancelado'} [status] 
         * @param {string} [carreta] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMeusDesembarques(codigo?: string, lote?: number, data?: Date, status?: 'pendente' | 'iniciado' | 'encerrado' | 'cancelado', carreta?: string, pagina?: number, tamanhoPagina?: number, options?: any) {
            return DesembarqueApiFp(configuration).consultaMeusDesembarques(codigo, lote, data, status, carreta, pagina, tamanhoPagina, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Detalha o desembarque indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalha(id: number, options?: any) {
            return DesembarqueApiFp(configuration).detalha(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Encerra o desembarque indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        encerra(id: number, options?: any) {
            return DesembarqueApiFp(configuration).encerra(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Salva os documentos do desembarque
         * @param {DocumentosDesembarqueDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salvaDocumentos(body?: DocumentosDesembarqueDTO, options?: any) {
            return DesembarqueApiFp(configuration).salvaDocumentos(body, options)(fetch, basePath);
        },
    };
};

/**
 * DesembarqueApi - object-oriented interface
 * @export
 * @class DesembarqueApi
 * @extends {BaseAPI}
 */
export class DesembarqueApi extends BaseAPI {
    /**
     * 
     * @summary Cancela o desembarque indicado
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DesembarqueApi
     */
    public cancela(id: number, options?: any) {
        return DesembarqueApiFp(this.configuration).cancela(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta os registros cadastrados
     * @param {string} [codigo] 
     * @param {number} [proprietario] 
     * @param {number} [lote] 
     * @param {Date} [data] 
     * @param {'pendente' | 'iniciado' | 'encerrado' | 'cancelado'} [status] 
     * @param {string} [carreta] 
     * @param {number} [pagina] 
     * @param {number} [tamanhoPagina] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DesembarqueApi
     */
    public consulta(codigo?: string, proprietario?: number, lote?: number, data?: Date, status?: 'pendente' | 'iniciado' | 'encerrado' | 'cancelado', carreta?: string, pagina?: number, tamanhoPagina?: number, options?: any) {
        return DesembarqueApiFp(this.configuration).consulta(codigo, proprietario, lote, data, status, carreta, pagina, tamanhoPagina, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta os registros cadastrados para o usuário logado
     * @param {string} [codigo] 
     * @param {number} [lote] 
     * @param {Date} [data] 
     * @param {'pendente' | 'iniciado' | 'encerrado' | 'cancelado'} [status] 
     * @param {string} [carreta] 
     * @param {number} [pagina] 
     * @param {number} [tamanhoPagina] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DesembarqueApi
     */
    public consultaMeusDesembarques(codigo?: string, lote?: number, data?: Date, status?: 'pendente' | 'iniciado' | 'encerrado' | 'cancelado', carreta?: string, pagina?: number, tamanhoPagina?: number, options?: any) {
        return DesembarqueApiFp(this.configuration).consultaMeusDesembarques(codigo, lote, data, status, carreta, pagina, tamanhoPagina, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Detalha o desembarque indicado
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DesembarqueApi
     */
    public detalha(id: number, options?: any) {
        return DesembarqueApiFp(this.configuration).detalha(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Encerra o desembarque indicado
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DesembarqueApi
     */
    public encerra(id: number, options?: any) {
        return DesembarqueApiFp(this.configuration).encerra(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Salva os documentos do desembarque
     * @param {DocumentosDesembarqueDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DesembarqueApi
     */
    public salvaDocumentos(body?: DocumentosDesembarqueDTO, options?: any) {
        return DesembarqueApiFp(this.configuration).salvaDocumentos(body, options)(this.fetch, this.basePath);
    }

}

/**
 * InfoApi - fetch parameter creator
 * @export
 */
export const InfoApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Recupera a versão da API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersao(options: any = {}): FetchArgs {
            const localVarPath = `/info/versao`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InfoApi - functional programming interface
 * @export
 */
export const InfoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Recupera a versão da API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersao(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VersaoDTO> {
            const localVarFetchArgs = InfoApiFetchParamCreator(configuration).getVersao(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * InfoApi - factory interface
 * @export
 */
export const InfoApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Recupera a versão da API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersao(options?: any) {
            return InfoApiFp(configuration).getVersao(options)(fetch, basePath);
        },
    };
};

/**
 * InfoApi - object-oriented interface
 * @export
 * @class InfoApi
 * @extends {BaseAPI}
 */
export class InfoApi extends BaseAPI {
    /**
     * 
     * @summary Recupera a versão da API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InfoApi
     */
    public getVersao(options?: any) {
        return InfoApiFp(this.configuration).getVersao(options)(this.fetch, this.basePath);
    }

}

/**
 * LoteApi - fetch parameter creator
 * @export
 */
export const LoteApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Consulta os registros cadastrados
         * @param {string} [codigo] 
         * @param {Date} [data] 
         * @param {'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado'} [etapa] 
         * @param {'pendente' | 'ativo' | 'inativo'} [status] 
         * @param {number} [proprietario] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consulta(codigo?: string, data?: Date, etapa?: 'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado', status?: 'pendente' | 'ativo' | 'inativo', proprietario?: number, pagina?: number, tamanhoPagina?: number, options: any = {}): FetchArgs {
            const localVarPath = `/lote`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (codigo !== undefined) {
                localVarQueryParameter['codigo'] = codigo;
            }

            if (data !== undefined) {
                localVarQueryParameter['data'] = (data as any).toISOString();
            }

            if (etapa !== undefined) {
                localVarQueryParameter['etapa'] = etapa;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (proprietario !== undefined) {
                localVarQueryParameter['proprietario'] = proprietario;
            }

            if (pagina !== undefined) {
                localVarQueryParameter['pagina'] = pagina;
            }

            if (tamanhoPagina !== undefined) {
                localVarQueryParameter['tamanhoPagina'] = tamanhoPagina;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta o laudo de entrada do lote
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaLaudoEntrada(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling consultaLaudoEntrada.');
            }
            const localVarPath = `/lote/{id}/laudo-entrada`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta o laudo de entrada do lote indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMeuLaudoEntrada(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling consultaMeuLaudoEntrada.');
            }
            const localVarPath = `/lote/meu/{id}/laudo-entrada`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta os registros cadastrados do cliente logado
         * @param {string} [codigo] 
         * @param {Date} [data] 
         * @param {'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado'} [etapa] 
         * @param {'pendente' | 'ativo' | 'inativo'} [status] 
         * @param {string} [codigo2] 
         * @param {Date} [data2] 
         * @param {'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado'} [etapa2] 
         * @param {'pendente' | 'ativo' | 'inativo'} [status2] 
         * @param {number} [proprietario] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMeusLotes(codigo?: string, data?: Date, etapa?: 'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado', status?: 'pendente' | 'ativo' | 'inativo', codigo2?: string, data2?: Date, etapa2?: 'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado', status2?: 'pendente' | 'ativo' | 'inativo', proprietario?: number, pagina?: number, tamanhoPagina?: number, options: any = {}): FetchArgs {
            const localVarPath = `/lote/meus`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (codigo !== undefined) {
                localVarQueryParameter['codigo'] = codigo;
            }

            if (data !== undefined) {
                localVarQueryParameter['data'] = (data as any).toISOString();
            }

            if (etapa !== undefined) {
                localVarQueryParameter['etapa'] = etapa;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (codigo2 !== undefined) {
                localVarQueryParameter['codigo'] = codigo2;
            }

            if (data2 !== undefined) {
                localVarQueryParameter['data'] = (data2 as any).toISOString();
            }

            if (etapa2 !== undefined) {
                localVarQueryParameter['etapa'] = etapa2;
            }

            if (status2 !== undefined) {
                localVarQueryParameter['status'] = status2;
            }

            if (proprietario !== undefined) {
                localVarQueryParameter['proprietario'] = proprietario;
            }

            if (pagina !== undefined) {
                localVarQueryParameter['pagina'] = pagina;
            }

            if (tamanhoPagina !== undefined) {
                localVarQueryParameter['tamanhoPagina'] = tamanhoPagina;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta as mídias de desembarque do lote
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMidiasDesembarque(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling consultaMidiasDesembarque.');
            }
            const localVarPath = `/lote/{id}/midias`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta as mídias de desembarque do lote do usuário
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMinhasMidiasDesembarque(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling consultaMinhasMidiasDesembarque.');
            }
            const localVarPath = `/lote/meu/{id}/midias`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta as ocorrencias do laudo de entrada do lote 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaOcorrenciasLaudoEntrada(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling consultaOcorrenciasLaudoEntrada.');
            }
            const localVarPath = `/lote/{id}/laudo-entrada/ocorrencias`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta os registros de relatorio boschdo cliente logado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaRelatorioBosch(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling consultaRelatorioBosch.');
            }
            const localVarPath = `/lote/{id}/relatorio/bosch`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Detalha um lote indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalhe(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling detalhe.');
            }
            const localVarPath = `/lote/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Detalha um lote indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalheMeuLote(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling detalheMeuLote.');
            }
            const localVarPath = `/lote/meu/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Exporta o detalhamento do processamento de entrada do lote
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportaProcessamentoEntradaPdf(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling exportaProcessamentoEntradaPdf.');
            }
            const localVarPath = `/lote/meu/{id}/processamento-entrada/exporta/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Exporta o detalhamento do processamento de entrada do lote
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportaProcessamentoEntradaPlanilha(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling exportaProcessamentoEntradaPlanilha.');
            }
            const localVarPath = `/lote/meu/{id}/processamento-entrada/exporta/planilha`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gera relatório em base64 do lote
         * @param {number} id 
         * @param {LaudoEntradaDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizaLaudoEntrada(id: number, body?: LaudoEntradaDTO, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling finalizaLaudoEntrada.');
            }
            const localVarPath = `/lote/{id}/laudo-entrada/finaliza`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (("LaudoEntradaDTO" !== "string" as any)) || localVarRequestOptions.headers['Content-Type'] === 'application/json' as any;
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Salva as mídias de desembarque do lote
         * @param {number} id 
         * @param {MidiasLoteDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salvaMidiasDesembarque(id: number, body?: MidiasLoteDTO, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling salvaMidiasDesembarque.');
            }
            const localVarPath = `/lote/{id}/midias`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (("MidiasLoteDTO" !== "string" as any)) || localVarRequestOptions.headers['Content-Type'] === 'application/json' as any;
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoteApi - functional programming interface
 * @export
 */
export const LoteApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Consulta os registros cadastrados
         * @param {string} [codigo] 
         * @param {Date} [data] 
         * @param {'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado'} [etapa] 
         * @param {'pendente' | 'ativo' | 'inativo'} [status] 
         * @param {number} [proprietario] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consulta(codigo?: string, data?: Date, etapa?: 'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado', status?: 'pendente' | 'ativo' | 'inativo', proprietario?: number, pagina?: number, tamanhoPagina?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaginaLoteDTO> {
            const localVarFetchArgs = LoteApiFetchParamCreator(configuration).consulta(codigo, data, etapa, status, proprietario, pagina, tamanhoPagina, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta o laudo de entrada do lote
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaLaudoEntrada(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LaudoEntradaDTO> {
            const localVarFetchArgs = LoteApiFetchParamCreator(configuration).consultaLaudoEntrada(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta o laudo de entrada do lote indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMeuLaudoEntrada(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LaudoEntradaDTO> {
            const localVarFetchArgs = LoteApiFetchParamCreator(configuration).consultaMeuLaudoEntrada(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta os registros cadastrados do cliente logado
         * @param {string} [codigo] 
         * @param {Date} [data] 
         * @param {'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado'} [etapa] 
         * @param {'pendente' | 'ativo' | 'inativo'} [status] 
         * @param {string} [codigo2] 
         * @param {Date} [data2] 
         * @param {'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado'} [etapa2] 
         * @param {'pendente' | 'ativo' | 'inativo'} [status2] 
         * @param {number} [proprietario] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMeusLotes(codigo?: string, data?: Date, etapa?: 'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado', status?: 'pendente' | 'ativo' | 'inativo', codigo2?: string, data2?: Date, etapa2?: 'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado', status2?: 'pendente' | 'ativo' | 'inativo', proprietario?: number, pagina?: number, tamanhoPagina?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaginaLoteDTO> {
            const localVarFetchArgs = LoteApiFetchParamCreator(configuration).consultaMeusLotes(codigo, data, etapa, status, codigo2, data2, etapa2, status2, proprietario, pagina, tamanhoPagina, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta as mídias de desembarque do lote
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMidiasDesembarque(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MidiasLoteDTO> {
            const localVarFetchArgs = LoteApiFetchParamCreator(configuration).consultaMidiasDesembarque(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta as mídias de desembarque do lote do usuário
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMinhasMidiasDesembarque(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MidiasLoteDTO> {
            const localVarFetchArgs = LoteApiFetchParamCreator(configuration).consultaMinhasMidiasDesembarque(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta as ocorrencias do laudo de entrada do lote 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaOcorrenciasLaudoEntrada(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<OcorrenciaLaudoEntradaDTO>> {
            const localVarFetchArgs = LoteApiFetchParamCreator(configuration).consultaOcorrenciasLaudoEntrada(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta os registros de relatorio boschdo cliente logado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaRelatorioBosch(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RelatorioBoschDTO> {
            const localVarFetchArgs = LoteApiFetchParamCreator(configuration).consultaRelatorioBosch(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Detalha um lote indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalhe(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LoteDTO> {
            const localVarFetchArgs = LoteApiFetchParamCreator(configuration).detalhe(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Detalha um lote indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalheMeuLote(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LoteDTO> {
            const localVarFetchArgs = LoteApiFetchParamCreator(configuration).detalheMeuLote(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Exporta o detalhamento do processamento de entrada do lote
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportaProcessamentoEntradaPdf(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ArquivoProcessamentoEntradaDTO> {
            const localVarFetchArgs = LoteApiFetchParamCreator(configuration).exportaProcessamentoEntradaPdf(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Exporta o detalhamento do processamento de entrada do lote
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportaProcessamentoEntradaPlanilha(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ArquivoProcessamentoEntradaDTO> {
            const localVarFetchArgs = LoteApiFetchParamCreator(configuration).exportaProcessamentoEntradaPlanilha(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Gera relatório em base64 do lote
         * @param {number} id 
         * @param {LaudoEntradaDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizaLaudoEntrada(id: number, body?: LaudoEntradaDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocumentoDigitalDTO> {
            const localVarFetchArgs = LoteApiFetchParamCreator(configuration).finalizaLaudoEntrada(id, body, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Salva as mídias de desembarque do lote
         * @param {number} id 
         * @param {MidiasLoteDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salvaMidiasDesembarque(id: number, body?: MidiasLoteDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MidiasLoteDTO> {
            const localVarFetchArgs = LoteApiFetchParamCreator(configuration).salvaMidiasDesembarque(id, body, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * LoteApi - factory interface
 * @export
 */
export const LoteApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Consulta os registros cadastrados
         * @param {string} [codigo] 
         * @param {Date} [data] 
         * @param {'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado'} [etapa] 
         * @param {'pendente' | 'ativo' | 'inativo'} [status] 
         * @param {number} [proprietario] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consulta(codigo?: string, data?: Date, etapa?: 'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado', status?: 'pendente' | 'ativo' | 'inativo', proprietario?: number, pagina?: number, tamanhoPagina?: number, options?: any) {
            return LoteApiFp(configuration).consulta(codigo, data, etapa, status, proprietario, pagina, tamanhoPagina, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta o laudo de entrada do lote
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaLaudoEntrada(id: number, options?: any) {
            return LoteApiFp(configuration).consultaLaudoEntrada(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta o laudo de entrada do lote indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMeuLaudoEntrada(id: number, options?: any) {
            return LoteApiFp(configuration).consultaMeuLaudoEntrada(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta os registros cadastrados do cliente logado
         * @param {string} [codigo] 
         * @param {Date} [data] 
         * @param {'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado'} [etapa] 
         * @param {'pendente' | 'ativo' | 'inativo'} [status] 
         * @param {string} [codigo2] 
         * @param {Date} [data2] 
         * @param {'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado'} [etapa2] 
         * @param {'pendente' | 'ativo' | 'inativo'} [status2] 
         * @param {number} [proprietario] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMeusLotes(codigo?: string, data?: Date, etapa?: 'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado', status?: 'pendente' | 'ativo' | 'inativo', codigo2?: string, data2?: Date, etapa2?: 'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado', status2?: 'pendente' | 'ativo' | 'inativo', proprietario?: number, pagina?: number, tamanhoPagina?: number, options?: any) {
            return LoteApiFp(configuration).consultaMeusLotes(codigo, data, etapa, status, codigo2, data2, etapa2, status2, proprietario, pagina, tamanhoPagina, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta as mídias de desembarque do lote
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMidiasDesembarque(id: number, options?: any) {
            return LoteApiFp(configuration).consultaMidiasDesembarque(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta as mídias de desembarque do lote do usuário
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMinhasMidiasDesembarque(id: number, options?: any) {
            return LoteApiFp(configuration).consultaMinhasMidiasDesembarque(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta as ocorrencias do laudo de entrada do lote 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaOcorrenciasLaudoEntrada(id: number, options?: any) {
            return LoteApiFp(configuration).consultaOcorrenciasLaudoEntrada(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta os registros de relatorio boschdo cliente logado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaRelatorioBosch(id: number, options?: any) {
            return LoteApiFp(configuration).consultaRelatorioBosch(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Detalha um lote indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalhe(id: number, options?: any) {
            return LoteApiFp(configuration).detalhe(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Detalha um lote indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalheMeuLote(id: number, options?: any) {
            return LoteApiFp(configuration).detalheMeuLote(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Exporta o detalhamento do processamento de entrada do lote
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportaProcessamentoEntradaPdf(id: number, options?: any) {
            return LoteApiFp(configuration).exportaProcessamentoEntradaPdf(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Exporta o detalhamento do processamento de entrada do lote
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportaProcessamentoEntradaPlanilha(id: number, options?: any) {
            return LoteApiFp(configuration).exportaProcessamentoEntradaPlanilha(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Gera relatório em base64 do lote
         * @param {number} id 
         * @param {LaudoEntradaDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizaLaudoEntrada(id: number, body?: LaudoEntradaDTO, options?: any) {
            return LoteApiFp(configuration).finalizaLaudoEntrada(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Salva as mídias de desembarque do lote
         * @param {number} id 
         * @param {MidiasLoteDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salvaMidiasDesembarque(id: number, body?: MidiasLoteDTO, options?: any) {
            return LoteApiFp(configuration).salvaMidiasDesembarque(id, body, options)(fetch, basePath);
        },
    };
};

/**
 * LoteApi - object-oriented interface
 * @export
 * @class LoteApi
 * @extends {BaseAPI}
 */
export class LoteApi extends BaseAPI {
    /**
     * 
     * @summary Consulta os registros cadastrados
     * @param {string} [codigo] 
     * @param {Date} [data] 
     * @param {'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado'} [etapa] 
     * @param {'pendente' | 'ativo' | 'inativo'} [status] 
     * @param {number} [proprietario] 
     * @param {number} [pagina] 
     * @param {number} [tamanhoPagina] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoteApi
     */
    public consulta(codigo?: string, data?: Date, etapa?: 'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado', status?: 'pendente' | 'ativo' | 'inativo', proprietario?: number, pagina?: number, tamanhoPagina?: number, options?: any) {
        return LoteApiFp(this.configuration).consulta(codigo, data, etapa, status, proprietario, pagina, tamanhoPagina, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta o laudo de entrada do lote
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoteApi
     */
    public consultaLaudoEntrada(id: number, options?: any) {
        return LoteApiFp(this.configuration).consultaLaudoEntrada(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta o laudo de entrada do lote indicado
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoteApi
     */
    public consultaMeuLaudoEntrada(id: number, options?: any) {
        return LoteApiFp(this.configuration).consultaMeuLaudoEntrada(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta os registros cadastrados do cliente logado
     * @param {string} [codigo] 
     * @param {Date} [data] 
     * @param {'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado'} [etapa] 
     * @param {'pendente' | 'ativo' | 'inativo'} [status] 
     * @param {string} [codigo2] 
     * @param {Date} [data2] 
     * @param {'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado'} [etapa2] 
     * @param {'pendente' | 'ativo' | 'inativo'} [status2] 
     * @param {number} [proprietario] 
     * @param {number} [pagina] 
     * @param {number} [tamanhoPagina] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoteApi
     */
    public consultaMeusLotes(codigo?: string, data?: Date, etapa?: 'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado', status?: 'pendente' | 'ativo' | 'inativo', codigo2?: string, data2?: Date, etapa2?: 'desembarque' | 'processamento_entrada' | 'processamento_saida' | 'manejo' | 'encerrado', status2?: 'pendente' | 'ativo' | 'inativo', proprietario?: number, pagina?: number, tamanhoPagina?: number, options?: any) {
        return LoteApiFp(this.configuration).consultaMeusLotes(codigo, data, etapa, status, codigo2, data2, etapa2, status2, proprietario, pagina, tamanhoPagina, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta as mídias de desembarque do lote
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoteApi
     */
    public consultaMidiasDesembarque(id: number, options?: any) {
        return LoteApiFp(this.configuration).consultaMidiasDesembarque(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta as mídias de desembarque do lote do usuário
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoteApi
     */
    public consultaMinhasMidiasDesembarque(id: number, options?: any) {
        return LoteApiFp(this.configuration).consultaMinhasMidiasDesembarque(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta as ocorrencias do laudo de entrada do lote 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoteApi
     */
    public consultaOcorrenciasLaudoEntrada(id: number, options?: any) {
        return LoteApiFp(this.configuration).consultaOcorrenciasLaudoEntrada(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta os registros de relatorio boschdo cliente logado
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoteApi
     */
    public consultaRelatorioBosch(id: number, options?: any) {
        return LoteApiFp(this.configuration).consultaRelatorioBosch(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Detalha um lote indicado
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoteApi
     */
    public detalhe(id: number, options?: any) {
        return LoteApiFp(this.configuration).detalhe(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Detalha um lote indicado
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoteApi
     */
    public detalheMeuLote(id: number, options?: any) {
        return LoteApiFp(this.configuration).detalheMeuLote(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Exporta o detalhamento do processamento de entrada do lote
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoteApi
     */
    public exportaProcessamentoEntradaPdf(id: number, options?: any) {
        return LoteApiFp(this.configuration).exportaProcessamentoEntradaPdf(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Exporta o detalhamento do processamento de entrada do lote
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoteApi
     */
    public exportaProcessamentoEntradaPlanilha(id: number, options?: any) {
        return LoteApiFp(this.configuration).exportaProcessamentoEntradaPlanilha(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Gera relatório em base64 do lote
     * @param {number} id 
     * @param {LaudoEntradaDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoteApi
     */
    public finalizaLaudoEntrada(id: number, body?: LaudoEntradaDTO, options?: any) {
        return LoteApiFp(this.configuration).finalizaLaudoEntrada(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Salva as mídias de desembarque do lote
     * @param {number} id 
     * @param {MidiasLoteDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoteApi
     */
    public salvaMidiasDesembarque(id: number, body?: MidiasLoteDTO, options?: any) {
        return LoteApiFp(this.configuration).salvaMidiasDesembarque(id, body, options)(this.fetch, this.basePath);
    }

}

/**
 * NoticiaApi - fetch parameter creator
 * @export
 */
export const NoticiaApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Consulta os registros cadastrados
         * @param {Date} [data] 
         * @param {number} [responsavel] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consulta(data?: Date, responsavel?: number, pagina?: number, tamanhoPagina?: number, options: any = {}): FetchArgs {
            const localVarPath = `/noticia`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (data !== undefined) {
                localVarQueryParameter['data'] = (data as any).toISOString();
            }

            if (responsavel !== undefined) {
                localVarQueryParameter['responsavel'] = responsavel;
            }

            if (pagina !== undefined) {
                localVarQueryParameter['pagina'] = pagina;
            }

            if (tamanhoPagina !== undefined) {
                localVarQueryParameter['tamanhoPagina'] = tamanhoPagina;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Detalha os dados do registro indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalha(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling detalha.');
            }
            const localVarPath = `/noticia/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove o registro indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling remove.');
            }
            const localVarPath = `/noticia/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Salva o registro indicado
         * @param {NoticiaDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salva(body?: NoticiaDTO, options: any = {}): FetchArgs {
            const localVarPath = `/noticia`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (("NoticiaDTO" !== "string" as any)) || localVarRequestOptions.headers['Content-Type'] === 'application/json' as any;
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NoticiaApi - functional programming interface
 * @export
 */
export const NoticiaApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Consulta os registros cadastrados
         * @param {Date} [data] 
         * @param {number} [responsavel] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consulta(data?: Date, responsavel?: number, pagina?: number, tamanhoPagina?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaginaNoticiaDTO> {
            const localVarFetchArgs = NoticiaApiFetchParamCreator(configuration).consulta(data, responsavel, pagina, tamanhoPagina, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Detalha os dados do registro indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalha(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<NoticiaDTO> {
            const localVarFetchArgs = NoticiaApiFetchParamCreator(configuration).detalha(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Remove o registro indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = NoticiaApiFetchParamCreator(configuration).remove(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Salva o registro indicado
         * @param {NoticiaDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salva(body?: NoticiaDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<NoticiaDTO> {
            const localVarFetchArgs = NoticiaApiFetchParamCreator(configuration).salva(body, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * NoticiaApi - factory interface
 * @export
 */
export const NoticiaApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Consulta os registros cadastrados
         * @param {Date} [data] 
         * @param {number} [responsavel] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consulta(data?: Date, responsavel?: number, pagina?: number, tamanhoPagina?: number, options?: any) {
            return NoticiaApiFp(configuration).consulta(data, responsavel, pagina, tamanhoPagina, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Detalha os dados do registro indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalha(id: number, options?: any) {
            return NoticiaApiFp(configuration).detalha(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Remove o registro indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: number, options?: any) {
            return NoticiaApiFp(configuration).remove(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Salva o registro indicado
         * @param {NoticiaDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salva(body?: NoticiaDTO, options?: any) {
            return NoticiaApiFp(configuration).salva(body, options)(fetch, basePath);
        },
    };
};

/**
 * NoticiaApi - object-oriented interface
 * @export
 * @class NoticiaApi
 * @extends {BaseAPI}
 */
export class NoticiaApi extends BaseAPI {
    /**
     * 
     * @summary Consulta os registros cadastrados
     * @param {Date} [data] 
     * @param {number} [responsavel] 
     * @param {number} [pagina] 
     * @param {number} [tamanhoPagina] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticiaApi
     */
    public consulta(data?: Date, responsavel?: number, pagina?: number, tamanhoPagina?: number, options?: any) {
        return NoticiaApiFp(this.configuration).consulta(data, responsavel, pagina, tamanhoPagina, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Detalha os dados do registro indicado
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticiaApi
     */
    public detalha(id: number, options?: any) {
        return NoticiaApiFp(this.configuration).detalha(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Remove o registro indicado
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticiaApi
     */
    public remove(id: number, options?: any) {
        return NoticiaApiFp(this.configuration).remove(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Salva o registro indicado
     * @param {NoticiaDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticiaApi
     */
    public salva(body?: NoticiaDTO, options?: any) {
        return NoticiaApiFp(this.configuration).salva(body, options)(this.fetch, this.basePath);
    }

}

/**
 * PendenciaApi - fetch parameter creator
 * @export
 */
export const PendenciaApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Aprova contagem da pendência indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aprovaContagem(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling aprovaContagem.');
            }
            const localVarPath = `/pendencia/{id}/contagem/aprovada`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Aprova contagem da pendência indicada
         * @param {number} id 
         * @param {AlteracaoClienteDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aprovaPeloCliente(id: number, body?: AlteracaoClienteDTO, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling aprovaPeloCliente.');
            }
            const localVarPath = `/pendencia/{id}/aprovada-pelo-cliente`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (("AlteracaoClienteDTO" !== "string" as any)) || localVarRequestOptions.headers['Content-Type'] === 'application/json' as any;
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancela a pendência indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancela(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling cancela.');
            }
            const localVarPath = `/pendencia/{id}/cancela`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Rejeita contagem da pendência indicada
         * @param {number} id 
         * @param {CancelamentoPendenciaDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelaConclusao(id: number, body?: CancelamentoPendenciaDTO, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling cancelaConclusao.');
            }
            const localVarPath = `/pendencia/{id}/cancela-conclusao`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (("CancelamentoPendenciaDTO" !== "string" as any)) || localVarRequestOptions.headers['Content-Type'] === 'application/json' as any;
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Conclui a pendência indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concluiPendencia(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling concluiPendencia.');
            }
            const localVarPath = `/pendencia/{id}/conclui`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Confirma a negociação indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmaMinhaNegociacao(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling confirmaMinhaNegociacao.');
            }
            const localVarPath = `/pendencia/{id}/minha/negociacao/confirma`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta os registros do historico de pendencia
         * @param {number} pendencia 
         * @param {string} [codigo] 
         * @param {'contagem' | 'negociacao'} [tipo] 
         * @param {'pendente' | 'aprovado' | 'recusa_enviada' | 'recusa_confirmada' | 'recusa_visualizada' | 'enviado' | 'visualizado' | 'confirmado' | 'concluido' | 'cancelado'} [status] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaHistoricoPendencia(pendencia: number, codigo?: string, tipo?: 'contagem' | 'negociacao', status?: 'pendente' | 'aprovado' | 'recusa_enviada' | 'recusa_confirmada' | 'recusa_visualizada' | 'enviado' | 'visualizado' | 'confirmado' | 'concluido' | 'cancelado', pagina?: number, tamanhoPagina?: number, options: any = {}): FetchArgs {
            // verify required parameter 'pendencia' is not null or undefined
            if (pendencia === null || pendencia === undefined) {
                throw new RequiredError('pendencia','Required parameter pendencia was null or undefined when calling consultaHistoricoPendencia.');
            }
            const localVarPath = `/pendencia/{pendencia}/historico`
                .replace(`{${"pendencia"}}`, encodeURIComponent(String(pendencia)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (codigo !== undefined) {
                localVarQueryParameter['codigo'] = codigo;
            }

            if (tipo !== undefined) {
                localVarQueryParameter['tipo'] = tipo;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (pagina !== undefined) {
                localVarQueryParameter['pagina'] = pagina;
            }

            if (tamanhoPagina !== undefined) {
                localVarQueryParameter['tamanhoPagina'] = tamanhoPagina;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta os históricos de pendencia
         * @param {string} [codigo] 
         * @param {'contagem' | 'negociacao'} [tipo] 
         * @param {'pendente' | 'aprovado' | 'recusa_enviada' | 'recusa_confirmada' | 'recusa_visualizada' | 'enviado' | 'visualizado' | 'confirmado' | 'concluido' | 'cancelado'} [status] 
         * @param {number} [cliente] 
         * @param {'ate_59_dias' | 'de_60_a_74_dias' | 'de_75_a_94_dias' | 'de_95_dias_em_diante'} [faixa] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaHistoricoPendencias(codigo?: string, tipo?: 'contagem' | 'negociacao', status?: 'pendente' | 'aprovado' | 'recusa_enviada' | 'recusa_confirmada' | 'recusa_visualizada' | 'enviado' | 'visualizado' | 'confirmado' | 'concluido' | 'cancelado', cliente?: number, faixa?: 'ate_59_dias' | 'de_60_a_74_dias' | 'de_75_a_94_dias' | 'de_95_dias_em_diante', pagina?: number, tamanhoPagina?: number, options: any = {}): FetchArgs {
            const localVarPath = `/pendencia/historico`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (codigo !== undefined) {
                localVarQueryParameter['codigo'] = codigo;
            }

            if (tipo !== undefined) {
                localVarQueryParameter['tipo'] = tipo;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (cliente !== undefined) {
                localVarQueryParameter['cliente'] = cliente;
            }

            if (faixa !== undefined) {
                localVarQueryParameter['faixa'] = faixa;
            }

            if (pagina !== undefined) {
                localVarQueryParameter['pagina'] = pagina;
            }

            if (tamanhoPagina !== undefined) {
                localVarQueryParameter['tamanhoPagina'] = tamanhoPagina;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta os registros de pendencia de termos do cleinte
         * @param {string} [codigo] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMinhasPendenciasTermos(codigo?: string, pagina?: number, tamanhoPagina?: number, options: any = {}): FetchArgs {
            const localVarPath = `/pendencia/minhas`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (codigo !== undefined) {
                localVarQueryParameter['codigo'] = codigo;
            }

            if (pagina !== undefined) {
                localVarQueryParameter['pagina'] = pagina;
            }

            if (tamanhoPagina !== undefined) {
                localVarQueryParameter['tamanhoPagina'] = tamanhoPagina;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta os registros de pendencia
         * @param {string} [codigo] 
         * @param {'contagem' | 'negociacao'} [tipo] 
         * @param {'pendente' | 'aprovado' | 'recusa_enviada' | 'recusa_confirmada' | 'recusa_visualizada' | 'enviado' | 'visualizado' | 'confirmado' | 'concluido' | 'cancelado'} [status] 
         * @param {number} [cliente] 
         * @param {'ate_59_dias' | 'de_60_a_74_dias' | 'de_75_a_94_dias' | 'de_95_dias_em_diante'} [faixa] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaPendencias(codigo?: string, tipo?: 'contagem' | 'negociacao', status?: 'pendente' | 'aprovado' | 'recusa_enviada' | 'recusa_confirmada' | 'recusa_visualizada' | 'enviado' | 'visualizado' | 'confirmado' | 'concluido' | 'cancelado', cliente?: number, faixa?: 'ate_59_dias' | 'de_60_a_74_dias' | 'de_75_a_94_dias' | 'de_95_dias_em_diante', pagina?: number, tamanhoPagina?: number, options: any = {}): FetchArgs {
            const localVarPath = `/pendencia`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (codigo !== undefined) {
                localVarQueryParameter['codigo'] = codigo;
            }

            if (tipo !== undefined) {
                localVarQueryParameter['tipo'] = tipo;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (cliente !== undefined) {
                localVarQueryParameter['cliente'] = cliente;
            }

            if (faixa !== undefined) {
                localVarQueryParameter['faixa'] = faixa;
            }

            if (pagina !== undefined) {
                localVarQueryParameter['pagina'] = pagina;
            }

            if (tamanhoPagina !== undefined) {
                localVarQueryParameter['tamanhoPagina'] = tamanhoPagina;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta a quantidade de animais por faixas para o cliente indicado
         * @param {number} cliente 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaQuantidadesFaixa(cliente: number, options: any = {}): FetchArgs {
            // verify required parameter 'cliente' is not null or undefined
            if (cliente === null || cliente === undefined) {
                throw new RequiredError('cliente','Required parameter cliente was null or undefined when calling consultaQuantidadesFaixa.');
            }
            const localVarPath = `/pendencia/cliente/{cliente}/faixa/quantidades`
                .replace(`{${"cliente"}}`, encodeURIComponent(String(cliente)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta a quantidade de animais por dias de cocho e sexo
         * @param {number} cliente 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaQuantidadesGadosCliente(cliente: number, options: any = {}): FetchArgs {
            // verify required parameter 'cliente' is not null or undefined
            if (cliente === null || cliente === undefined) {
                throw new RequiredError('cliente','Required parameter cliente was null or undefined when calling consultaQuantidadesGadosCliente.');
            }
            const localVarPath = `/pendencia/cliente/{cliente}/animais/quantidades`
                .replace(`{${"cliente"}}`, encodeURIComponent(String(cliente)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta os registros de pendencia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaResumoGadosCliente(options: any = {}): FetchArgs {
            const localVarPath = `/pendencia/quantidades`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Detalha a pendencia indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalha(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling detalha.');
            }
            const localVarPath = `/pendencia/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Envia negociação da pendência indicada
         * @param {number} id 
         * @param {EnvioNegociacaoDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enviaNegociacao(id: number, body?: EnvioNegociacaoDTO, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling enviaNegociacao.');
            }
            const localVarPath = `/pendencia/{id}/negociacao/envia`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (("EnvioNegociacaoDTO" !== "string" as any)) || localVarRequestOptions.headers['Content-Type'] === 'application/json' as any;
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Exporta o histórico de pendencias do cliente
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportaHistoricoCliente(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling exportaHistoricoCliente.');
            }
            const localVarPath = `/pendencia/cliente/{id}/exporta`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Exporta o histórico da pendencia indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportaHistoricoPendencia(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling exportaHistoricoPendencia.');
            }
            const localVarPath = `/pendencia/{id}/historico/exporta`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gerar pendencia para o cliente indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gera(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling gera.');
            }
            const localVarPath = `/pendencia/cliente/{id}/gera`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Rejeita contagem da pendência indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejeitaContagem(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling rejeitaContagem.');
            }
            const localVarPath = `/pendencia/{id}/contagem/rejeita`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Rejeita a contagem indicada
         * @param {number} id 
         * @param {RejeicaoContagemDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejeitaMinhaContagem(id: number, body?: RejeicaoContagemDTO, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling rejeitaMinhaContagem.');
            }
            const localVarPath = `/pendencia/{id}/minha/contagem/rejeita`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (("RejeicaoContagemDTO" !== "string" as any)) || localVarRequestOptions.headers['Content-Type'] === 'application/json' as any;
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Rejeita a negociação indicada
         * @param {number} id 
         * @param {RejeicaoNegociacaoDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejeitaMinhaNegociacao(id: number, body?: RejeicaoNegociacaoDTO, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling rejeitaMinhaNegociacao.');
            }
            const localVarPath = `/pendencia/{id}/minha/negociacao/rejeita`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (("RejeicaoNegociacaoDTO" !== "string" as any)) || localVarRequestOptions.headers['Content-Type'] === 'application/json' as any;
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Rejeita negociação da pendência indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejeitaNegociacao(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling rejeitaNegociacao.');
            }
            const localVarPath = `/pendencia/{id}/negociacao/rejeita`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Rejeita contagem da pendência indicada
         * @param {number} id 
         * @param {AlteracaoClienteDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejeitaPeloCliente(id: number, body?: AlteracaoClienteDTO, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling rejeitaPeloCliente.');
            }
            const localVarPath = `/pendencia/{id}/rejeita-pelo-cliente`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (("AlteracaoClienteDTO" !== "string" as any)) || localVarRequestOptions.headers['Content-Type'] === 'application/json' as any;
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Visualiza a pendência indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visualizaMinhaPendencia(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling visualizaMinhaPendencia.');
            }
            const localVarPath = `/pendencia/{id}/minha/visualiza`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PendenciaApi - functional programming interface
 * @export
 */
export const PendenciaApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Aprova contagem da pendência indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aprovaContagem(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PendenciaDTO> {
            const localVarFetchArgs = PendenciaApiFetchParamCreator(configuration).aprovaContagem(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Aprova contagem da pendência indicada
         * @param {number} id 
         * @param {AlteracaoClienteDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aprovaPeloCliente(id: number, body?: AlteracaoClienteDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PendenciaDTO> {
            const localVarFetchArgs = PendenciaApiFetchParamCreator(configuration).aprovaPeloCliente(id, body, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Cancela a pendência indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancela(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PendenciaDTO> {
            const localVarFetchArgs = PendenciaApiFetchParamCreator(configuration).cancela(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Rejeita contagem da pendência indicada
         * @param {number} id 
         * @param {CancelamentoPendenciaDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelaConclusao(id: number, body?: CancelamentoPendenciaDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PendenciaDTO> {
            const localVarFetchArgs = PendenciaApiFetchParamCreator(configuration).cancelaConclusao(id, body, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Conclui a pendência indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concluiPendencia(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PendenciaDTO> {
            const localVarFetchArgs = PendenciaApiFetchParamCreator(configuration).concluiPendencia(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Confirma a negociação indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmaMinhaNegociacao(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PendenciaDTO> {
            const localVarFetchArgs = PendenciaApiFetchParamCreator(configuration).confirmaMinhaNegociacao(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta os registros do historico de pendencia
         * @param {number} pendencia 
         * @param {string} [codigo] 
         * @param {'contagem' | 'negociacao'} [tipo] 
         * @param {'pendente' | 'aprovado' | 'recusa_enviada' | 'recusa_confirmada' | 'recusa_visualizada' | 'enviado' | 'visualizado' | 'confirmado' | 'concluido' | 'cancelado'} [status] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaHistoricoPendencia(pendencia: number, codigo?: string, tipo?: 'contagem' | 'negociacao', status?: 'pendente' | 'aprovado' | 'recusa_enviada' | 'recusa_confirmada' | 'recusa_visualizada' | 'enviado' | 'visualizado' | 'confirmado' | 'concluido' | 'cancelado', pagina?: number, tamanhoPagina?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaginaPendenciaDTO> {
            const localVarFetchArgs = PendenciaApiFetchParamCreator(configuration).consultaHistoricoPendencia(pendencia, codigo, tipo, status, pagina, tamanhoPagina, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta os históricos de pendencia
         * @param {string} [codigo] 
         * @param {'contagem' | 'negociacao'} [tipo] 
         * @param {'pendente' | 'aprovado' | 'recusa_enviada' | 'recusa_confirmada' | 'recusa_visualizada' | 'enviado' | 'visualizado' | 'confirmado' | 'concluido' | 'cancelado'} [status] 
         * @param {number} [cliente] 
         * @param {'ate_59_dias' | 'de_60_a_74_dias' | 'de_75_a_94_dias' | 'de_95_dias_em_diante'} [faixa] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaHistoricoPendencias(codigo?: string, tipo?: 'contagem' | 'negociacao', status?: 'pendente' | 'aprovado' | 'recusa_enviada' | 'recusa_confirmada' | 'recusa_visualizada' | 'enviado' | 'visualizado' | 'confirmado' | 'concluido' | 'cancelado', cliente?: number, faixa?: 'ate_59_dias' | 'de_60_a_74_dias' | 'de_75_a_94_dias' | 'de_95_dias_em_diante', pagina?: number, tamanhoPagina?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaginaPendenciaDTO> {
            const localVarFetchArgs = PendenciaApiFetchParamCreator(configuration).consultaHistoricoPendencias(codigo, tipo, status, cliente, faixa, pagina, tamanhoPagina, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta os registros de pendencia de termos do cleinte
         * @param {string} [codigo] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMinhasPendenciasTermos(codigo?: string, pagina?: number, tamanhoPagina?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaginaPendenciaDTO> {
            const localVarFetchArgs = PendenciaApiFetchParamCreator(configuration).consultaMinhasPendenciasTermos(codigo, pagina, tamanhoPagina, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta os registros de pendencia
         * @param {string} [codigo] 
         * @param {'contagem' | 'negociacao'} [tipo] 
         * @param {'pendente' | 'aprovado' | 'recusa_enviada' | 'recusa_confirmada' | 'recusa_visualizada' | 'enviado' | 'visualizado' | 'confirmado' | 'concluido' | 'cancelado'} [status] 
         * @param {number} [cliente] 
         * @param {'ate_59_dias' | 'de_60_a_74_dias' | 'de_75_a_94_dias' | 'de_95_dias_em_diante'} [faixa] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaPendencias(codigo?: string, tipo?: 'contagem' | 'negociacao', status?: 'pendente' | 'aprovado' | 'recusa_enviada' | 'recusa_confirmada' | 'recusa_visualizada' | 'enviado' | 'visualizado' | 'confirmado' | 'concluido' | 'cancelado', cliente?: number, faixa?: 'ate_59_dias' | 'de_60_a_74_dias' | 'de_75_a_94_dias' | 'de_95_dias_em_diante', pagina?: number, tamanhoPagina?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaginaTotalPendenciaDTO> {
            const localVarFetchArgs = PendenciaApiFetchParamCreator(configuration).consultaPendencias(codigo, tipo, status, cliente, faixa, pagina, tamanhoPagina, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta a quantidade de animais por faixas para o cliente indicado
         * @param {number} cliente 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaQuantidadesFaixa(cliente: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<QuantidadeAnimaisClienteFaixaDTO> {
            const localVarFetchArgs = PendenciaApiFetchParamCreator(configuration).consultaQuantidadesFaixa(cliente, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta a quantidade de animais por dias de cocho e sexo
         * @param {number} cliente 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaQuantidadesGadosCliente(cliente: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<QuantidadesAnimaisPorDiasDeCochoDTO> {
            const localVarFetchArgs = PendenciaApiFetchParamCreator(configuration).consultaQuantidadesGadosCliente(cliente, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta os registros de pendencia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaResumoGadosCliente(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<QuantidadePendenciasStatusTipoDTO> {
            const localVarFetchArgs = PendenciaApiFetchParamCreator(configuration).consultaResumoGadosCliente(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Detalha a pendencia indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalha(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PendenciaDTO> {
            const localVarFetchArgs = PendenciaApiFetchParamCreator(configuration).detalha(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Envia negociação da pendência indicada
         * @param {number} id 
         * @param {EnvioNegociacaoDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enviaNegociacao(id: number, body?: EnvioNegociacaoDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PendenciaDTO> {
            const localVarFetchArgs = PendenciaApiFetchParamCreator(configuration).enviaNegociacao(id, body, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Exporta o histórico de pendencias do cliente
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportaHistoricoCliente(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ArquivoDTO> {
            const localVarFetchArgs = PendenciaApiFetchParamCreator(configuration).exportaHistoricoCliente(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Exporta o histórico da pendencia indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportaHistoricoPendencia(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ArquivoDTO> {
            const localVarFetchArgs = PendenciaApiFetchParamCreator(configuration).exportaHistoricoPendencia(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Gerar pendencia para o cliente indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gera(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PendenciaApiFetchParamCreator(configuration).gera(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Rejeita contagem da pendência indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejeitaContagem(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PendenciaDTO> {
            const localVarFetchArgs = PendenciaApiFetchParamCreator(configuration).rejeitaContagem(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Rejeita a contagem indicada
         * @param {number} id 
         * @param {RejeicaoContagemDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejeitaMinhaContagem(id: number, body?: RejeicaoContagemDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PendenciaDTO> {
            const localVarFetchArgs = PendenciaApiFetchParamCreator(configuration).rejeitaMinhaContagem(id, body, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Rejeita a negociação indicada
         * @param {number} id 
         * @param {RejeicaoNegociacaoDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejeitaMinhaNegociacao(id: number, body?: RejeicaoNegociacaoDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PendenciaDTO> {
            const localVarFetchArgs = PendenciaApiFetchParamCreator(configuration).rejeitaMinhaNegociacao(id, body, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Rejeita negociação da pendência indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejeitaNegociacao(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PendenciaDTO> {
            const localVarFetchArgs = PendenciaApiFetchParamCreator(configuration).rejeitaNegociacao(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Rejeita contagem da pendência indicada
         * @param {number} id 
         * @param {AlteracaoClienteDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejeitaPeloCliente(id: number, body?: AlteracaoClienteDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PendenciaDTO> {
            const localVarFetchArgs = PendenciaApiFetchParamCreator(configuration).rejeitaPeloCliente(id, body, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Visualiza a pendência indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visualizaMinhaPendencia(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PendenciaDTO> {
            const localVarFetchArgs = PendenciaApiFetchParamCreator(configuration).visualizaMinhaPendencia(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PendenciaApi - factory interface
 * @export
 */
export const PendenciaApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Aprova contagem da pendência indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aprovaContagem(id: number, options?: any) {
            return PendenciaApiFp(configuration).aprovaContagem(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Aprova contagem da pendência indicada
         * @param {number} id 
         * @param {AlteracaoClienteDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aprovaPeloCliente(id: number, body?: AlteracaoClienteDTO, options?: any) {
            return PendenciaApiFp(configuration).aprovaPeloCliente(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Cancela a pendência indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancela(id: number, options?: any) {
            return PendenciaApiFp(configuration).cancela(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Rejeita contagem da pendência indicada
         * @param {number} id 
         * @param {CancelamentoPendenciaDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelaConclusao(id: number, body?: CancelamentoPendenciaDTO, options?: any) {
            return PendenciaApiFp(configuration).cancelaConclusao(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Conclui a pendência indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concluiPendencia(id: number, options?: any) {
            return PendenciaApiFp(configuration).concluiPendencia(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Confirma a negociação indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmaMinhaNegociacao(id: number, options?: any) {
            return PendenciaApiFp(configuration).confirmaMinhaNegociacao(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta os registros do historico de pendencia
         * @param {number} pendencia 
         * @param {string} [codigo] 
         * @param {'contagem' | 'negociacao'} [tipo] 
         * @param {'pendente' | 'aprovado' | 'recusa_enviada' | 'recusa_confirmada' | 'recusa_visualizada' | 'enviado' | 'visualizado' | 'confirmado' | 'concluido' | 'cancelado'} [status] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaHistoricoPendencia(pendencia: number, codigo?: string, tipo?: 'contagem' | 'negociacao', status?: 'pendente' | 'aprovado' | 'recusa_enviada' | 'recusa_confirmada' | 'recusa_visualizada' | 'enviado' | 'visualizado' | 'confirmado' | 'concluido' | 'cancelado', pagina?: number, tamanhoPagina?: number, options?: any) {
            return PendenciaApiFp(configuration).consultaHistoricoPendencia(pendencia, codigo, tipo, status, pagina, tamanhoPagina, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta os históricos de pendencia
         * @param {string} [codigo] 
         * @param {'contagem' | 'negociacao'} [tipo] 
         * @param {'pendente' | 'aprovado' | 'recusa_enviada' | 'recusa_confirmada' | 'recusa_visualizada' | 'enviado' | 'visualizado' | 'confirmado' | 'concluido' | 'cancelado'} [status] 
         * @param {number} [cliente] 
         * @param {'ate_59_dias' | 'de_60_a_74_dias' | 'de_75_a_94_dias' | 'de_95_dias_em_diante'} [faixa] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaHistoricoPendencias(codigo?: string, tipo?: 'contagem' | 'negociacao', status?: 'pendente' | 'aprovado' | 'recusa_enviada' | 'recusa_confirmada' | 'recusa_visualizada' | 'enviado' | 'visualizado' | 'confirmado' | 'concluido' | 'cancelado', cliente?: number, faixa?: 'ate_59_dias' | 'de_60_a_74_dias' | 'de_75_a_94_dias' | 'de_95_dias_em_diante', pagina?: number, tamanhoPagina?: number, options?: any) {
            return PendenciaApiFp(configuration).consultaHistoricoPendencias(codigo, tipo, status, cliente, faixa, pagina, tamanhoPagina, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta os registros de pendencia de termos do cleinte
         * @param {string} [codigo] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMinhasPendenciasTermos(codigo?: string, pagina?: number, tamanhoPagina?: number, options?: any) {
            return PendenciaApiFp(configuration).consultaMinhasPendenciasTermos(codigo, pagina, tamanhoPagina, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta os registros de pendencia
         * @param {string} [codigo] 
         * @param {'contagem' | 'negociacao'} [tipo] 
         * @param {'pendente' | 'aprovado' | 'recusa_enviada' | 'recusa_confirmada' | 'recusa_visualizada' | 'enviado' | 'visualizado' | 'confirmado' | 'concluido' | 'cancelado'} [status] 
         * @param {number} [cliente] 
         * @param {'ate_59_dias' | 'de_60_a_74_dias' | 'de_75_a_94_dias' | 'de_95_dias_em_diante'} [faixa] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaPendencias(codigo?: string, tipo?: 'contagem' | 'negociacao', status?: 'pendente' | 'aprovado' | 'recusa_enviada' | 'recusa_confirmada' | 'recusa_visualizada' | 'enviado' | 'visualizado' | 'confirmado' | 'concluido' | 'cancelado', cliente?: number, faixa?: 'ate_59_dias' | 'de_60_a_74_dias' | 'de_75_a_94_dias' | 'de_95_dias_em_diante', pagina?: number, tamanhoPagina?: number, options?: any) {
            return PendenciaApiFp(configuration).consultaPendencias(codigo, tipo, status, cliente, faixa, pagina, tamanhoPagina, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta a quantidade de animais por faixas para o cliente indicado
         * @param {number} cliente 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaQuantidadesFaixa(cliente: number, options?: any) {
            return PendenciaApiFp(configuration).consultaQuantidadesFaixa(cliente, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta a quantidade de animais por dias de cocho e sexo
         * @param {number} cliente 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaQuantidadesGadosCliente(cliente: number, options?: any) {
            return PendenciaApiFp(configuration).consultaQuantidadesGadosCliente(cliente, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta os registros de pendencia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaResumoGadosCliente(options?: any) {
            return PendenciaApiFp(configuration).consultaResumoGadosCliente(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Detalha a pendencia indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalha(id: number, options?: any) {
            return PendenciaApiFp(configuration).detalha(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Envia negociação da pendência indicada
         * @param {number} id 
         * @param {EnvioNegociacaoDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enviaNegociacao(id: number, body?: EnvioNegociacaoDTO, options?: any) {
            return PendenciaApiFp(configuration).enviaNegociacao(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Exporta o histórico de pendencias do cliente
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportaHistoricoCliente(id: number, options?: any) {
            return PendenciaApiFp(configuration).exportaHistoricoCliente(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Exporta o histórico da pendencia indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportaHistoricoPendencia(id: number, options?: any) {
            return PendenciaApiFp(configuration).exportaHistoricoPendencia(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Gerar pendencia para o cliente indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gera(id: number, options?: any) {
            return PendenciaApiFp(configuration).gera(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Rejeita contagem da pendência indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejeitaContagem(id: number, options?: any) {
            return PendenciaApiFp(configuration).rejeitaContagem(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Rejeita a contagem indicada
         * @param {number} id 
         * @param {RejeicaoContagemDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejeitaMinhaContagem(id: number, body?: RejeicaoContagemDTO, options?: any) {
            return PendenciaApiFp(configuration).rejeitaMinhaContagem(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Rejeita a negociação indicada
         * @param {number} id 
         * @param {RejeicaoNegociacaoDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejeitaMinhaNegociacao(id: number, body?: RejeicaoNegociacaoDTO, options?: any) {
            return PendenciaApiFp(configuration).rejeitaMinhaNegociacao(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Rejeita negociação da pendência indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejeitaNegociacao(id: number, options?: any) {
            return PendenciaApiFp(configuration).rejeitaNegociacao(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Rejeita contagem da pendência indicada
         * @param {number} id 
         * @param {AlteracaoClienteDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejeitaPeloCliente(id: number, body?: AlteracaoClienteDTO, options?: any) {
            return PendenciaApiFp(configuration).rejeitaPeloCliente(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Visualiza a pendência indicada
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visualizaMinhaPendencia(id: number, options?: any) {
            return PendenciaApiFp(configuration).visualizaMinhaPendencia(id, options)(fetch, basePath);
        },
    };
};

/**
 * PendenciaApi - object-oriented interface
 * @export
 * @class PendenciaApi
 * @extends {BaseAPI}
 */
export class PendenciaApi extends BaseAPI {
    /**
     * 
     * @summary Aprova contagem da pendência indicada
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PendenciaApi
     */
    public aprovaContagem(id: number, options?: any) {
        return PendenciaApiFp(this.configuration).aprovaContagem(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Aprova contagem da pendência indicada
     * @param {number} id 
     * @param {AlteracaoClienteDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PendenciaApi
     */
    public aprovaPeloCliente(id: number, body?: AlteracaoClienteDTO, options?: any) {
        return PendenciaApiFp(this.configuration).aprovaPeloCliente(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Cancela a pendência indicada
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PendenciaApi
     */
    public cancela(id: number, options?: any) {
        return PendenciaApiFp(this.configuration).cancela(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Rejeita contagem da pendência indicada
     * @param {number} id 
     * @param {CancelamentoPendenciaDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PendenciaApi
     */
    public cancelaConclusao(id: number, body?: CancelamentoPendenciaDTO, options?: any) {
        return PendenciaApiFp(this.configuration).cancelaConclusao(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Conclui a pendência indicada
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PendenciaApi
     */
    public concluiPendencia(id: number, options?: any) {
        return PendenciaApiFp(this.configuration).concluiPendencia(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Confirma a negociação indicada
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PendenciaApi
     */
    public confirmaMinhaNegociacao(id: number, options?: any) {
        return PendenciaApiFp(this.configuration).confirmaMinhaNegociacao(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta os registros do historico de pendencia
     * @param {number} pendencia 
     * @param {string} [codigo] 
     * @param {'contagem' | 'negociacao'} [tipo] 
     * @param {'pendente' | 'aprovado' | 'recusa_enviada' | 'recusa_confirmada' | 'recusa_visualizada' | 'enviado' | 'visualizado' | 'confirmado' | 'concluido' | 'cancelado'} [status] 
     * @param {number} [pagina] 
     * @param {number} [tamanhoPagina] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PendenciaApi
     */
    public consultaHistoricoPendencia(pendencia: number, codigo?: string, tipo?: 'contagem' | 'negociacao', status?: 'pendente' | 'aprovado' | 'recusa_enviada' | 'recusa_confirmada' | 'recusa_visualizada' | 'enviado' | 'visualizado' | 'confirmado' | 'concluido' | 'cancelado', pagina?: number, tamanhoPagina?: number, options?: any) {
        return PendenciaApiFp(this.configuration).consultaHistoricoPendencia(pendencia, codigo, tipo, status, pagina, tamanhoPagina, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta os históricos de pendencia
     * @param {string} [codigo] 
     * @param {'contagem' | 'negociacao'} [tipo] 
     * @param {'pendente' | 'aprovado' | 'recusa_enviada' | 'recusa_confirmada' | 'recusa_visualizada' | 'enviado' | 'visualizado' | 'confirmado' | 'concluido' | 'cancelado'} [status] 
     * @param {number} [cliente] 
     * @param {'ate_59_dias' | 'de_60_a_74_dias' | 'de_75_a_94_dias' | 'de_95_dias_em_diante'} [faixa] 
     * @param {number} [pagina] 
     * @param {number} [tamanhoPagina] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PendenciaApi
     */
    public consultaHistoricoPendencias(codigo?: string, tipo?: 'contagem' | 'negociacao', status?: 'pendente' | 'aprovado' | 'recusa_enviada' | 'recusa_confirmada' | 'recusa_visualizada' | 'enviado' | 'visualizado' | 'confirmado' | 'concluido' | 'cancelado', cliente?: number, faixa?: 'ate_59_dias' | 'de_60_a_74_dias' | 'de_75_a_94_dias' | 'de_95_dias_em_diante', pagina?: number, tamanhoPagina?: number, options?: any) {
        return PendenciaApiFp(this.configuration).consultaHistoricoPendencias(codigo, tipo, status, cliente, faixa, pagina, tamanhoPagina, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta os registros de pendencia de termos do cleinte
     * @param {string} [codigo] 
     * @param {number} [pagina] 
     * @param {number} [tamanhoPagina] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PendenciaApi
     */
    public consultaMinhasPendenciasTermos(codigo?: string, pagina?: number, tamanhoPagina?: number, options?: any) {
        return PendenciaApiFp(this.configuration).consultaMinhasPendenciasTermos(codigo, pagina, tamanhoPagina, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta os registros de pendencia
     * @param {string} [codigo] 
     * @param {'contagem' | 'negociacao'} [tipo] 
     * @param {'pendente' | 'aprovado' | 'recusa_enviada' | 'recusa_confirmada' | 'recusa_visualizada' | 'enviado' | 'visualizado' | 'confirmado' | 'concluido' | 'cancelado'} [status] 
     * @param {number} [cliente] 
     * @param {'ate_59_dias' | 'de_60_a_74_dias' | 'de_75_a_94_dias' | 'de_95_dias_em_diante'} [faixa] 
     * @param {number} [pagina] 
     * @param {number} [tamanhoPagina] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PendenciaApi
     */
    public consultaPendencias(codigo?: string, tipo?: 'contagem' | 'negociacao', status?: 'pendente' | 'aprovado' | 'recusa_enviada' | 'recusa_confirmada' | 'recusa_visualizada' | 'enviado' | 'visualizado' | 'confirmado' | 'concluido' | 'cancelado', cliente?: number, faixa?: 'ate_59_dias' | 'de_60_a_74_dias' | 'de_75_a_94_dias' | 'de_95_dias_em_diante', pagina?: number, tamanhoPagina?: number, options?: any) {
        return PendenciaApiFp(this.configuration).consultaPendencias(codigo, tipo, status, cliente, faixa, pagina, tamanhoPagina, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta a quantidade de animais por faixas para o cliente indicado
     * @param {number} cliente 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PendenciaApi
     */
    public consultaQuantidadesFaixa(cliente: number, options?: any) {
        return PendenciaApiFp(this.configuration).consultaQuantidadesFaixa(cliente, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta a quantidade de animais por dias de cocho e sexo
     * @param {number} cliente 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PendenciaApi
     */
    public consultaQuantidadesGadosCliente(cliente: number, options?: any) {
        return PendenciaApiFp(this.configuration).consultaQuantidadesGadosCliente(cliente, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta os registros de pendencia
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PendenciaApi
     */
    public consultaResumoGadosCliente(options?: any) {
        return PendenciaApiFp(this.configuration).consultaResumoGadosCliente(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Detalha a pendencia indicada
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PendenciaApi
     */
    public detalha(id: number, options?: any) {
        return PendenciaApiFp(this.configuration).detalha(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Envia negociação da pendência indicada
     * @param {number} id 
     * @param {EnvioNegociacaoDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PendenciaApi
     */
    public enviaNegociacao(id: number, body?: EnvioNegociacaoDTO, options?: any) {
        return PendenciaApiFp(this.configuration).enviaNegociacao(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Exporta o histórico de pendencias do cliente
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PendenciaApi
     */
    public exportaHistoricoCliente(id: number, options?: any) {
        return PendenciaApiFp(this.configuration).exportaHistoricoCliente(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Exporta o histórico da pendencia indicada
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PendenciaApi
     */
    public exportaHistoricoPendencia(id: number, options?: any) {
        return PendenciaApiFp(this.configuration).exportaHistoricoPendencia(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Gerar pendencia para o cliente indicado
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PendenciaApi
     */
    public gera(id: number, options?: any) {
        return PendenciaApiFp(this.configuration).gera(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Rejeita contagem da pendência indicada
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PendenciaApi
     */
    public rejeitaContagem(id: number, options?: any) {
        return PendenciaApiFp(this.configuration).rejeitaContagem(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Rejeita a contagem indicada
     * @param {number} id 
     * @param {RejeicaoContagemDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PendenciaApi
     */
    public rejeitaMinhaContagem(id: number, body?: RejeicaoContagemDTO, options?: any) {
        return PendenciaApiFp(this.configuration).rejeitaMinhaContagem(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Rejeita a negociação indicada
     * @param {number} id 
     * @param {RejeicaoNegociacaoDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PendenciaApi
     */
    public rejeitaMinhaNegociacao(id: number, body?: RejeicaoNegociacaoDTO, options?: any) {
        return PendenciaApiFp(this.configuration).rejeitaMinhaNegociacao(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Rejeita negociação da pendência indicada
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PendenciaApi
     */
    public rejeitaNegociacao(id: number, options?: any) {
        return PendenciaApiFp(this.configuration).rejeitaNegociacao(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Rejeita contagem da pendência indicada
     * @param {number} id 
     * @param {AlteracaoClienteDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PendenciaApi
     */
    public rejeitaPeloCliente(id: number, body?: AlteracaoClienteDTO, options?: any) {
        return PendenciaApiFp(this.configuration).rejeitaPeloCliente(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Visualiza a pendência indicada
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PendenciaApi
     */
    public visualizaMinhaPendencia(id: number, options?: any) {
        return PendenciaApiFp(this.configuration).visualizaMinhaPendencia(id, options)(this.fetch, this.basePath);
    }

}

/**
 * ProcessamentoSaidaApi - fetch parameter creator
 * @export
 */
export const ProcessamentoSaidaApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Consulta os processamentos de saída
         * @param {string} [codigo] 
         * @param {Date} [dataHora] 
         * @param {number} [proprietario] 
         * @param {'pendente' | 'iniciado' | 'aguardando_financeiro' | 'encerrado'} [status] 
         * @param {number} [lote] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consulta(codigo?: string, dataHora?: Date, proprietario?: number, status?: 'pendente' | 'iniciado' | 'aguardando_financeiro' | 'encerrado', lote?: number, pagina?: number, tamanhoPagina?: number, options: any = {}): FetchArgs {
            const localVarPath = `/processamento-saida`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (codigo !== undefined) {
                localVarQueryParameter['codigo'] = codigo;
            }

            if (dataHora !== undefined) {
                localVarQueryParameter['dataHora'] = (dataHora as any).toISOString();
            }

            if (proprietario !== undefined) {
                localVarQueryParameter['proprietario'] = proprietario;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (lote !== undefined) {
                localVarQueryParameter['lote'] = lote;
            }

            if (pagina !== undefined) {
                localVarQueryParameter['pagina'] = pagina;
            }

            if (tamanhoPagina !== undefined) {
                localVarQueryParameter['tamanhoPagina'] = tamanhoPagina;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta os registros cadastrados por lote
         * @param {string} [codigo] 
         * @param {Date} [dataHora] 
         * @param {number} [proprietario] 
         * @param {'pendente' | 'iniciado' | 'aguardando_financeiro' | 'encerrado'} [status] 
         * @param {number} [lote] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaByLote(codigo?: string, dataHora?: Date, proprietario?: number, status?: 'pendente' | 'iniciado' | 'aguardando_financeiro' | 'encerrado', lote?: number, pagina?: number, tamanhoPagina?: number, options: any = {}): FetchArgs {
            const localVarPath = `/processamento-saida/lote`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (codigo !== undefined) {
                localVarQueryParameter['codigo'] = codigo;
            }

            if (dataHora !== undefined) {
                localVarQueryParameter['dataHora'] = (dataHora as any).toISOString();
            }

            if (proprietario !== undefined) {
                localVarQueryParameter['proprietario'] = proprietario;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (lote !== undefined) {
                localVarQueryParameter['lote'] = lote;
            }

            if (pagina !== undefined) {
                localVarQueryParameter['pagina'] = pagina;
            }

            if (tamanhoPagina !== undefined) {
                localVarQueryParameter['tamanhoPagina'] = tamanhoPagina;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta os registros cadastrados por lote
         * @param {string} [codigo] 
         * @param {Date} [dataHora] 
         * @param {number} [proprietario] 
         * @param {'pendente' | 'iniciado' | 'aguardando_financeiro' | 'encerrado'} [status] 
         * @param {number} [lote] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMeusProcessamentos(codigo?: string, dataHora?: Date, proprietario?: number, status?: 'pendente' | 'iniciado' | 'aguardando_financeiro' | 'encerrado', lote?: number, pagina?: number, tamanhoPagina?: number, options: any = {}): FetchArgs {
            const localVarPath = `/processamento-saida/meus`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (codigo !== undefined) {
                localVarQueryParameter['codigo'] = codigo;
            }

            if (dataHora !== undefined) {
                localVarQueryParameter['dataHora'] = (dataHora as any).toISOString();
            }

            if (proprietario !== undefined) {
                localVarQueryParameter['proprietario'] = proprietario;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (lote !== undefined) {
                localVarQueryParameter['lote'] = lote;
            }

            if (pagina !== undefined) {
                localVarQueryParameter['pagina'] = pagina;
            }

            if (tamanhoPagina !== undefined) {
                localVarQueryParameter['tamanhoPagina'] = tamanhoPagina;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Detalha o processamento de saída específico
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalha(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling detalha.');
            }
            const localVarPath = `/processamento-saida/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Salva e envia os dados com notificação para o cliente
         * @param {ProcessamentoSaidaDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        envia(body?: ProcessamentoSaidaDTO, options: any = {}): FetchArgs {
            const localVarPath = `/processamento-saida/envia`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (("ProcessamentoSaidaDTO" !== "string" as any)) || localVarRequestOptions.headers['Content-Type'] === 'application/json' as any;
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Salva os documentos do processamento de saida
         * @param {ProcessamentoSaidaDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salva(body?: ProcessamentoSaidaDTO, options: any = {}): FetchArgs {
            const localVarPath = `/processamento-saida`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (("ProcessamentoSaidaDTO" !== "string" as any)) || localVarRequestOptions.headers['Content-Type'] === 'application/json' as any;
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProcessamentoSaidaApi - functional programming interface
 * @export
 */
export const ProcessamentoSaidaApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Consulta os processamentos de saída
         * @param {string} [codigo] 
         * @param {Date} [dataHora] 
         * @param {number} [proprietario] 
         * @param {'pendente' | 'iniciado' | 'aguardando_financeiro' | 'encerrado'} [status] 
         * @param {number} [lote] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consulta(codigo?: string, dataHora?: Date, proprietario?: number, status?: 'pendente' | 'iniciado' | 'aguardando_financeiro' | 'encerrado', lote?: number, pagina?: number, tamanhoPagina?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaginaProcessamentoSaidaDTO> {
            const localVarFetchArgs = ProcessamentoSaidaApiFetchParamCreator(configuration).consulta(codigo, dataHora, proprietario, status, lote, pagina, tamanhoPagina, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta os registros cadastrados por lote
         * @param {string} [codigo] 
         * @param {Date} [dataHora] 
         * @param {number} [proprietario] 
         * @param {'pendente' | 'iniciado' | 'aguardando_financeiro' | 'encerrado'} [status] 
         * @param {number} [lote] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaByLote(codigo?: string, dataHora?: Date, proprietario?: number, status?: 'pendente' | 'iniciado' | 'aguardando_financeiro' | 'encerrado', lote?: number, pagina?: number, tamanhoPagina?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaginaProcessamentoSaidaLoteDTO> {
            const localVarFetchArgs = ProcessamentoSaidaApiFetchParamCreator(configuration).consultaByLote(codigo, dataHora, proprietario, status, lote, pagina, tamanhoPagina, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta os registros cadastrados por lote
         * @param {string} [codigo] 
         * @param {Date} [dataHora] 
         * @param {number} [proprietario] 
         * @param {'pendente' | 'iniciado' | 'aguardando_financeiro' | 'encerrado'} [status] 
         * @param {number} [lote] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMeusProcessamentos(codigo?: string, dataHora?: Date, proprietario?: number, status?: 'pendente' | 'iniciado' | 'aguardando_financeiro' | 'encerrado', lote?: number, pagina?: number, tamanhoPagina?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaginaProcessamentoSaidaDTO> {
            const localVarFetchArgs = ProcessamentoSaidaApiFetchParamCreator(configuration).consultaMeusProcessamentos(codigo, dataHora, proprietario, status, lote, pagina, tamanhoPagina, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Detalha o processamento de saída específico
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalha(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProcessamentoSaidaDTO> {
            const localVarFetchArgs = ProcessamentoSaidaApiFetchParamCreator(configuration).detalha(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Salva e envia os dados com notificação para o cliente
         * @param {ProcessamentoSaidaDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        envia(body?: ProcessamentoSaidaDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProcessamentoSaidaDTO> {
            const localVarFetchArgs = ProcessamentoSaidaApiFetchParamCreator(configuration).envia(body, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Salva os documentos do processamento de saida
         * @param {ProcessamentoSaidaDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salva(body?: ProcessamentoSaidaDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProcessamentoSaidaDTO> {
            const localVarFetchArgs = ProcessamentoSaidaApiFetchParamCreator(configuration).salva(body, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ProcessamentoSaidaApi - factory interface
 * @export
 */
export const ProcessamentoSaidaApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Consulta os processamentos de saída
         * @param {string} [codigo] 
         * @param {Date} [dataHora] 
         * @param {number} [proprietario] 
         * @param {'pendente' | 'iniciado' | 'aguardando_financeiro' | 'encerrado'} [status] 
         * @param {number} [lote] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consulta(codigo?: string, dataHora?: Date, proprietario?: number, status?: 'pendente' | 'iniciado' | 'aguardando_financeiro' | 'encerrado', lote?: number, pagina?: number, tamanhoPagina?: number, options?: any) {
            return ProcessamentoSaidaApiFp(configuration).consulta(codigo, dataHora, proprietario, status, lote, pagina, tamanhoPagina, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta os registros cadastrados por lote
         * @param {string} [codigo] 
         * @param {Date} [dataHora] 
         * @param {number} [proprietario] 
         * @param {'pendente' | 'iniciado' | 'aguardando_financeiro' | 'encerrado'} [status] 
         * @param {number} [lote] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaByLote(codigo?: string, dataHora?: Date, proprietario?: number, status?: 'pendente' | 'iniciado' | 'aguardando_financeiro' | 'encerrado', lote?: number, pagina?: number, tamanhoPagina?: number, options?: any) {
            return ProcessamentoSaidaApiFp(configuration).consultaByLote(codigo, dataHora, proprietario, status, lote, pagina, tamanhoPagina, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta os registros cadastrados por lote
         * @param {string} [codigo] 
         * @param {Date} [dataHora] 
         * @param {number} [proprietario] 
         * @param {'pendente' | 'iniciado' | 'aguardando_financeiro' | 'encerrado'} [status] 
         * @param {number} [lote] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaMeusProcessamentos(codigo?: string, dataHora?: Date, proprietario?: number, status?: 'pendente' | 'iniciado' | 'aguardando_financeiro' | 'encerrado', lote?: number, pagina?: number, tamanhoPagina?: number, options?: any) {
            return ProcessamentoSaidaApiFp(configuration).consultaMeusProcessamentos(codigo, dataHora, proprietario, status, lote, pagina, tamanhoPagina, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Detalha o processamento de saída específico
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalha(id: number, options?: any) {
            return ProcessamentoSaidaApiFp(configuration).detalha(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Salva e envia os dados com notificação para o cliente
         * @param {ProcessamentoSaidaDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        envia(body?: ProcessamentoSaidaDTO, options?: any) {
            return ProcessamentoSaidaApiFp(configuration).envia(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Salva os documentos do processamento de saida
         * @param {ProcessamentoSaidaDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salva(body?: ProcessamentoSaidaDTO, options?: any) {
            return ProcessamentoSaidaApiFp(configuration).salva(body, options)(fetch, basePath);
        },
    };
};

/**
 * ProcessamentoSaidaApi - object-oriented interface
 * @export
 * @class ProcessamentoSaidaApi
 * @extends {BaseAPI}
 */
export class ProcessamentoSaidaApi extends BaseAPI {
    /**
     * 
     * @summary Consulta os processamentos de saída
     * @param {string} [codigo] 
     * @param {Date} [dataHora] 
     * @param {number} [proprietario] 
     * @param {'pendente' | 'iniciado' | 'aguardando_financeiro' | 'encerrado'} [status] 
     * @param {number} [lote] 
     * @param {number} [pagina] 
     * @param {number} [tamanhoPagina] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessamentoSaidaApi
     */
    public consulta(codigo?: string, dataHora?: Date, proprietario?: number, status?: 'pendente' | 'iniciado' | 'aguardando_financeiro' | 'encerrado', lote?: number, pagina?: number, tamanhoPagina?: number, options?: any) {
        return ProcessamentoSaidaApiFp(this.configuration).consulta(codigo, dataHora, proprietario, status, lote, pagina, tamanhoPagina, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta os registros cadastrados por lote
     * @param {string} [codigo] 
     * @param {Date} [dataHora] 
     * @param {number} [proprietario] 
     * @param {'pendente' | 'iniciado' | 'aguardando_financeiro' | 'encerrado'} [status] 
     * @param {number} [lote] 
     * @param {number} [pagina] 
     * @param {number} [tamanhoPagina] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessamentoSaidaApi
     */
    public consultaByLote(codigo?: string, dataHora?: Date, proprietario?: number, status?: 'pendente' | 'iniciado' | 'aguardando_financeiro' | 'encerrado', lote?: number, pagina?: number, tamanhoPagina?: number, options?: any) {
        return ProcessamentoSaidaApiFp(this.configuration).consultaByLote(codigo, dataHora, proprietario, status, lote, pagina, tamanhoPagina, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta os registros cadastrados por lote
     * @param {string} [codigo] 
     * @param {Date} [dataHora] 
     * @param {number} [proprietario] 
     * @param {'pendente' | 'iniciado' | 'aguardando_financeiro' | 'encerrado'} [status] 
     * @param {number} [lote] 
     * @param {number} [pagina] 
     * @param {number} [tamanhoPagina] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessamentoSaidaApi
     */
    public consultaMeusProcessamentos(codigo?: string, dataHora?: Date, proprietario?: number, status?: 'pendente' | 'iniciado' | 'aguardando_financeiro' | 'encerrado', lote?: number, pagina?: number, tamanhoPagina?: number, options?: any) {
        return ProcessamentoSaidaApiFp(this.configuration).consultaMeusProcessamentos(codigo, dataHora, proprietario, status, lote, pagina, tamanhoPagina, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Detalha o processamento de saída específico
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessamentoSaidaApi
     */
    public detalha(id: number, options?: any) {
        return ProcessamentoSaidaApiFp(this.configuration).detalha(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Salva e envia os dados com notificação para o cliente
     * @param {ProcessamentoSaidaDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessamentoSaidaApi
     */
    public envia(body?: ProcessamentoSaidaDTO, options?: any) {
        return ProcessamentoSaidaApiFp(this.configuration).envia(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Salva os documentos do processamento de saida
     * @param {ProcessamentoSaidaDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessamentoSaidaApi
     */
    public salva(body?: ProcessamentoSaidaDTO, options?: any) {
        return ProcessamentoSaidaApiFp(this.configuration).salva(body, options)(this.fetch, this.basePath);
    }

}

/**
 * UsuarioAdministradorApi - fetch parameter creator
 * @export
 */
export const UsuarioAdministradorApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Ativa o usuário indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ativa(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling ativa.');
            }
            const localVarPath = `/usuario-administrador/{id}/ativa`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Busca usuario por CPF
         * @param {string} cpf 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buscaPorCpfCnpj(cpf: string, options: any = {}): FetchArgs {
            // verify required parameter 'cpf' is not null or undefined
            if (cpf === null || cpf === undefined) {
                throw new RequiredError('cpf','Required parameter cpf was null or undefined when calling buscaPorCpfCnpj.');
            }
            const localVarPath = `/usuario-administrador/cpf/{cpf}`
                .replace(`{${"cpf"}}`, encodeURIComponent(String(cpf)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Busca usuario por e-mail
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buscaPorEmail(email: string, options: any = {}): FetchArgs {
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling buscaPorEmail.');
            }
            const localVarPath = `/usuario-administrador/email/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta os registros cadastrados
         * @param {'gerencial' | 'administrativo' | 'comercial' | 'veterinario'} [perfil] 
         * @param {string} [nome] 
         * @param {'inativo' | 'ativo' | 'bloqueado' | 'excluido'} [status] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consulta(perfil?: 'gerencial' | 'administrativo' | 'comercial' | 'veterinario', nome?: string, status?: 'inativo' | 'ativo' | 'bloqueado' | 'excluido', pagina?: number, tamanhoPagina?: number, options: any = {}): FetchArgs {
            const localVarPath = `/usuario-administrador`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (perfil !== undefined) {
                localVarQueryParameter['perfil'] = perfil;
            }

            if (nome !== undefined) {
                localVarQueryParameter['nome'] = nome;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (pagina !== undefined) {
                localVarQueryParameter['pagina'] = pagina;
            }

            if (tamanhoPagina !== undefined) {
                localVarQueryParameter['tamanhoPagina'] = tamanhoPagina;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consulta uma página de veterinários no sistema
         * @param {'gerencial' | 'administrativo' | 'comercial' | 'veterinario'} [perfil] 
         * @param {string} [nome] 
         * @param {'inativo' | 'ativo' | 'bloqueado' | 'excluido'} [status] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaVeterinarios(perfil?: 'gerencial' | 'administrativo' | 'comercial' | 'veterinario', nome?: string, status?: 'inativo' | 'ativo' | 'bloqueado' | 'excluido', pagina?: number, tamanhoPagina?: number, options: any = {}): FetchArgs {
            const localVarPath = `/usuario-administrador/veterinario`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (perfil !== undefined) {
                localVarQueryParameter['perfil'] = perfil;
            }

            if (nome !== undefined) {
                localVarQueryParameter['nome'] = nome;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (pagina !== undefined) {
                localVarQueryParameter['pagina'] = pagina;
            }

            if (tamanhoPagina !== undefined) {
                localVarQueryParameter['tamanhoPagina'] = tamanhoPagina;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Detalha os dados do registro indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalha(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling detalha.');
            }
            const localVarPath = `/usuario-administrador/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Inativa o usuário indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inativa(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling inativa.');
            }
            const localVarPath = `/usuario-administrador/{id}/inativa`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove o registro indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling remove.');
            }
            const localVarPath = `/usuario-administrador/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Salva o registro indicado
         * @param {UsuarioAdministradorDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salva(body?: UsuarioAdministradorDTO, options: any = {}): FetchArgs {
            const localVarPath = `/usuario-administrador`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (("UsuarioAdministradorDTO" !== "string" as any)) || localVarRequestOptions.headers['Content-Type'] === 'application/json' as any;
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsuarioAdministradorApi - functional programming interface
 * @export
 */
export const UsuarioAdministradorApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Ativa o usuário indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ativa(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UsuarioAdministradorApiFetchParamCreator(configuration).ativa(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Busca usuario por CPF
         * @param {string} cpf 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buscaPorCpfCnpj(cpf: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UsuarioDTO> {
            const localVarFetchArgs = UsuarioAdministradorApiFetchParamCreator(configuration).buscaPorCpfCnpj(cpf, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Busca usuario por e-mail
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buscaPorEmail(email: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UsuarioDTO> {
            const localVarFetchArgs = UsuarioAdministradorApiFetchParamCreator(configuration).buscaPorEmail(email, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta os registros cadastrados
         * @param {'gerencial' | 'administrativo' | 'comercial' | 'veterinario'} [perfil] 
         * @param {string} [nome] 
         * @param {'inativo' | 'ativo' | 'bloqueado' | 'excluido'} [status] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consulta(perfil?: 'gerencial' | 'administrativo' | 'comercial' | 'veterinario', nome?: string, status?: 'inativo' | 'ativo' | 'bloqueado' | 'excluido', pagina?: number, tamanhoPagina?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaginaUsuarioAdministradorDTO> {
            const localVarFetchArgs = UsuarioAdministradorApiFetchParamCreator(configuration).consulta(perfil, nome, status, pagina, tamanhoPagina, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Consulta uma página de veterinários no sistema
         * @param {'gerencial' | 'administrativo' | 'comercial' | 'veterinario'} [perfil] 
         * @param {string} [nome] 
         * @param {'inativo' | 'ativo' | 'bloqueado' | 'excluido'} [status] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaVeterinarios(perfil?: 'gerencial' | 'administrativo' | 'comercial' | 'veterinario', nome?: string, status?: 'inativo' | 'ativo' | 'bloqueado' | 'excluido', pagina?: number, tamanhoPagina?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaginaUsuarioAdministradorDTO> {
            const localVarFetchArgs = UsuarioAdministradorApiFetchParamCreator(configuration).consultaVeterinarios(perfil, nome, status, pagina, tamanhoPagina, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Detalha os dados do registro indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalha(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UsuarioAdministradorDTO> {
            const localVarFetchArgs = UsuarioAdministradorApiFetchParamCreator(configuration).detalha(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Inativa o usuário indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inativa(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UsuarioAdministradorApiFetchParamCreator(configuration).inativa(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Remove o registro indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UsuarioAdministradorApiFetchParamCreator(configuration).remove(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Salva o registro indicado
         * @param {UsuarioAdministradorDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salva(body?: UsuarioAdministradorDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UsuarioAdministradorDTO> {
            const localVarFetchArgs = UsuarioAdministradorApiFetchParamCreator(configuration).salva(body, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status === 204) { return null; } if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UsuarioAdministradorApi - factory interface
 * @export
 */
export const UsuarioAdministradorApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Ativa o usuário indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ativa(id: number, options?: any) {
            return UsuarioAdministradorApiFp(configuration).ativa(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Busca usuario por CPF
         * @param {string} cpf 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buscaPorCpfCnpj(cpf: string, options?: any) {
            return UsuarioAdministradorApiFp(configuration).buscaPorCpfCnpj(cpf, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Busca usuario por e-mail
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buscaPorEmail(email: string, options?: any) {
            return UsuarioAdministradorApiFp(configuration).buscaPorEmail(email, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta os registros cadastrados
         * @param {'gerencial' | 'administrativo' | 'comercial' | 'veterinario'} [perfil] 
         * @param {string} [nome] 
         * @param {'inativo' | 'ativo' | 'bloqueado' | 'excluido'} [status] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consulta(perfil?: 'gerencial' | 'administrativo' | 'comercial' | 'veterinario', nome?: string, status?: 'inativo' | 'ativo' | 'bloqueado' | 'excluido', pagina?: number, tamanhoPagina?: number, options?: any) {
            return UsuarioAdministradorApiFp(configuration).consulta(perfil, nome, status, pagina, tamanhoPagina, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Consulta uma página de veterinários no sistema
         * @param {'gerencial' | 'administrativo' | 'comercial' | 'veterinario'} [perfil] 
         * @param {string} [nome] 
         * @param {'inativo' | 'ativo' | 'bloqueado' | 'excluido'} [status] 
         * @param {number} [pagina] 
         * @param {number} [tamanhoPagina] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaVeterinarios(perfil?: 'gerencial' | 'administrativo' | 'comercial' | 'veterinario', nome?: string, status?: 'inativo' | 'ativo' | 'bloqueado' | 'excluido', pagina?: number, tamanhoPagina?: number, options?: any) {
            return UsuarioAdministradorApiFp(configuration).consultaVeterinarios(perfil, nome, status, pagina, tamanhoPagina, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Detalha os dados do registro indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detalha(id: number, options?: any) {
            return UsuarioAdministradorApiFp(configuration).detalha(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Inativa o usuário indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inativa(id: number, options?: any) {
            return UsuarioAdministradorApiFp(configuration).inativa(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Remove o registro indicado
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: number, options?: any) {
            return UsuarioAdministradorApiFp(configuration).remove(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Salva o registro indicado
         * @param {UsuarioAdministradorDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salva(body?: UsuarioAdministradorDTO, options?: any) {
            return UsuarioAdministradorApiFp(configuration).salva(body, options)(fetch, basePath);
        },
    };
};

/**
 * UsuarioAdministradorApi - object-oriented interface
 * @export
 * @class UsuarioAdministradorApi
 * @extends {BaseAPI}
 */
export class UsuarioAdministradorApi extends BaseAPI {
    /**
     * 
     * @summary Ativa o usuário indicado
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsuarioAdministradorApi
     */
    public ativa(id: number, options?: any) {
        return UsuarioAdministradorApiFp(this.configuration).ativa(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Busca usuario por CPF
     * @param {string} cpf 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsuarioAdministradorApi
     */
    public buscaPorCpfCnpj(cpf: string, options?: any) {
        return UsuarioAdministradorApiFp(this.configuration).buscaPorCpfCnpj(cpf, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Busca usuario por e-mail
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsuarioAdministradorApi
     */
    public buscaPorEmail(email: string, options?: any) {
        return UsuarioAdministradorApiFp(this.configuration).buscaPorEmail(email, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta os registros cadastrados
     * @param {'gerencial' | 'administrativo' | 'comercial' | 'veterinario'} [perfil] 
     * @param {string} [nome] 
     * @param {'inativo' | 'ativo' | 'bloqueado' | 'excluido'} [status] 
     * @param {number} [pagina] 
     * @param {number} [tamanhoPagina] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsuarioAdministradorApi
     */
    public consulta(perfil?: 'gerencial' | 'administrativo' | 'comercial' | 'veterinario', nome?: string, status?: 'inativo' | 'ativo' | 'bloqueado' | 'excluido', pagina?: number, tamanhoPagina?: number, options?: any) {
        return UsuarioAdministradorApiFp(this.configuration).consulta(perfil, nome, status, pagina, tamanhoPagina, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Consulta uma página de veterinários no sistema
     * @param {'gerencial' | 'administrativo' | 'comercial' | 'veterinario'} [perfil] 
     * @param {string} [nome] 
     * @param {'inativo' | 'ativo' | 'bloqueado' | 'excluido'} [status] 
     * @param {number} [pagina] 
     * @param {number} [tamanhoPagina] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsuarioAdministradorApi
     */
    public consultaVeterinarios(perfil?: 'gerencial' | 'administrativo' | 'comercial' | 'veterinario', nome?: string, status?: 'inativo' | 'ativo' | 'bloqueado' | 'excluido', pagina?: number, tamanhoPagina?: number, options?: any) {
        return UsuarioAdministradorApiFp(this.configuration).consultaVeterinarios(perfil, nome, status, pagina, tamanhoPagina, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Detalha os dados do registro indicado
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsuarioAdministradorApi
     */
    public detalha(id: number, options?: any) {
        return UsuarioAdministradorApiFp(this.configuration).detalha(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Inativa o usuário indicado
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsuarioAdministradorApi
     */
    public inativa(id: number, options?: any) {
        return UsuarioAdministradorApiFp(this.configuration).inativa(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Remove o registro indicado
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsuarioAdministradorApi
     */
    public remove(id: number, options?: any) {
        return UsuarioAdministradorApiFp(this.configuration).remove(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Salva o registro indicado
     * @param {UsuarioAdministradorDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsuarioAdministradorApi
     */
    public salva(body?: UsuarioAdministradorDTO, options?: any) {
        return UsuarioAdministradorApiFp(this.configuration).salva(body, options)(this.fetch, this.basePath);
    }

}

